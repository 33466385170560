import React,{useState} from 'react';
import './ThermalPrintComponent.css'
import logo from "../Assets/voterCamp.png";
import LoaderWrapper from '../components/LoaderWrapper'

const ThermalPrintComponent = ({ data }) => {

  const itemsPerPage = 5;

  // Calculate the number of pages needed
  const pageCount = Math.ceil(data.length / itemsPerPage);

  // Create an array of page indices
  const pageIndices = Array.from({ length: pageCount }, (_, index) => index);

  console.log("datalist", data);
  return (
    <div>

    {data.map((data, index) => (
      // <ThermalPrintComponent key={index} data={data} />
      <div className="thermal-print-content" style={{ display: 'flex', border: '2px dotted black', padding: '5px', width: '800px',fontSize:'8px !important',height:'220px',color:'gray !important' }}>
      {/* Image on the left side */}
      <div className="image-container" style={{ flex: '1',marginTop:'12px' }}>
        <img className="" src={logo} alt="not visible" style={{ maxWidth: '90%' }} />
      </div>

      {/* Data on the right side */}
      <div className="data-container" style={{ flex: '1', textAlign: 'center', padding: '5px',borderLeft:'1px dotted gray' }}>
        <span style={{ fontWeight: '800', fontSize: '20px', textAlign:'center' }}>{data.voter_id}</span>

        {/* SL No, Voter Name, Age, Voter ID, Part Number */}
        <div className="voter-info" style={{ marginTop: '3px',textAlign:'left',lineHeight:'1.3' }}>
          {/* <div className="sl-no">Sl No: {data.slNo}</div> */}
          <div className="voter-name">Name:<b>{data.first_name}</b> </div>
          <div className="voter-name">Assembly Constituency:<b> {data.constituency__mla_constituency_name}</b> </div>
          <div className="voter-name">Parliament Constituency: <b>{data.constituency__mp_constituency_name}</b> </div>
          <div className="age">Distict Code: <b>{data.constituency__dist}</b> </div>
          {/* <div className="age">Age: {data.age}</div>
          <div className="voter-id">Voter ID: {data.voter_id}</div>
          <div className="part-number">Part Number: {data.partNumber}</div> */}
        </div>

        {/* Divider */}
        {/* <hr className="divider" /> */}

        {/* Booth Number, Voting Center */}
        <div className="booth-info" style={{ marginTop: '5px',display:'flex',justifyContent:'space-between',textAlign:'left'}}>
          <div className="booth-number">Booth Number:<b> {data.polling_booth}</b> </div>
          <div className="voting-center">Booth Name: <b>{data.polling_booth__polling_booth_name}</b> </div>
        </div>

        {/* Divider */}
        {/* <hr className="divider" /> */}

        {/* Voting Date and Time */}
        <div className="voting-info" style={{ marginTop: '3px',textAlign:'left',lineHeight:'1.3' }}>
          <div className="voting-date">Serial No: <b>{data.serial_no}</b> </div>
          {/* <div className="voting-time">Pooling Station Address:<b> {data.street}</b> </div> */}
        </div>

        <div className="booth-info" style={{ marginTop: '3px',textAlign:'left' }}>
          <div className="booth-number"><b>Voting Date: 30-Nov-2023</b> </div>
          {/* <div className="voting-center"><b>Voting Time: Between 07:00AM to 06:00PM</b> </div> */}
        </div>
        {/* <div className="thank-you-message" style={{ marginTop: '10px' }}>
          <span>*********************</span>
          <span>THANK YOU</span>
          <span>*********************</span>
        </div> */}
      </div>
    </div>
    ))}
  </div>

  );
};

export default ThermalPrintComponent;
