import React, { useEffect, useState, useRef } from "react";
import {
  getBirthdayData,
  getBirthdayDataWithPagination,
  getBirthdayFilters,
} from "../Service/BirthdayService";
import callPendingActionIcon from "../Assets/callPendingActionIcon.svg";
import endfooter from "../Assets/endfooter.png";
import arrow from "../Assets/dropdownarrow.png";
import Bluearrow from "../Assets/Bluearrow.png";
import "./Birthday.css";
import { isMobile } from "react-device-detect";
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight";
import { MdKeyboardArrowLeft } from "@react-icons/all-files/md/MdKeyboardArrowLeft";
import viewEyeIcon from "../Assets/viewEyeIcon.svg";
import Modal from "react-bootstrap/Modal";
import BirthdayIndividualModal from "./BirthdayIndividualModal";
import LoaderWrapper from "../components/LoaderWrapper";
import pdf from "../Assets/pdflogo.png";
import excel from "../Assets/excellogo.png";
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

const handleTitleDiv = (column) => {
  return (
    <div className="titleDiv">
      <span>{column}</span>
    </div>
  );
};

const Birthday = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, setTotalpages] = useState();
  const pagesCount = Math.ceil(totalpages / 50);
  const [objectsPerPage, setObjectsPerPage] = useState(50);
  const [nextPageUrl, setNextPageUrl] = useState(undefined);
  const [prevPageUrl, setPrevPageUrl] = useState(undefined);
  const [filtersData, setFiltersData] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [showDropdownward, setShowDropdownward] = useState(false);
  const ref = useRef();
  const [show, setShow] = useState(false);
  const [mobileColumn, setMobileColumn] = useState("First Name");
  const tabColumnsArray = [
    "Action",
    "Voter ID",
    "First Name",
    "Last Name",
    "Age",
    "Designation",
    "Phone Number",
    "Call Status",
  ];
  const [columnIndex, setColumnIndex] = useState(1);
  const [viewBirthdayItem, setViewBirthdayItem] = useState("");
  const [isLoading, setIsLoading] = useState();

  const handleColumn = (index) => {
    if (index >= 1 && index < tabColumnsArray.length) {
      setMobileColumn(tabColumnsArray[index]);
      setColumnIndex(index);
    }
  };
  useEffect(() => {
    // setIsLoading(true)
    if (filterValues.length === 0) {
      getBirthdayData().then((resp) => {
        if (resp.next) {
          let nextPage = resp.next.replace("http", "https");
          setNextPageUrl(nextPage);
          // setIsLoading(false)
        } else {
          setNextPageUrl(undefined);
          // setIsLoading(false)
        }
        if (resp.previous) {
          let prevPage = resp.next.replace("http", "https");
          setPrevPageUrl(resp.previous);
        } else {
          setPrevPageUrl(undefined);
        }
        setData(resp.results);
        setTotalpages(resp.count);
      });
      getBirthdayFilters().then((resp) => {
        setFiltersData(resp);
      });
    }
  }, [filterValues]);
  useEffect(() => {
    getBirthdayData(filterValues.join(",")).then((resp) => {
      if (resp.next) {
        setNextPageUrl(resp.next);
      } else {
        setNextPageUrl(undefined);
      }
      if (resp.previous) {
        setPrevPageUrl(resp.previous);
      } else {
        setPrevPageUrl(undefined);
      }
      setData(resp.results);
      setTotalpages(resp.count);
    });
  }, []);
  const handleShow = (viewItem) => {
    setShow(true);
    setViewBirthdayItem(viewItem);
  };

  const handleDataWithPagination = (url) => {
    getBirthdayDataWithPagination(url).then((resp) => {
      if (resp.next) {
        setNextPageUrl(resp.next);
      } else {
        setNextPageUrl(undefined);
      }
      if (resp.previous) {
        setPrevPageUrl(resp.previous);
      } else {
        setPrevPageUrl(undefined);
      }
      setData(resp.results);
      setTotalpages(resp.count);
    });
  };

  const handleChangeFilter = (event, item) => {
    console.log(filterValues.indexOf(item.voter_type_id), "filterValues");
    if (filterValues.indexOf(item.voter_type_id) < 0) {
      setFilterValues((filters) => [...filters, item.voter_type_id]);
    } else {
      setFilterValues((oldValues) => {
        return oldValues.filter((filter) => filter !== item.voter_type_id);
      });
    }
  };

  function handleNextPage() {
    setCurrentPage((prevPage) => prevPage + 1);
    handleDataWithPagination(nextPageUrl);
  }

  function handlePreviousPage() {
    setCurrentPage((prevPage) => prevPage - 1);
    handleDataWithPagination(prevPageUrl);
  }

  const [selectedPhonenumber, setSelectedphoneNumber] = useState(null);

  const handleMakeCall = (phone_no) => {
    setSelectedphoneNumber(phone_no);
    window.location.href = `tel:${phone_no}`;
  };

  const handleDropdownwardClick = (e) => {
    e.stopPropagation();
    setShowDropdownward(!showDropdownward);
  };

  function handleClickOutside(event) {
    event.stopPropagation();
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDropdownward(!showDropdownward);
    }
  }

  useOnClickOutside(ref, () => setShowDropdownward(false));

  return (
    <div className="birthday_data_main_div d-flex">
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{viewBirthdayItem.voter_id}</Modal.Title>
        </Modal.Header>
        <BirthdayIndividualModal viewBirthdayItem={viewBirthdayItem} />
      </Modal>
      <div className="top_nav_holder_main">
        <div className="d-flex flex-column">
          <div className="birthday_data_text_main_div">
            <span className="birthday_data_text_ems">Birthdays</span>
            {/* <button
                            className="pdflogo-birthday d-flex align-items-center"
                        >
                            <div
                                className="pdfImg_birthday d-flex justify-content-center align-items-center"
                                alt="not-visible"
                                style={{ opacity: "1" }}
                            >
                                <img src={pdf} className="pdfcenter_Logo" alt="not visible" />
                            </div>
                        </button> */}
            {/* <button
                            className="excelllogo-birthday d-flex align-items-center"
                        >
                            <img src={excel} className="excel_img" alt="not-visible" />
                        </button> */}
            <div className={"filter_birthday"}>
              <div
                className="birthday_dropdow_filter d-flex align-items-center"
                ref={ref}
              >
                <div
                  style={{ width: "100%", height: "100%" }}
                  className="d-flex align-items-center"
                  onClick={handleDropdownwardClick}
                >
                  <span className="birthday_dropdown_box_text mandaltown">
                    Filter
                  </span>
                  <span className="mandalmore"></span>
                  <img
                    className="birthday_dropdown_arrow"
                    src={Bluearrow}
                    alt="not_visible"
                  />
                </div>
                {showDropdownward && (
                  <div className="mandaltown_data" id="mandaltown_data_">
                    {filtersData.map((item) => {
                      return (
                        <div
                          key={item.voter_type_id}
                          id={`m${item.voter_type_id}`}
                          className={`mandal_town_data d-flex flex-column justify-content-center`}
                          onClick={(e) => handleChangeFilter(e, item)}
                        >
                          <span>{item.voter_type_name}</span>
                          <input
                            className="checkbox_mandal"
                            type="checkbox"
                            checked={
                              !(filterValues.indexOf(item.voter_type_id) < 0)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* //// */}
        <div
          style={{ marginTop: "5vw" }}
          className="itoconnect_birthdays_data_mobile_main_div"
        >
          <div className="birthdays_title_name">
            <span id="itoconnect_birthdayss_id_title">Voter IDs</span>
          </div>
          <div className="itoconnect_birthdays_id_child_div_main">


             {/* {
          data.length == 0 ? (
                <div className="nodata_available">NO DATA AVAILABLE</div>
              ) : (
                data.map((item) => (
                  <div key={item.id}>
                
                <div id="title_div_birthdays_data_first_column">
                    <span className="" onClick={() => handleShow(item)}>{item.voter_id}</span>
                  </div>
                  <div className="birthdays_data_first_name_age_caste_main">
                  <div className="birthdays_data_child_div_first_name_age_caste">
                   <div className="birthdays_display_first_name_">
                      <span>{item.first_name}</span>
                   </div>

                    <div className="birthdays_display_last_name_">
                      <span>{item.last_name}</span>
                    </div>
                    <div className="birthdays_display_gender_">
                      <span>{item.gender}</span>
                    </div>
                  
                    </div>
                    <div className="call_icon_Data">
                    <img
                        className="call_image_button"
                        src={callPendingActionIcon}
                        alt="not_visible"
                        onClick={() => handleMakeCall(item.phone_no)}
                      />
                    </div>
                    </div>
                  </div> 
                  ))
                  )}  */}

                  <div>
  {data.length > 0 && data.map((item) => (
    <div key={item.id}>
      <div id="title_div_birthdays_data_first_column">
        <span className="" onClick={() => handleShow(item)}>
          {item.voter_id}
        </span>
      </div>
      <div className="birthdays_data_first_name_age_caste_main">
        <div className="birthdays_data_child_div_first_name_age_caste">
          <div className="birthdays_display_first_name_">
            <span>{item.first_name}</span>
          </div>
          <div className="birthdays_display_last_name_">
            <span>{item.last_name}</span>
          </div>
          <div className="birthdays_display_gender_">
            <span>{item.gender}</span>
          </div>
        </div>
        <div className="call_icon_Data">
          <img
            className="call_image_button"
            src={callPendingActionIcon}
            alt="not_visible"
            onClick={() => handleMakeCall(item.phone_no)}
          />
        </div>
      </div>
    </div>
  ))}
</div>


         
          </div>
        </div>

        <div className="d-flex birthdays_data_next_prev_main_div">
          <div className="total_pages_birthday">{pagesCount}</div>
          <button
            className="prev_button_birthday_data"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            ❮
          </button>
          <div className="current_page_number_birthday_data">{currentPage}</div>
          <button
            className="next_button_birthday_data"
            onClick={handleNextPage}
            disabled={nextPageUrl === undefined}
          >
            ❯
          </button>
        </div>

        {/* /// */}
        <div className="parent_horizontal_scroll_birthday_data">
          <div className="horizontal_scroll_birthday_data">
            <div className="d-flex top_headings_in_birthday_data_main_div">
              {tabColumnsArray.map((item) => {
                return (
                  <div>
                    {data.length == 0 ? (
                      <div className="nodata_available">NO DATA AVAILABLE</div>
                    ) : (
                      <div>
                        <div>
                          <div className="top_heading_in_birthday_data">
                            <span>{item}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="birthday_data_holder_main_div">
              {data.length == 0 ? (
                <div className="nodata_available">NO DATA AVAILABLE</div>
              ) : (
                data.map((item) => (
                  <div key={item.id} className="d-flex">
                    <div className="titleDiv">
                      <span onClick={() => handleShow(item)}>View</span>
                    </div>
                    <div className="titleDiv">
                      <span>{item.voter_id}</span>
                    </div>
                    <div className="titleDiv">
                      <span>{item.first_name}</span>
                    </div>
                    <div className="titleDiv">
                      <span>{item.last_name}</span>
                    </div>
                    <div className="titleDiv">
                      <span>{item.age}</span>
                    </div>
                    <div className="titleDiv">
                      <span>{item.designation}</span>
                    </div>
                    <div className="titleDiv">
                      <span>{item.phone_no}</span>
                    </div>
                    <div className="titleDiv">
                      <img
                        className="call_image_button"
                        src={callPendingActionIcon}
                        alt="not_visible"
                        onClick={() => handleMakeCall(item.phone_no)}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="birthday_data_underlines_holder">
          <div className="birthday_data_underline_one"></div>
          <div className="birthday_data_underline_one"></div>
        </div>
        <div className="d-flex buttons_birthday_data_next_prev_main_div">
          <div className="total_pages_birthday">{pagesCount}</div>
          <button
            className="prev_button_birthday_data"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <div className="current_page_number_birthday_data">{currentPage}</div>
          <button
            className="next_button_birthday_data"
            onClick={handleNextPage}
            disabled={nextPageUrl === undefined}
          >
            Next
          </button>
        </div>
      </div>
      {isLoading ? <LoaderWrapper /> : null}
    </div>
  );
};
export default Birthday;
