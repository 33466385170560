import { urlData } from "../config/apiconfig";
import axios from "axios";
import axiosInstance from "./AxiosInstance";

// const url = "http://65.0.54.74:8000";
// const url = "https://api.itoconnect.online";
// const url = "https://test-api.itoconnect.online";

import React from "react";
import { encode, decode } from "base-64"; // Import the Base64 encode function

const url = process.env.REACT_APP_SERVER_CONFIG;
const cookieUrl = process.env.REACT_APP_COOKIE_CONFIG;
// const cookieUrl = "http://localhost:3000/"; // comment this before pushing to dev
const emailkey = "ufj2mjfrpz35rnz8i78f";
const passwordkey = "c84t7p4pjt4w5clenxiloc1mu";

export async function refreshToken() {
  let accessData = JSON.parse(localStorage.getItem("data"));
  if (accessData && accessData.refresh) {
    let data = {
      refresh: accessData.refresh,
    };
    try {
      let response = await axios.post(`${url}${urlData.refreshToken}`, data);
      // let response = await axiosInstance.post(`${urlData.refreshToken}`, data)

      if (response.data && response.data.access) {
        accessData["token"] = response.data.access;
        accessData["access"] = response.data.access;
        localStorage.setItem("data", JSON.stringify(accessData));
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = "/login";
      }
      return false;
    }
  } else {
    window.location.href = "/login";
  }
}

export async function loginUser(email, password, isRememberMeChecked) {
  const encodedEmail = encode(email);
  const encodedPassword = encode(password);
  const encodeemailkey = encode(emailkey);
  const encodepasswordkey = encode(passwordkey);
  const emailencrypted = `${encodedEmail}${encodeemailkey}`;
  const passwordencrypted = `${encodedPassword}${encodepasswordkey}`;
  console.log(
    "email-encrypted ",
    emailencrypted,
    "encode emailkey",
    encodeemailkey,
    "encodedEmail",
    encodedEmail
  );

  const response = await fetch(`${url}${urlData.signIn}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: emailencrypted,
      password: passwordencrypted,
    }),
  });

  const data = await response.json();
  if (data.status) {
    if (data.access) {
      data["token"] = data.access;
    }
    // const emailEncoded = encodeURIComponent(emailencrypted)
    console.log({
      email: emailencrypted,
      password: passwordencrypted,
    });
    if (isRememberMeChecked) {
      document.cookie = `emailid=${encodeURIComponent(
        encodedEmail
      )};path=${cookieUrl}`;
      document.cookie = `password=${encodeURIComponent(
        encodedPassword
      )};path=${cookieUrl}`;
    }
    localStorage.setItem("data", JSON.stringify(data));
    window.location.href = "/";
  }
  return data;
}

export async function templates() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get("/sms/dltdata/");
  return response.data;
}

export async function finaltemplates() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get("/sms/getcampaigns/");
  return response.data;
}

export async function getcampData(item) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `/sms/campaigndetails/?campaign_id=${item}`
  );
  return response.data;
}

export async function dashboardMasterData() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.constituencyDashboardAPI}`
  );
  return response.data;
  // const response = await fetch(`${url}${urlData.constituencyDashboardAPI}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  const data = await response.json();
  return data;
}

export async function dashboardConstituencyVotersData() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.allConstituencyVoterDashboardAPI}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.allConstituencyVoterDashboardAPI}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  const data = await response.json();
  return data;
}

export async function constituencyCasteData() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.casteAtConstituencyDashboardAPI}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.casteAtConstituencyDashboardAPI}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const data = await response.json();
  // return data;
}

export async function favourToConstituencyData() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.favourConstituencyDashboardAPI}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.favourConstituencyDashboardAPI}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  const data = await response.json();
  return data;
}

// venkat code

export async function DashboardApi() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.constituencyDashboardAPI}`
  );
  return response.data;

  // const dashboard = await fetch(`${url}${urlData.constituencyDashboardAPI}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   }
  //   ,
  // }
  // );
  // const data = dashboard.json();
  // return data;
}
// get mandal data for constituency id value

export async function constituencyDashboardApi(id) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const dashboard = await axiosInstance.get(
    `${urlData.mpConstituencyDashboardAPI}${id}`
  );
  return dashboard.data;
  // const dashboard = await fetch(
  //   `${url}${urlData.mpConstituencyDashboardAPI}${id}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const data = dashboard.json();
  // return data;
}

export async function mandalData(selectedConstituencyId) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const dashboard = await axiosInstance.get(
    `${urlData.mpConstituencyDashboardAPI}${selectedConstituencyId}`
  );
  return dashboard.data;
  // const dashboard = await fetch(
  //   `${url}${urlData.mpConstituencyDashboardAPI}${selectedConstituencyId}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  const data = dashboard.json();
  return data;
}

//filter village data dashboard//finished
export async function villageData(selectedmandal) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const dashboard = await axiosInstance.get(
    `${urlData.mandalDashboardAPI}${selectedmandal}`
  );
  return dashboard.data;
  // const dashboard = await fetch(
  //   `${url}${urlData.mandalDashboardAPI}${selectedmandal}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  const data = dashboard.json();
  return data;
}
//finished
export async function pollingStation(selectedvillage) {
  // console.log('vill',selectedvillage)
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const polling = await axiosInstance.get(
    `${urlData.wardVillageDashboardAPI}${selectedvillage}`
  );
  return polling.data;
  // const polling = await fetch(
  //   `${url}${urlData.wardVillageDashboardAPI}${selectedvillage}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  const pollingdata = polling.json();
  return pollingdata;
}
//male,female,total,others api
export async function constituencyVoterFilterData(selectedconstituencies,selectedmandals,selectedvillages,selectedpollingstations) {
  const mandal = selectedmandals.length > 0? `&mandal=${selectedmandals}`:''
  const village = selectedvillages.length > 0 ? `&ward_village=${selectedvillages}`: ''
  const pollings = selectedpollingstations.length > 0 ? `&polling_booth=${selectedpollingstations}`: ''
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.constituencyMPVoterDashboardAPI}${selectedconstituencies}${mandal}${village}${pollings}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.constituencyMPVoterDashboardAPI}${constituencyId}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function mandalVoterFilterData(
  selectConstituencyId,
  selectedmandal
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function casteConstituencyVoterFilterData(
  constituencyId,
  selectedmandal
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.casteAtMPConstituencyDashboardAPI}${constituencyId}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.casteAtMPConstituencyDashboardAPI}${constituencyId}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  const responseData = response.json();
  return responseData;
}
export async function casteMandalVoterFilterData(
  constituencyId,
  selectedmandal
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.casteAtMPConstituencyDashboardAPI}${constituencyId}&mandal=${selectedmandal}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.casteAtMPConstituencyDashboardAPI}${constituencyId}&mandal=${selectedmandal}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function favourConstituencyVoterFilterData(
  selectedConstituencyId
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.favourMPConstituencyDashboardAPI}${selectedConstituencyId}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.favourMPConstituencyDashboardAPI}${selectedConstituencyId}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function favourMandalVoterFilterData(
  selectedConstituencyId,
  selectedmandal
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.favourMPConstituencyDashboardAPI}${selectedConstituencyId}&mandal=${selectedmandal}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.favourMPConstituencyDashboardAPI}${selectedConstituencyId}&mandal=${selectedmandal}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function villageVoterFilterData(
  selectConstituencyId,
  selectedmandal,
  selectedvillage
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}`
  );
  return response.data;

  // const response = await fetch(
  //   `${url}${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function casteVillageVoterFilterData(
  selectedconstituency,
  selectedmandal,
  selectedvillage
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.casteAtMPConstituencyDashboardAPI}${selectedconstituency}&mandal=${selectedmandal}&ward_village=${selectedvillage}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.casteAtMPConstituencyDashboardAPI}${selectedconstituency}&mandal=${selectedmandal}&ward_village=${selectedvillage}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function favourVillageVoterFilterData(
  selectedconstituencies,selectedmandals,selectedvillages
) {
  const mandal = selectedmandals.length > 0? `&mandal=${selectedmandals}`:''
  const village = selectedvillages.length > 0 ? `&ward_village=${selectedvillages}`: ''
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(
    `${urlData.favourMPConstituencyDashboardAPI}${selectedconstituencies}${mandal}${village}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.favourMPConstituencyDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function pollingVoterFilterData(
  selectConstituencyId,
  selectedmandal,
  selectedvillage,
  selectedPollingStation
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const url =
    selectedmandal.length > 0
      ? `${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}&polling_booth=${selectedPollingStation}`
      : `${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&polling_booth=${selectedPollingStation}`;
  const response = await axiosInstance.get(url);
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.constituencyMPVoterDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}&polling_booth=${selectedPollingStation}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function castePollingVoterFilterData(
  selectedconstituencies,selectedmandals,selectedvillages,selectedpollingstations
) {
  const mandal = selectedmandals.length > 0? `&mandal=${selectedmandals}`:''
  const village = selectedvillages.length > 0 ? `&ward_village=${selectedvillages}`: ''
  const pollings = selectedpollingstations.length > 0 ? `&polling_booth=${selectedpollingstations}`: ''
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const url =`${urlData.casteAtMPConstituencyDashboardAPI}${selectedconstituencies}${mandal}${village}${pollings}`
  const response = await axiosInstance.get(url);
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.casteMandalConstituencyDashboardAPI}${selectedmandal}&ward_village=${selectedvillage}&polling_booth=${selectedPollingStation}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
export async function favourPollingVoterFilterData(
  selectConstituencyId,
  selectedmandal,
  selectedvillage,
  selectedPollingStation
) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const url =
    selectedmandal.length > 0
      ? `${urlData.favourMPConstituencyDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}&polling_booth=${selectedPollingStation}`
      : `${urlData.favourMPConstituencyDashboardAPI}${selectConstituencyId}&polling_booth=${selectedPollingStation}`;
  const response = await axiosInstance.get(url);
  return response.data;

  // const response = await fetch(
  //   `${url}${urlData.favourMPConstituencyDashboardAPI}${selectConstituencyId}&mandal=${selectedmandal}&ward_village=${selectedvillage}&polling_booth=${selectedPollingStation}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const responseData = response.json();
  // return responseData;
}
//completed
export async function importantpeople() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const polling = await axiosInstance.get(`${urlData.impPeopleDashboardAPI}`);
  return polling.data;
  // const polling = await fetch(`${url}${urlData.impPeopleDashboardAPI}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  const importantdata = polling.json();
  return importantdata;
}
export async function getwardissues(selectedconstituencies,selectedmandals,selectedvillages,selectedpollingstations) {
  const constituencies = selectedconstituencies.length > 0 ?`?constituency_id=${selectedconstituencies}`:''
  const mandal = selectedmandals.length > 0? `&mandal=${selectedmandals}`:''
  const village = selectedvillages.length > 0 ? `&ward_village=${selectedvillages}`: ''
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const resp = await axiosInstance.get(`${urlData.wradvillagegetissues}${constituencies}${mandal}${village}`)
  return resp.data;
}

export async function todopostDashboard(eventtodo) {
  // const tokenData = JSON.parse(localStorage.getItem("data"));
  const tododata = { todo_title: `${eventtodo}` };
  const response = await axiosInstance.post(
    `${urlData.toDoDashboardAPI}`,
    tododata
  );
  return response.data;

  // const response = await fetch(`${url}${urlData.toDoDashboardAPI}`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  //   body: JSON.stringify({ todo_title: `${eventtodo}` }),
  // });
  // const postdata = response.json();
  // return postdata;
}

export async function sendBulkSMSITO(smsDataMobile, smsMessage) {
  const tokenData = JSON.parse(localStorage.getItem("data"));

  // const response = await fetch(`https://api.itoconnect.online/sms/sendbulksms`, {
  //   method: "POST",
  //   headers: {
  //     "access-control-allow-origin" : "*",
  //     "Content-type": "application/json; charset=UTF-8",
  //     "Accept" : "application/json",
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  //   body: JSON.stringify(
  //     {
  //       "users":"C360soft",
  //       "keys":"4566b49e8aXX",
  //       "contacts":"+917411484910",
  //       "content":"Hello Abhilash Welcome to iToconnect Setting your password is easy. Just press the button below and follow the instructions. We/'ll have you up and running in no time. 77 If you did not make this request then please ignore this sms. Thanks, CSFTAI",
  //       "senderids": "CSFTAI",
  //       "templates": "1707167844054150447"
  //       }
  //   ),
  // })
  // const postdata = response.json();
  // return postdata;

  const response = await fetch(
    `https://api.itoconnect.online/sms/sendbulksms`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        users: "C360soft",
        keys: "4566b49e8aXX",
        contacts: "+917411484910",
        content:
          "Hello Abhilash Welcome to iToconnect Setting your password is easy. Just press the button below and follow the instructions. We/'ll have you up and running in no time. 77 If you did not make this request then please ignore this sms. Thanks, CSFTAI",
        senderids: "CSFTAI",
        templates: "1707167844054150447",
      }),
    }
  );

  const responseData = response.json();
  return responseData;
}

export async function sendBulkSMS(smsDataMobile, smsMessage) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  // const response = await axiosInstance.post(`https://smslogin.co/v3/api.php?username=c360sft&apikey=8b64819ce753a1260163&senderid=CSFTAI&mobile=${smsDataMobile}&message=${smsMessage}`,smsDataMobile, smsMessage);
  // return response.data;
  const response = await fetch(
    `https://smslogin.co/v3/api.php?username=c360sft&apikey=8b64819ce753a1260163&senderid=CSFTAI&mobile=${smsDataMobile}&message=${smsMessage}`,
    {
      method: "POST",
      headers: {
        "access-control-allow-origin": "*",
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${tokenData.token}`,
      },
    }
  );
  const postdata = response.json();
  return postdata;
}

export async function todogetDashboard() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`${urlData.toDoDashboardAPI}`);
  return response.data;
  // const response = await fetch(`${url}${urlData.toDoDashboardAPI}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  const postdata = response.json();
  return postdata;
}

export async function tododeleteDashboard(deletetodo) {
  // const tokenData = JSON.parse(localStorage.getItem("data"));

  const response = await axiosInstance.delete(
    `${urlData.toDoDashboardAPI}?id=${deletetodo}`
  );
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.toDoDashboardAPI}?id=${deletetodo}`,
  //   {
  //     method: "DELETE",
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  // const postdata = response.json();
  // return postdata;
}

export async function logout() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`${urlData.signout}`);
  return response.data;
  // const polling = await fetch(`${url}${urlData.signout}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  // const importantdata = polling.json();
  // return importantdata;
}

// ********* signup ***************//

export async function signUp(formData) {
  const response = await fetch(`${url}${urlData.signUp}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formData),
  });

  const responseData = response.json();
  return responseData;
}
// ********* verify otp ***************//

export async function verifyOtp(otpData) {
  const response = await axiosInstance.post(`${urlData.verifyOtp}`, otpData);
  return response.data;

  // const response = await fetch(`${url}${urlData.verifyOtp}`, {
  //   method: "POST",
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify(otpData),
  // });

  // const responseData = response.json();
  // return responseData;
}

//*********** Resend OTP  ************** */
export async function resendOtp(otpmail) {
  const response = await axiosInstance.get(`${urlData.resendOtp}${otpmail}`);
  return response.data;
  // const response = await await fetch(`${url}${urlData.resendOtp}${otpmail}`);
  // const responseData = response.json();
  // return responseData;
}

//************ get State and role data for signup  ************* */

export async function getStateAndRoleData(otpmail) {
  const response = await fetch(`${url}${urlData.getStateAndRoleDataSignup}`);
  const responseData = response.json();
  return responseData;
}

//************ Get district data   ************* */

export async function getDistrictData(stateId) {
  const response = await fetch(
    `${url}${urlData.getDistrictDataSignup}${stateId}`
  );
  const responseData = response.json();
  return responseData;
}

//************ Get Constituency data for MLA/MP role  ************* */

export async function getConstituencyDataForMLAorMP(roleId, districtId) {
  const response = await fetch(
    `${url}/dropdown/?user_type_id=${roleId}&dist_id=${districtId}`
  );
  const responseData = response.json();
  return responseData;
}

//******************Create Password ************************* */
export async function createNewPassword(updatedbearertoken, createPassword) {
  const passwordencode = encode(passwordkey);
  const currentpassword = encode(createPassword.password);
  const confirmcurrentpassword = `${currentpassword}${passwordencode}`;
  const confirmpassword = encode(createPassword.ConfirmPassword);
  const confirmpassowrdencoded = `${confirmpassword}${passwordencode}`;

  const response = await fetch(`${url}${urlData.setPassword}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "origin-when-cross-origin", // Use a compatible policy
    body: JSON.stringify({
      password: confirmcurrentpassword,
      ConfirmPassword: confirmpassowrdencoded,
      Token: updatedbearertoken,
    }),
  });
  const responseData = response.json();
  return responseData;
}

// ****************Forgot Password *********************/

export async function forgotPassword(resetPassword) {
  try {
    const response = await axiosInstance.get(
      `${urlData.forgotPassword}${resetPassword}`
    );
    return response.data;
    // const response = fetch(`${url}${urlData.forgotPassword}${resetPassword}`);
    return response.then((data) => {
      return data.json();
    });
    const responseData = response.json();
    return response.json;
  } catch (error) {
    console.log(error);
  }
}

// ******************* Roles Page ***********************

export class RoleDataService {
  constructor() {
    if (this.tokenData) {
      this.headerConfig = {
        headers: {
          Authorization: "Bearer " + this.tokenData.token,
        },
      };
    }
  }

  tokenData = JSON.parse(localStorage.getItem("data"));
  headerConfig = {};
  serverURL = process.env.REACT_APP_SERVER_CONFIG;
  // serverURL = process.env.REACT_APP_TEST_SERVER_CONFIG;
  getRolesURL = this.serverURL + urlData.roles.getRoles;
  addOrUpdateRolesURL = this.serverURL + urlData.roles.addRoles;
  getPermissionsURL = this.serverURL + urlData.roles.getPermissions;
  getScreensURL = this.serverURL + urlData.roles.getScreens;

  // const response = await axiosInstance.get('/sms/dltdata');
  // return response.data;
  getRoles() {
    return axios.get(this.getRolesURL, this.headerConfig);
  }

  getPermissions(role) {
    return axios.get(this.getPermissionsURL + role, this.headerConfig);
  }

  addRole(data) {
    return axios.post(this.addOrUpdateRolesURL, data, this.headerConfig);
  }

  editRole(data) {
    return axios.patch(this.addOrUpdateRolesURL, data, this.headerConfig);
  }

  getScreens() {
    return axios.get(this.getScreensURL, this.headerConfig);
  }
}

// ****************change password **************

export async function passwrodChange(oldpassword, newpassword) {
  // const tokenData = JSON.parse(localStorage.getItem("data"));
  const oldpasswordencode = encode(oldpassword);
  const newpasswordencode = encode(newpassword);
  const encodenewpasswordkey = encode(passwordkey);

  const changepasswordData = {
    old_password: `${oldpasswordencode}${encodenewpasswordkey}`,
    new_password: `${newpasswordencode}${encodenewpasswordkey}`,
  };

  const response = await axiosInstance.post(
    `${urlData.changepassword}`,
    changepasswordData
  );
  return response.data;

  // const response = await fetch(`${url}`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  //   body: JSON.stringify(),
  // });
  // const responseData = response.json();
  // return responseData;
}

/*****Reports Page */
export class ReportsDataService {
  constructor() {
    if (this.tokenData) {
      this.headerConfig = {
        headers: {
          Authorization: "Bearer " + this.tokenData.token,
        },
      };
    }
  }

  tokenData = JSON.parse(localStorage.getItem("data"));
  headerConfig = {};
  serverURL = process.env.REACT_APP_SERVER_CONFIG;
  getS3ReportsURL = this.serverURL + urlData.reports.getS3Reports;
  getReportsURL = this.serverURL + urlData.reports.getReports;
  getReportsByKeyURL = this.serverURL + urlData.reports.getReportByKey;
  getS3Reports() {
    return axios.get(this.getS3ReportsURL, this.headerConfig);
  }
  getReports() {
    return axios.post(this.getReportsURL, {}, this.headerConfig);
    // return axiosInstance.get();
  }
  getReportsByKey(key) {
    return axios.get(this.getReportsByKeyURL + key, this.headerConfig);
  }
  getBlobFile(url) {
    let headersConfig = {
      headers: {
        // Authorization: "Bearer " + this.tokenData.token,
        responseType: "blob",
      },
    };
    return axios.get(url, headersConfig);
  }
}

export async function profilePic(croppedimageName) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  // const response = await axiosInstance.get(`${urlData.profilePicendpoint}?file=${croppedimageName}`);
  // return response.data;
  const response = await fetch(
    `${url}${urlData.profilePicendpoint}?file=${croppedimageName}`,
    {
      headers: {
        Authorization: `Bearer ${tokenData.token}`,
      },
    }
  );
  const responseData = response.json();
  return responseData;
}

export async function getProfilepic(selectedconstituencies,selectedmandals,selectedvillages,selectedpollingstations) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`${urlData.getprofileendpoint}`);
  return response.data;
  // const response = await fetch(
  //   `${url}${urlData.getprofileendpoint}`,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${tokenData.token}`,
  //     },
  //   }
  // );
  const responseData = response.json();
  return responseData;
}
