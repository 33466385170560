import React, { useCallback, useContext, useRef } from "react";
// import React, { useRef } from ‘react’;
import ReactDOM from "react-dom";
import { useReactToPrint } from "react-to-print";
import { useState, useEffect } from "react";
import polygon from "../Assets/Polygonvotersdata.png";
import { urlData } from "../config/apiconfig";
import "./Votersdata.css";
import newvoter from "../Assets/addnewvoter.png";
import AddVoterspopup from "./AddVoterspopup";
import excel from "../Assets/excellogo.png";
import refresh from "../Assets/refreshbtn.png";
import filter from "../Assets/filterbtn.png";
import filterBtn from "../Assets/filter_btn.png";
import Printer from '../Assets/Printer.png'
import pdf from "../Assets/pdflogo.png";
import settings from "../Assets/settingslogo.png";
import jsonResponse from "../../src/Assets/response.json";
import html2pdf from "html2pdf.js";
import printMobile from "../../src/Assets/print_iconmobile.png"

import {
  votersData,
  votersDataConstituency,
  votersDataMandal,
  votersDataVillage,
  votersDatapollingStation,
  csvconverter,
  pdfconverter,
  deleteVoter,
  searchvoterId,
  getvoterPrintList,
} from "../Service/voterdataservice";
import SettingsInfovotersdata from "./SettingsInfovotersdata";
import loadingring from "../Assets/loadingring.png";
import loadinglogo from "../Assets/logoitoconnect.png";
import LoaderWrapper from "../components/LoaderWrapper";
import { RoleDataContext } from "../AppContextComponent";
import { ROLES_PAGE_NAME_CONSTANTS } from "../Utils/utils";
import { faL } from "@fortawesome/free-solid-svg-icons";
import ThermalPrintComponent from "./ThermalPrintComponent";

function Votersdata() {
  const [selectedvoter, setSelectedvoter] = useState();
  const [closenav, setClosenav] = useState(true);
  const [respclosenav, setRespClosenav] = useState(false);
  const [votersdataIsopen, setvotersdataIsopen] = useState(false);
  const [SearchtermvoterID, setSearchtermvoterID] = useState("");
  const [serchdata, setSearchdata] = useState();
  const [occupation, setOccupation] = useState();
  const [education, setEducation] = useState();
  const [LoadingpageRender, setLoadingpageRender] = useState(true);

  const toggleDropdown = () => {
    setvotersdataIsopen(!votersdataIsopen);
  };
  const toggleClicked = () => {
    setClosenav(true);
    setRespClosenav(true);
  };
  const toggleopend = () => {
    setClosenav(false);
    setRespClosenav(false);
  };
  const handlecancleDeletevoter = () => {
    setSelectedvoter();
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpages, setTotalpages] = useState();
  const pagesCount = Math.ceil(totalpages / 50);
  const [objectsPerPage, setObjectsPerPage] = useState(50);
  const [deleteVoters, setDeletevoters] = useState(1);
  const [searchStatus, setSearchStatus] = useState(true);
  const { roleDataService } = useContext(RoleDataContext);
  const [gotopage, setGotopage] = useState(null);
  const [navigate, setNavigate] = useState();

  const [filteredData, setFilteredData] = useState([]); // State to hold filtered results
  const [searchTermVoterID, setSearchTermVoterID] = useState("");
  const [loadingscreen, setLoadingscreen] = useState(false);

  // input filed search
  const handleSearch = () => {
    // Perform search operation using searchTermVoterID
    // Update filteredData with the search output
    // Replace this with your actual search logic using searchTermVoterID
    const results = data.filter((item) =>
      item.voter_id.includes(searchTermVoterID)
    );
    setFilteredData(results);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handledeleteVoter = () => {
    deleteVoter(selectedvoter)
      .then((resp) => {
        setSelectedvoter();
      })
      .then(() => {
        setDeletevoters((prevState) => prevState + 1);
      })
      .finally(() => {
        setSearchtermvoterID("");
      });
  };

  function handleNextPage() {
    setCurrentPage((prevPage) => prevPage + 1);
  }
  function handlePreviousPage() {
    setCurrentPage((prevPage) => prevPage - 1);
  }
  const [addvotersclicked, setAddvotersclicked] = useState(false);

  const handleClickedpopup = () => {
    setAddvotersclicked(true);
  };
  const cancleaddvoters = () => {
    setAddvotersclicked(false);
    setVotermoduleType("");
  };
  

  const [filterbutton, setFilterbutton] = useState(false);
  const handleclickedFilter = () => {
    setFilterbutton(!filterbutton);
    setSettingbutton(false);
  };

  const [settingbutton, setSettingbutton] = useState(false);

  const handleclickedSettingsbutton = () => {
    setSettingbutton(!settingbutton);
    setFilterbutton(false);
  };
  const handlecloseColumnsection = () => {
    setSettingbutton(false);
  };
  const handleapplysettingsClose = () => {
    setSettingbutton(false);
  };
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const roledata = tokenData.user_data.role;

  // const roledata = 'MP'

  const [constituencyDropdown, setconstituencyDropdown] = useState(false);
  const [selectedConstituencyId, setselectedConstituencyId] = useState([]);
  const [constituencyOptions, setconstituencyoption] = useState([]);

  const toggleConstituencydropdown = () => {
    setconstituencyDropdown(!constituencyDropdown);
  };

  const dropdownconstituencyRef = useRef(null);

  useEffect(() => {
    roleDataService.getPageRole(ROLES_PAGE_NAME_CONSTANTS.VoterData);
    const handleClickOutside = (event) => {
      if (
        dropdownconstituencyRef.current &&
        !dropdownconstituencyRef.current.contains(event.target)
      ) {
        setconstituencyDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    if (selectedConstituencyId.includes(option.constituency_id)) {
      setselectedConstituencyId(
        selectedConstituencyId.filter((id) => id !== option.constituency_id)
      );
    } else {
      setselectedConstituencyId([
        ...selectedConstituencyId,
        option.constituency_id,
      ]);
    }
  };

  const initialconstituencyRender = useRef(true);
  useEffect(() => {
    if (initialconstituencyRender.current) {
      votersDataConstituency().then((data) => {
        setconstituencyoption(data.constituencies);
        if (roledata === "MLA") {
          setselectedConstituencyId(
            data.constituencies.map((option) => option.constituency_id)
          );
        } else {
          setselectedConstituencyId(
            data.constituencies.map((option) => option.constituency_id)
          );
        }
        setvillageOptions(data.villages);
        setpolingstationOptions(data.polling);
        setmandalOptions(data.mandal);
      });

      initialconstituencyRender.current = false;
    }
  }, []);

  const selectedConstituencies = selectedConstituencyId.join(",");

  const firstSelectedConstituency =
    selectedConstituencyId.length > 0
      ? constituencyOptions.find(
          (option) => option.constituency_id === selectedConstituencyId[0]
        ).mla_constituency_name
      : "Constituency";
  const selectedCount = selectedConstituencyId.length - 1;

  // mandaldropdown
  const [mandaldropdown, setmandaldropdown] = useState(false);
  const [selectedmandalmandalOptions, setselectedmandalmandalOptions] =
    useState([]);
  const [mandalOptions, setmandalOptions] = useState([]);

  const togglemandaldropdown = () => {
    setmandaldropdown(!mandaldropdown);
  };

  const dropdownmandalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownmandalRef.current &&
        !dropdownmandalRef.current.contains(event.target)
      ) {
        setmandaldropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectedmandals = selectedmandalmandalOptions.join(",");
  useEffect(() => {
    if (!LoadingpageRender) {
      votersDataMandal(selectedConstituencies).then((resp) => {
        if (selectedConstituencies.length > 0) {
          setmandalOptions(resp);
        } else {
          setmandalOptions(resp.mandal);
        }
      });
    } else {
      setLoadingpageRender(false);
    }
  }, [selectedConstituencies]);

  const handleMandalOptionclick = (option) => {
    if (selectedmandalmandalOptions.includes(option.mandal_id)) {
      setselectedmandalmandalOptions(
        selectedmandalmandalOptions.filter((id) => id !== option.mandal_id)
      );
    } else {
      setselectedmandalmandalOptions([
        ...selectedmandalmandalOptions,
        option.mandal_id,
      ]);
    }
  };

  const prevselectedConstituencyId = useRef([]);

  useEffect(() => {
    if (selectedConstituencyId !== prevselectedConstituencyId.current.value) {
      setselectedmandalmandalOptions([]);
      setMandalsvotersdata("");
    }
    prevselectedConstituencyId.current = selectedConstituencyId;
  }, [selectedConstituencyId]);

  const firstSelectedmandal =
    selectedmandalmandalOptions.length > 0
      ? mandalOptions.find(
          (option) => option.mandal_id === selectedmandalmandalOptions[0]
        )?.mandal_name
      : "Mandal/Town";
  const selectedmandalcount = selectedmandalmandalOptions.length - 1;

  // villageData

  const [isVillageOpen, setisVillageOpen] = useState(false);
  const [selectedVillagesoptionIds, setselectedVillagesoptionIds] = useState(
    []
  );
  const [villageOptions, setvillageOptions] = useState([]);

  const villageToggleDropdown = () => {
    setisVillageOpen(!isVillageOpen);
  };

  const dropdownvillagesRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownvillagesRef.current &&
        !dropdownvillagesRef.current.contains(event.target)
      ) {
        setisVillageOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectedvillages = selectedVillagesoptionIds.join(",");

  useEffect(() => {
    if (!LoadingpageRender) {
      votersDataVillage(selectedmandals).then((resp) => {
        if (selectedmandals.length > 0) {
          setvillageOptions(resp);
        } else {
          setvillageOptions(resp.villages);
        }
      });
    } else {
      setLoadingpageRender(false);
    }
  }, [selectedmandals]);

  const handleVillageclick = (option) => {
    if (selectedVillagesoptionIds.includes(option.ward_village_id)) {
      setselectedVillagesoptionIds(
        selectedVillagesoptionIds.filter((id) => id !== option.ward_village_id)
      );
    } else {
      setselectedVillagesoptionIds([
        ...selectedVillagesoptionIds,
        option.ward_village_id,
      ]);
    }
  };

  const prevselectedmandalmandalOptions = useRef([]);

  useEffect(() => {
    if (
      selectedmandalmandalOptions.length !==
      prevselectedmandalmandalOptions.current.value
    ) {
      setselectedVillagesoptionIds([]);
      setVillagessvotersdata("");
    }
    prevselectedmandalmandalOptions.current = selectedmandalmandalOptions;
  }, [selectedmandalmandalOptions]);

  const firstSelectedvillage =
    selectedVillagesoptionIds.length > 0
      ? villageOptions.find(
          (option) => option.ward_village_id === selectedVillagesoptionIds[0]
        )?.ward_village_name
      : "Ward/Villages";
  const selectedvillagecount = selectedVillagesoptionIds.length - 1;

  // pollingstation
  const [ispollingstationoption, setispollingstationoption] = useState(false);
  const [selectedpollingstationid, setselectedpollingstationid] = useState([]);
  const [polingstationOptions, setpolingstationOptions] = useState([]);

  const dropdownpollingstationsRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownpollingstationsRef.current &&
        !dropdownpollingstationsRef.current.contains(event.target)
      ) {
        setispollingstationoption(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const pollingstationtoggle = () => {
    setispollingstationoption(!ispollingstationoption);
  };

  useEffect(() => {
    if (!LoadingpageRender) {
      votersDatapollingStation(selectedvillages).then((resp) => {
        if (selectedVillagesoptionIds.length > 0) {
          setpolingstationOptions(resp);
        } else {
          setpolingstationOptions(resp.polling);
        }
      });
    } else {
      setLoadingpageRender(false);
    }
  }, [selectedvillages]);

  useEffect(() => {
    if (initialconstituencyRender.current) {
      votersDataConstituency().then((data) => {
        setconstituencyoption(data.constituencies);
        if (roledata === "MLA") {
          setselectedConstituencyId(
            data.constituencies.map((option) => option.constituency_id)
          );
        } else {
          setselectedConstituencyId(
            data.constituencies.map((option) => option.constituency_id)
          );
        }
        setvillageOptions(data.villages);
        setpolingstationOptions(data.polling);
        setmandalOptions(data.mandal);
      });

      initialconstituencyRender.current = false;
    }
  }, []);

  const handlepollingstationclick = (option) => {
    if (selectedpollingstationid.includes(option.polling_booth_id)) {
      setselectedpollingstationid(
        selectedpollingstationid.filter((id) => id !== option.polling_booth_id)
      );
    } else {
      setselectedpollingstationid([
        ...selectedpollingstationid,
        option.polling_booth_id,
      ]);
    }
  };

  const prevselectedVillagesoptionIds = useRef([]);
  useEffect(() => {
    if (
      selectedVillagesoptionIds.length !==
      prevselectedVillagesoptionIds.current.value
    ) {
      setselectedpollingstationid([]);
      setpollingstationssvotersdata("");
    }
    prevselectedVillagesoptionIds.current = selectedVillagesoptionIds;
  }, [selectedVillagesoptionIds]);

  const firstSelectedpollimgstation =
    selectedpollingstationid.length > 0
      ? polingstationOptions.find(
          (option) => option.polling_booth_id === selectedpollingstationid[0]
        )
      : null;

  const displayText = firstSelectedpollimgstation
    ? `${firstSelectedpollimgstation.polling_booth_id} - ${firstSelectedpollimgstation.polling_booth_name}`
    : "Polling Station";

  const selectedpollingstationcount = selectedpollingstationid.length - 1;
  const selectedpollingstations = selectedpollingstationid.join(",");

  const constituenciesParams = `${urlData.votersdataConstituencyapi}${selectedConstituencies}&`;
  const mandalParams = `${urlData.votersdataMandalapi}${selectedmandals}&`;
  const villageParams = `${urlData.votersdataVillageapi}${selectedvillages}&`;
  const pollingstationParams = `${urlData.votersdatapollingstationapi}${selectedpollingstations}&`;

  // votersdata
  const [constituenciesvotersdata, setConstituencyvotersdata] = useState("");
  const [mandalsvotersdata, setMandalsvotersdata] = useState("");
  const [villagesvotersdata, setVillagessvotersdata] = useState("");
  const [pollingstationsvotersdata, setpollingstationssvotersdata] =
    useState("");

  const handleApplyClicked = () => {
    switch (true) {
      case selectedpollingstations.length > 0:
        setpollingstationssvotersdata(pollingstationParams);
        setVillagessvotersdata("");
        setMandalsvotersdata("");
        setConstituencyvotersdata("");
        break;
      case selectedvillages.length > 0:
        setVillagessvotersdata(villageParams);
        setMandalsvotersdata("");
        setConstituencyvotersdata("");
        break;
      case selectedmandals.length > 0:
        setMandalsvotersdata(mandalParams);
        setConstituencyvotersdata("");
        break;
      case selectedConstituencies.length > 0:
        setConstituencyvotersdata(constituenciesParams);
        break;
      default:
        setpollingstationssvotersdata("");
        setVillagessvotersdata("");
        setMandalsvotersdata("");
        setConstituencyvotersdata("");
    }
  };

  useEffect(() => {
    if (SearchtermvoterID == "") {
      setSearchStatus(true);
      setSearchdata();
    }
  });

  // votersdata Api call
  const initialRender = useRef(true);

  const [checkedSelectedData, setCheckedSelectedData] = useState([]);
  const [printVoterData, setprintVoterData] = useState();
  //

  // PrintVoterSlipFunctionality
  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("data", jsonResponse.voters);
        // setprintVoterData(jsonResponse.voters);
        // const dataToPrint = jsonResponse.voters.slice(0, 2000);
        // console.log("data", dataToPrint);
        // setprintVoterData(dataToPrint);
        // setIsLoading(false);

       getvoterPrintList().then((resp) => {
          // if (resp.voters.length > 0) {
          console.log("data", resp.voters);
          setprintVoterData(resp.voters);
          // setIsLoading(false);
          // }
          // else {
          //   setIsLoading(false);
          //   alert("Data not found");
          // }
        });
        // setIsLoading(false);

        // You can now use jsonResponse directly
      } catch (error) {
        setIsLoading(false);

        console.error("Error fetching data:", error);
      }
      // finally {
      //   setIsLoading(false);
      // }
    };

    fetchData();

    // getvoterPrintList();
  }, []);

  useEffect(() => {
    if (searchStatus === true) {
      setIsLoading(true);
      setDownloadStatus("Loading...");
      votersData(
        currentPage,
        constituenciesvotersdata,
        mandalsvotersdata,
        villagesvotersdata,
        pollingstationsvotersdata
      )
        .then((resp) => {
          console.log("results", resp.results);
          setData(resp.results);
          setTotalpages(resp.count);
        })

        .finally(() => {
          setIsLoading(false);
          setDownloadStatus();
        });
    } else {
      setData(serchdata);
      setTotalpages(1);
    }
  }, [
    currentPage,
    constituenciesvotersdata,
    mandalsvotersdata,
    villagesvotersdata,
    pollingstationsvotersdata,
    deleteVoters,
    serchdata,
  ]);

  // csvdownload code

  const handleSelectedData = (checkedValues) => {
    setCheckedSelectedData(checkedValues);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState("");
  const voterClicked = () => {
    const csvbody = {
      filters: checkedSelectedData,
      filters_body: {},
    };

    if (selectedConstituencies.length > 0) {
      const digitsArray = selectedConstituencies.split(",");

      const parsedNumbersArray = digitsArray.map((num) => parseInt(num));

      csvbody.filters_body.constituency_id = parsedNumbersArray;
    }

    if (selectedmandals.length > 0) {
      const digitsArray = selectedmandals.split(",");

      const parsedNumbersArray = digitsArray.map((num) => parseInt(num));

      csvbody.filters_body.mandal = parsedNumbersArray;
    }

    if (selectedvillages.length > 0) {
      const digitsArray = selectedvillages.split(",");

      const parsedNumbersArray = digitsArray.map((num) => parseInt(num));

      csvbody.filters_body.ward_village = parsedNumbersArray;
    }

    if (selectedpollingstations.length > 0) {
      const digitsArray = selectedpollingstations.split(",");

      const parsedNumbersArray = digitsArray.map((num) => parseInt(num));

      csvbody.filters_body.polling_booth = parsedNumbersArray;
    }

    setIsLoading(true);

    setDownloadStatus("Downloading...");

    csvconverter(csvbody)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");

        link.href = url;

        link.setAttribute("download", "data.csv");

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      })

      .catch((error) => {
        setDownloadStatus("Error: Download failed.");
      })

      .finally(() => {
        setIsLoading(false);
        setDownloadStatus("");
      });
  };

  const downloadPdf = () => {
    const pdfbody = {
      filters: checkedSelectedData,
      filters_body: {},
    };

    if (selectedConstituencies.length > 0) {
      const digitsArray = selectedConstituencies.split(",");

      const parsedNumbersArray = digitsArray.map((num) => parseInt(num));

      pdfbody.filters_body.constituency_id = parsedNumbersArray;
    }

    if (selectedmandals.length > 0) {
      const digitsArray = selectedmandals.split(",");

      const parsedNumbersArray = digitsArray.map((num) => parseInt(num));

      pdfbody.filters_body.mandal = parsedNumbersArray;
    }

    if (selectedvillages.length > 0) {
      const digitsArray = selectedvillages.split(",");

      const parsedNumbersArray = digitsArray.map((num) => parseInt(num));

      pdfbody.filters_body.ward_village = parsedNumbersArray;
    }

    if (selectedpollingstations.length > 0) {
      const digitsArray = selectedpollingstations.split(",");

      const parsedNumbersArray = digitsArray.map((num) => parseInt(num));

      pdfbody.filters_body.polling_booth = parsedNumbersArray;
    }

    setIsLoading(true);

    pdfconverter(pdfbody)
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");

        a.href = url;

        a.download = "file.pdf";

        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);
      })

      .catch((error) => {
        setDownloadStatus("Error: Download failed.");
        
      })

      .finally(() => {
        setIsLoading(false);
      });
  };
  const [pdfdownload, setPdfdownload] = useState(false);
  const [pdfmessage, setPdfmessage] = useState(false);

  const [voterid, setVoterid] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [middlename, setMiddlename] = useState();
  const [gender, setGender] = useState();
  const [mobile, setMobile] = useState();
  const [subcaste, setSubcaste] = useState();
  const [caste, setCaste] = useState();
  const [dob, setDob] = useState();
  const [age, setAge] = useState();
  const [email, setEmail] = useState();
  const [religion, setReligion] = useState();
  const [company, setCompany] = useState();
  const [title, setTitle] = useState();
  const [designation, setDesignation] = useState();
  const [mandal, setMandal] = useState();
  const [village, setVillage] = useState();
  const [votertype, setVotertype] = useState();
  const [votefavour, setVotefavour] = useState();
  const [relationtype, setRelationtype] = useState();
  const [relationname, setRelationname] = useState();
  const [houseno, setHouseno] = useState();
  const [street, setStreet] = useState();
  const [revenueDivision, setRevenueDevision] = useState();
  const [sectionName, setSectionName] = useState();
  const [pollingbootheId, setPollingBootheID] = useState();
  const [pollingboothadress, setPollingBoothAddress] = useState();
  const [city, setCity] = useState();
  const [postalcode, setPostalcode] = useState();
  const [state, setState] = useState();
  const [xx, setXX] = useState();
  const [currentLoc, setCurrentloc] = useState();
  const [houseHead, setHouseHead] = useState();
  const [ipvid, setipvid] = useState();
  const [ipph, setipph] = useState();
  const [refname, setrefName] = useState();
  const [refvid, setrefvid] = useState();
  const [ipname, setipname] = useState();
  const [voterCount, setvoterCount] = useState();
  const [refph, setrefph] = useState();
  const [Notes, setNotes] = useState();
  const [printData, setPrintData] = useState();

  useEffect(() => {
    if (checkedSelectedData.indexOf("Voter ID") !== -1) {
      setVoterid(true);
    } else {
      setVoterid(false);
    }
    if (checkedSelectedData.indexOf("First Name") !== -1) {
      setFirstname(true);
    } else {
      setFirstname(false);
    }
    if (checkedSelectedData.indexOf("Last Name") !== -1) {
      setLastname(true);
    } else {
      setLastname(false);
    }
    if (checkedSelectedData.indexOf("Middle Name") !== -1) {
      setMiddlename(true);
    } else {
      setMiddlename(false);
    }
    if (checkedSelectedData.indexOf("Gender") !== -1) {
      setGender(true);
    } else {
      setGender(false);
    }
    if (checkedSelectedData.indexOf("Mobile Number") !== -1) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    if (checkedSelectedData.indexOf("Sub Caste") !== -1) {
      setSubcaste(true);
    } else {
      setSubcaste(false);
    }
    if (checkedSelectedData.indexOf("Caste Name") !== -1) {
      setCaste(true);
    } else {
      setCaste(false);
    }
    if (checkedSelectedData.indexOf("DOB") !== -1) {
      setDob(true);
    } else {
      setDob(false);
    }
    if (checkedSelectedData.indexOf("Age") !== -1) {
      setAge(true);
    } else {
      setAge(false);
    }
    if (checkedSelectedData.indexOf("Email") !== -1) {
      setEmail(true);
    } else {
      setEmail(false);
    }
    if (checkedSelectedData.indexOf("Religion") !== -1) {
      setReligion(true);
    } else {
      setReligion(false);
    }
    if (checkedSelectedData.indexOf("Company Name") !== -1) {
      setCompany(true);
    } else {
      setCompany(false);
    }
    if (checkedSelectedData.indexOf("Title") !== -1) {
      setTitle(true);
    } else {
      setTitle(false);
    }
    if (checkedSelectedData.indexOf("Designation") !== -1) {
      setDesignation(true);
    } else {
      setDesignation(false);
    }
    if (checkedSelectedData.indexOf("Mandal") !== -1) {
      setMandal(true);
    } else {
      setMandal(false);
    }
    if (checkedSelectedData.indexOf("Village") !== -1) {
      setVillage(true);
    } else {
      setVillage(false);
    }
    if (checkedSelectedData.indexOf("Voter Type") !== -1) {
      setVotertype(true);
    } else {
      setVotertype(false);
    }
    if (checkedSelectedData.indexOf("Favour Party") !== -1) {
      setVotefavour(true);
    } else {
      setVotefavour(false);
    }
    if (checkedSelectedData.indexOf("Relation Type") !== -1) {
      setRelationtype(true);
    } else {
      setRelationtype(false);
    }
    if (checkedSelectedData.indexOf("Relation Name") !== -1) {
      setRelationname(true);
    } else {
      setRelationname(false);
    }
    if (checkedSelectedData.indexOf("House No") !== -1) {
      setHouseno(true);
    } else {
      setHouseno(false);
    }
    if (checkedSelectedData.indexOf("Street") !== -1) {
      setStreet(true);
    } else {
      setStreet(false);
    }
    if (checkedSelectedData.indexOf("Revenue Division") !== -1) {
      setRevenueDevision(true);
    } else {
      setRevenueDevision(false);
    }
    if (checkedSelectedData.indexOf("Section Name") !== -1) {
      setSectionName(true);
    } else {
      setSectionName(false);
    }
    if (checkedSelectedData.indexOf("Polling Booth ID") !== -1) {
      setPollingBootheID(true);
    } else {
      setPollingBootheID(false);
    }
    if (checkedSelectedData.indexOf("Polling Booth Address") !== -1) {
      setPollingBoothAddress(true);
    } else {
      setPollingBoothAddress(false);
    }
    if (checkedSelectedData.indexOf("City") !== -1) {
      setCity(true);
    } else {
      setCity(false);
    }
    if (checkedSelectedData.indexOf("Postalcode") !== -1) {
      setPostalcode(true);
    } else {
      setPostalcode(false);
    }
    if (checkedSelectedData.indexOf("State") !== -1) {
      setState(true);
    } else {
      setState(false);
    }
    if (checkedSelectedData.indexOf("XX") !== -1) {
      setXX(true);
    } else {
      setXX(false);
    }
    if (checkedSelectedData.indexOf("LOC") !== -1) {
      setCurrentloc(true);
    } else {
      setCurrentloc(false);
    }
    if (checkedSelectedData.indexOf("HH") !== -1) {
      setHouseHead(true);
    } else {
      setHouseHead(false);
    }
    if (checkedSelectedData.indexOf("IP VID") !== -1) {
      setipvid(true);
    } else {
      setipvid(false);
    }
    if (checkedSelectedData.indexOf("IP Ph#") !== -1) {
      setipph(true);
    } else {
      setipph(false);
    }
    if (checkedSelectedData.indexOf("Ref Name") !== -1) {
      setrefName(true);
    } else {
      setrefName(false);
    }
    if (checkedSelectedData.indexOf("Ref VID") !== -1) {
      setrefvid(true);
    } else {
      setrefvid(false);
    }
    if (checkedSelectedData.indexOf("IP Name") !== -1) {
      setipname(true);
    } else {
      setipname(false);
    }
    if (checkedSelectedData.indexOf("Count") !== -1) {
      setvoterCount(true);
    } else {
      setvoterCount(false);
    }
    if (checkedSelectedData.indexOf("Ref Ph#") !== -1) {
      setrefph(true);
    } else {
      setrefph(false);
    }
    if (checkedSelectedData.indexOf("Notes") !== -1) {
      setNotes(true);
    } else {
      setNotes(false);
    }
    if (checkedSelectedData.indexOf("Occupation") !== -1) {
      setOccupation(true);
    } else {
      setOccupation(false);
    }
    if (checkedSelectedData.indexOf("Education") !== -1) {
      setEducation(true);
    } else {
      setEducation(false);
    }
  }, [checkedSelectedData]);

  const [Editvalue, setEditValue] = useState();
  const [selectedview, setselectedview] = useState();
  const [votermoduleType, setVotermoduleType] = useState();

  const handleViewClose = () => {
    setselectedview();
  };

  function handleTitleClick(voter_id) {
    setData(
      data.map((item) => {
        if (item.voter_id === voter_id) {
          return { ...item, showDeleteButton: !item.showDeleteButton };
        } else {
          return { ...item, showDeleteButton: false };
        }
      })
    );
  }

  useEffect(() => {
    if (filterbutton === false) {
      setPdfmessage(false);
    }
  });

  const handleAddvoterscloseClicked = () => {
    setAddvotersclicked(false);
  };

  // polling station pdf download

  const searchbittonClickes = (e) => {
    setIsLoading(true);
    searchvoterId(SearchtermvoterID).then((resp) => {
      if (resp.voters.length > 0) {
        setSearchStatus(false);
        setSearchdata(resp.voters);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        alert("Data not found");
      }
    });
  };

  useEffect(() => {
    getvoterPrintList().then((resp) => {
      if (resp.voters.length > 0) {
        // setSearchStatus(false);
        console.log("resp", resp);
        // setprintVoterData = resp.response;
        // setSearchdata(resp.voters);
        // setIsLoading(false)
      } else {
        setIsLoading(false);
        // alert("Data not found");
      }
    });
  }, []);

  const getAllVoterListforPrint = () => {
    // setIsLoading(false)
    searchvoterId().then((resp) => {
      // setIsLoading(false)
      if (resp.voters.length > 0) {
        // setSearchStatus(false);
        console.log("resp", resp);
        setprintVoterData = resp.response;
        setSearchdata(resp.voters);
        setIsLoading(false)
      } else {
        setIsLoading(false);
        alert("Data not found");
      }
    });
  };

  useEffect(() => {
    if (selectedpollingstationid.length > 0) {
      setPdfdownload(true);
    } else {
      setPdfdownload(false);
    }
    if (selectedpollingstationid.length > 1) {
      setPdfdownload(false);
      setPdfmessage(true);
    } else {
      setPdfmessage(false);
    }
  }, [selectedpollingstationid]);

  const [searchConstituency, setSearchConstituency] = useState("");
  const [searchmandals, setSearchMandals] = useState("");
  const [searchvillages, setSearchVillages] = useState("");
  const [searchpollingstations, setSearchpollingstations] = useState("");

  const filteredconstituency = searchConstituency
    ? constituencyOptions.filter((option) =>
        option.mla_constituency_name
          .toLowerCase()
          .includes(searchConstituency.toLowerCase())
      )
    : constituencyOptions;

  const filteredmandals = searchmandals
    ? mandalOptions.filter((option) =>
        option.mandal_name.toLowerCase().includes(searchmandals.toLowerCase())
      )
    : mandalOptions;

  const filteredvillages = searchvillages
    ? villageOptions.filter((option) =>
        option.ward_village_name
          .toLowerCase()
          .includes(searchvillages.toLowerCase())
      )
    : villageOptions;

  const filteredpollingstations = searchpollingstations
    ? polingstationOptions.filter((option) =>
        option.polling_booth_name
          .toLowerCase()
          .includes(searchpollingstations.toLowerCase())
      )
    : polingstationOptions;

  const handleSearchconstituency = (event) => {
    const inputValue = event.target.value;
    // Check if the input value contains numbers
    if (/\d/.test(inputValue)) {
      event.target.value = searchConstituency; // Reset the input value to the previous search term
      return; // Ignore the input if it contains numbers
    }
    setSearchConstituency(event.target.value);
  };

  const handleSearchmandals = (event) => {
    const inputValue = event.target.value;
    // Check if the input value contains numbers
    if (/\d/.test(inputValue)) {
      event.target.value = searchmandals; // Reset the input value to the previous search term
      return; // Ignore the input if it contains numbers
    }
    setSearchMandals(event.target.value);
  };

  const handleSearchvillages = (event) => {
    const inputValue = event.target.value;
    // Check if the input value contains numbers
    if (/\d/.test(inputValue)) {
      event.target.value = searchvillages; // Reset the input value to the previous search term
      return; // Ignore the input if it contains numbers
    }
    setSearchVillages(event.target.value);
  };

  const handleSearchpollingstations = (event) => {
    const inputValue = event.target.value;
    // Check if the input value contains numbers
    if (/\d/.test(inputValue)) {
      event.target.value = searchpollingstations; // Reset the input value to the previous search term
      return; // Ignore the input if it contains numbers
    }
    setSearchpollingstations(event.target.value);
  };

  useEffect(() => {
    if (mandaldropdown == false) {
      setSearchMandals("");
    }
    if (isVillageOpen == false) {
      setSearchVillages("");
    }
    if (ispollingstationoption == false) {
      setSearchpollingstations("");
    }
    if (constituencyDropdown == false) {
      setSearchConstituency("");
    }
  }, [
    constituencyDropdown,
    mandaldropdown,
    isVillageOpen,
    ispollingstationoption,
  ]);

  const handlekeyDownconstituency = (event) => {
    if (event.key === "Escape") {
      setconstituencyDropdown(false);
    }
  };
  const handlekeyDownmandals = (event) => {
    if (event.key === "Escape") {
      setmandaldropdown(false);
    }
  };
  const handlekeyDownvilages = (event) => {
    if (event.key === "Escape") {
      setisVillageOpen(false);
    }
  };
  const handlekeyDownpollingstations = (event) => {
    if (event.key === "Escape") {
      setispollingstationoption(false);
    }
  };
  const clearfilters = () => {
    setselectedmandalmandalOptions([]);
    setselectedVillagesoptionIds([]);
    setselectedpollingstationid([]);
  };
  const gotoinputChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    let numericValue = parseInt(inputValue);
    if (isNaN(numericValue) || numericValue < 1) {
      numericValue = "";
    } else if (numericValue > pagesCount) {
      numericValue = pagesCount;
    }
    setGotopage(numericValue.toString());
  };

  const handleGotopage = () => {
    if (gotopage) {
      setCurrentPage(gotopage);
    }
  };

  // const PrintDataComponent = () => {
  // const handlePrint = () => {
  //   window.print();
  // };

  // function PrintPdf() {
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //  content: () => componentRef.current,
  //  documentTitle: 'Visitor Pass',
  //  onAfterPrint: () => console.log('Printed PDF successfully!'),
  // });
  // const handlePrint = (item) => {
  //   // Create a print window with 3x5 inch dimensions
  //   const printWindow = window.open('', '');

  //   printWindow.document.open();
  //   printWindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css"></head><body>');
  //   printWindow.document.write('<div style="width:100%; height:100%;">'); // Container div for the content

  //   // Render your component inside the container
  //   ReactDOM.render(<ThermalPrintComponent data={item} />, printWindow.document.body);

  //   printWindow.document.write('</div>'); // Close the container div
  //   printWindow.document.write('</body></html>');
  //   printWindow.document.close();

  //   // Trigger printing
  //   printWindow.print();
  //   printWindow.close();
  // };

  // const handlePrint = (item) => {
  //   const printWindow = window.open('', '_blank', 'width=800,height=600');
  //   const content = `
  //     <html>
  //       <head>
  //         <link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css">
  //       </head>
  //       <body>
  //         <iframe src="about:blank" id="iframe" width="100%" height="100%"></iframe>
  //         <script>
  //           const iframe = document.getElementById('iframe');
  //           iframe.onload = function() {
  //             const doc = iframe.contentDocument;
  //             doc.open();
  //             doc.write('<html><head><link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css"></head><body>');
  //             doc.write('<div style="width:100%; height:100%;">'); // Container div for the content
  //             doc.write(\`${ReactDOM.render(<ThermalPrintComponent data={item} />)}\`);
  //             doc.write('</div>'); // Close the container div
  //             doc.write('</body></html>');
  //             doc.close();
  //           };
  //           iframe.src = 'about:blank';
  //         </script>
  //       </body>
  //     </html>
  //   `;

  //   printWindow.document.open();
  //   printWindow.document.write(content);
  //   printWindow.document.close();
  // };

  // const handlePrint = (item) => {
  //   // Create an iframe element
  //   const iframe = document.createElement('iframe');
  //   iframe.style.width = '3in'; // Set the width to 3 inches
  //   iframe.style.height = '5in'; // Set the height to 5 inches

  //   // Append the iframe to the document body
  //   document.body.appendChild(iframe);

  //   // Access the iframe's document
  //   const iframeDocument = iframe.contentDocument;

  //   // Write the content to the iframe's document
  //   if (iframeDocument) {
  //     iframeDocument.open();
  //     iframeDocument.write('<html><head><link rel="stylesheet" type="text/css" href="print.css"></head><body>');
  //     iframeDocument.write('<div style="width:100%; height:100%;">'); // Container div for the content

  //     // Render your component inside the container
  //     ReactDOM.render(<ThermalPrintComponent data={item} />, iframeDocument.body);

  //     iframeDocument.write('</div>'); // Close the container div
  //     iframeDocument.write('</body></html>');
  //     iframeDocument.close();

  //     // Trigger printing
  //     iframe.contentWindow.print();
  //   }

  //   // Remove the iframe after printing
  //   document.body.removeChild(iframe);
  // };

  // const handlePrint = (item) => {
  //   // Create a div to hold the content
  //   const content = document.createElement('div');

  //   // Render your component inside the container
  //   ReactDOM.render(<ThermalPrintComponent data={item} />, content);

  //   // Wait for a short time to ensure the content is fully rendered
  //   setTimeout(() => {
  //     // Generate PDF
  //     html2pdf(content, {
  //       margin: 10,
  //       filename: 'your-filename.pdf',
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  //       onBeforeSave: () => {
  //         // Cleanup: Remove the temporary content node after saving the PDF
  //         ReactDOM.unmountComponentAtNode(content);
  //         document.body.removeChild(content);
  //       },
  //     });
  //   }, 2000); // Adjust the delay as needed
  // };

  // Working Data

  const handlePrint = (item) => {
    setIsLoading(true);

    // Create an iframe element
    const iframe = document.createElement("iframe");
    iframe.style.width = "100%"; // Set the width to 100% of the viewport
    iframe.style.height = "100%"; // Set the height to 100% of the viewport

    // Set iframe attributes to ensure printing compatibility
    iframe.setAttribute("scrolling", "no"); // Disable scrolling
    iframe.setAttribute("frameborder", "0"); // Remove borders

    // Append the iframe to the document body
    document.body.appendChild(iframe);

    // Access the iframe's document
    const iframeDocument = iframe.contentDocument;

    // Write the content to the iframe's document
    if (iframeDocument) {

      iframeDocument.open();
      iframeDocument.write(
        '<html><head><link rel="stylesheet" type="text/css" href="print.css"></head><body>'
      );
      iframeDocument.write('<div style="width:100%; height:100%;">'); // Container div for the content

      // Render your component inside the container
      ReactDOM.render(
        <ThermalPrintComponent data={item} />,
        iframeDocument.body
      );

      iframeDocument.write("</div>"); // Close the container div
      iframeDocument.write("</body></html>");

      iframeDocument.close();
      
    }


    // Wait for the content to fully load in the iframe before triggering printing
    iframe.onload = function () {
      iframe.contentWindow.print();
      setIsLoading(false);

    };

    // Remove the iframe after printing
    iframe.addEventListener("afterprint", function () {
      document.body.removeChild(iframe);
    });
    // setIsLoading(false);


    // Trigger printing
    // iframe.contentWindow.print();
  };

  //          const handlePrint = (item) => {
  //       // Create an iframe element
  //       const iframe = document.createElement('iframe');
  //    iframe.style.display = 'none'; // Hide the iframe
  // iframe.style.width = '3in'; // Set the width to 3 inches
  // iframe.style.height = '5in'; // Set the height to 5 inches

  //       // Append the iframe to the document body
  //       document.body.appendChild(iframe);

  //       // Access the iframe's document
  //       const iframeDocument = iframe.contentDocument;

  //       // Write the content to the iframe's document
  //       if (iframeDocument) {
  //         iframeDocument.open();
  //         iframeDocument.write('<html><head><link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css"></head><body>');

  //         // Create a container div to render your React component
  //         iframeDocument.write('<div id="print-container"></div');

  //         // Render your component inside the container
  //         ReactDOM.render(<ThermalPrintComponent data={item} />, iframeDocument.getElementById('print-container'));

  //         iframeDocument.write('</body></html>');
  //         iframeDocument.close();
  //         iframe.contentWindow.print();

  //         document.body.removeChild(iframe);
  //       }
  //     };

  //       const handlePrint = (item) => {
  //   // Create an iframe element
  //   const iframe = document.createElement('iframe');
  //   iframe.style.display = 'none'; // Hide the iframe
  //   iframe.style.width = '3in'; // Set the width to 3 inches
  //   iframe.style.height = '5in'; // Set the height to 5 inches

  //   // Append the iframe to the document body
  //   document.body.appendChild(iframe);

  //   // Access the iframe's document
  //   const iframeDocument = iframe.contentDocument;

  //   // Write the content to the iframe's document
  //   if (iframeDocument) {
  //     iframeDocument.open();
  //     iframeDocument.write('<html><head><link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css"></head><body>');

  //     // Create a container div to render your React component
  //     iframeDocument.write('<div id="print-container"></div');

  //     // Render your component inside the container
  //     ReactDOM.render(<ThermalPrintComponent data={item} />, iframeDocument.getElementById('print-container'));

  //     iframeDocument.write('</body></html>');
  //     iframeDocument.close();

  //     // Wait for a moment for the content to render in the iframe
  //     setTimeout(() => {
  //       // Check if it's an Android device
  //       if (window.navigator.userAgent.indexOf('Android') !== -1) {
  //         // For Android, use iframe.contentWindow.print() to trigger the print dialog
  //         iframe.contentWindow.print();
  //       } else {
  //         // For non-Android devices, use iframe.contentWindow.printToPDF() if available
  //         if (typeof iframe.contentWindow.printToPDF === 'function') {
  //           iframe.contentWindow.printToPDF();
  //           iframe.contentWindow.print();

  //         } else {
  //           // Use iframe.contentWindow.print() as a fallback for non-Android devices
  //           iframe.contentWindow.print();
  //         }
  //       }

  //       // Remove the iframe after printing
  //       document.body.removeChild(iframe);
  //     }, 1000); // Adjust the delay as needed
  //   }
  // };

  // const handlePrint = (item) => {
  //   // Create a hidden iframe
  //   const iframe = document.createElement('iframe');
  //   iframe.style.display = 'none';

  //   // Append the iframe to the document body
  //   document.body.appendChild(iframe);

  //   // Access the iframe's document
  //   const iframeDocument = iframe.contentDocument;

  //   // Write the content to the iframe's document
  //   if (iframeDocument) {
  //     iframeDocument.open();
  //     iframeDocument.write('<html><head><link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css"></head><body>');

  //     // Render your component inside the container
  //     iframeDocument.write('<div id="print-container">Your content here</div>'); // Replace 'Your content here' with the content you want to print

  //     iframeDocument.write('</body></html>');
  //     iframeDocument.close();

  //     // Wait for a moment for the content to render in the iframe
  //     setTimeout(() => {
  //       // Use the WebView's built-in print function to print the content
  //       window.frames[iframe.name].print();

  //       // Remove the iframe after printing
  //       document.body.removeChild(iframe);
  //     }, 1000); // Adjust the delay as needed
  //   }
  // };

  // const handlePrint = (item) => {
  //   // Create an iframe element
  //   const iframe = document.createElement('iframe');
  //   iframe.style.display = 'none'; // Hide the iframe
  //   iframe.style.width = '3in'; // Set the width to 3 inches
  //   iframe.style.height = '5in'; // Set the height to 5 inches

  //   // Append the iframe to the document body
  //   document.body.appendChild(iframe);

  //   // Access the iframe's document
  //   const iframeDocument = iframe.contentDocument;

  //   // Write the content to the iframe's document
  //   if (iframeDocument) {
  //     iframeDocument.open();
  //     iframeDocument.write('<html><head><link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css"></head><body>');

  //     // Create a container div to render your React component
  //     iframeDocument.write('<div id="print-container"></div');

  //     // Render your component inside the container
  //     ReactDOM.render(<ThermalPrintComponent data={item} />, iframeDocument.getElementById('print-container'));

  //     iframeDocument.write('</body></html>');
  //     iframeDocument.close();

  //     // Wait for the iframe's content to fully load
  //     iframe.onload = function() {
  //       // Use the iframe's print method to trigger the print dialog
  //       iframe.contentWindow.print();

  //       // Remove the iframe after printing
  //       document.body.removeChild(iframe);
  //     };
  //   }
  // };

  // const handlePrint = (item) => {
  //   const printWindow = window.open('', '', 'width=300,height=500'); // Specify dimensions suitable for mobile
  //   printWindow.document.open();
  //   printWindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="print.css"></head><body>');
  //   printWindow.document.write('<div style="width:100%; height:100%;">'); // Container div for the content

  //   // Render your component inside the container
  //   ReactDOM.render(<ThermalPrintComponent data={item} />, printWindow.document.body);

  //   printWindow.document.write('</div>'); // Close the container div
  //   printWindow.document.write('</body></html>');
  //   printWindow.document.close();

  //   // To give the content time to load, add a small delay before printing
  //   setTimeout(function() {
  //     // Trigger printing
  //     printWindow.print();
  //     printWindow.close();
  //   }, 1000); // Adjust the delay as needed
  // };

  //       const handlePrint = (item) => {
  //   const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //   const width = isMobile ? '3in' : '300px';
  //   const height = isMobile ? '5in' : '500px';

  //   const printWindow = window.open('', '', `width=${width},height=${height}`);
  //   printWindow.document.open();
  //   printWindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="print.css"></head><body>');
  //   printWindow.document.write('<div style="width:100%; height:100%;">'); // Container div for the content

  //   // Render your component inside the container
  //   ReactDOM.render(<ThermalPrintComponent data={item} />, printWindow.document.body);

  //   printWindow.document.write('</div>'); // Close the container div
  //   printWindow.document.write('</body></html>');
  //   printWindow.document.close();

  //   // To give the content time to load, add a small delay before triggering printing
  //   setTimeout(function() {
  //     // Trigger printing
  //     printWindow.print();
  //     printWindow.close();
  //   }, 1000); // Adjust the delay as needed
  // };

  // const handlePrint = (item) => {
  //   const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //   const width = isMobile ? '3in' : '3in'; // Set the width to 3 inches for both mobile and desktop
  //   const height = isMobile ? '5in' : '5in'; // Set the height to 5 inches for both mobile and desktop

  //   // Create an iframe
  //   const iframe = document.createElement('iframe');
  //   iframe.style.width = '3in';
  //   iframe.style.height = '5in';
  //   iframe.style.display = 'none'; // Hide the iframe

  //   document.body.appendChild(iframe);

  //   iframe.onload = function () {
  //     const printDocument = iframe.contentDocument;

  //     // Write the content to the iframe's document
  //     printDocument.open();
  //     printDocument.write('<html><head><link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css"></head><body>');
  //     printDocument.write('<div style="width:100%; height:100%;">'); // Container div for the content

  //     // Render your component inside the container
  //     ReactDOM.render(<ThermalPrintComponent data={item} />, printDocument.body);

  //     printDocument.write('</div>'); // Close the container div
  //     printDocument.write('</body></html>');
  //     printDocument.close();

  //     // Trigger printing
  //     iframe.contentWindow.print();
  //   };

  //   // Set the iframe's src to initiate loading
  //   iframe.src = 'about:blank';

  //   // To give the content time to load, add a small delay before triggering printing
  //   setTimeout(function () {
  //     // Remove the iframe after printing
  //     document.body.removeChild(iframe);
  //   }, 2000); // Adjust the delay as needed
  // };

  // const handlePrint = (item) => {
  //   const width = '420mm'; // Set the width to 420mm (A2 envelope width)
  //   const height = '594mm'; // Set the height to 594mm (A2 envelope height)

  //   // Create an iframe
  //   const iframe = document.createElement('iframe');
  //   iframe.style.width = width;
  //   iframe.style.height = height;
  //   iframe.style.display = 'none'; // Hide the iframe

  //   document.body.appendChild(iframe);

  //   iframe.onload = function () {
  //     const printDocument = iframe.contentDocument;

  //     // Write the content to the iframe's document
  //     printDocument.open();
  //     printDocument.write('<html><head><link rel="stylesheet" type="text/css" href="ThermalPrintComponent.css"></head><body>');
  //     printDocument.write('<div style="width:100%; height:100%;">'); // Container div for the content

  //     // Render your component inside the container
  //     ReactDOM.render(<ThermalPrintComponent data={item} />, printDocument.body);

  //     printDocument.write('</div>'); // Close the container div
  //     printDocument.write('</body></html>');
  //     printDocument.close();

  //     // Trigger printing
  //     iframe.contentWindow.print();
  //   };

  //   // Set the iframe's src to initiate loading
  //   iframe.src = 'about:blank';

  //   // To give the content time to load, add a small delay before triggering printing
  //   setTimeout(function () {
  //     // Remove the iframe after printing
  //     document.body.removeChild(iframe);
  //   }, 2000); // Adjust the delay as needed
  // };

  // const handlePrint = (item) => {
  //   // const width = '100%'; // Set the width to 100% for full-width printing
  //   // const height = '100%'; // Set the height to 100% for full-height printing

  //   // Create an iframe
  //   const iframe = document.createElement('iframe');
  //   iframe.style.width = '0';
  //   iframe.style.height = '0';
  //   iframe.style.position = 'absolute';
  //   iframe.style.top = '-9999px';
  //   iframe.style.left = '-9999px';
  //   iframe.style.display = 'none'; // Hide the iframe
  //   document.body.appendChild(iframe);

  //   iframe.onload = function () {
  //     const printDocument = iframe.contentDocument;

  //     // Write the content to the iframe's document
  //     printDocument.open();
  //     printDocument.write('<html><head><style></style></head><body>');
  //     printDocument.write('<div style="width:100%; height:100%;">'); // Container div for the content

  //     // Render the complete data mapping in the iframe directly
  //     printDocument.write('<div class="thermal-print-content" style="text-align:center; border:2px dotted black; padding:10px;">');
  //     printDocument.write('<div class="row">');
  //     printDocument.write('<img class="login_emslogo" src="https://itoconnectapp.c360soft.ai/static/media/itoclogo.177bcd8fbbfae7581125.png" alt="not visible" style="max-width:70px;" />');
  //     printDocument.write('<br></br>');
  //     printDocument.write('<span style="padding:10px; font-weight:800; font-size:20px; margin-top:5px;">ItoConnect</span>');
  //     printDocument.write('</div>');
  //     printDocument.write('<br></br>');

  //     // SL No, Voter Name, Age, Voter ID, Part Number
  //     printDocument.write('<div class="voter-info" style="text-align:left;">');
  //     printDocument.write('<div class="sl-no" style="margin-top:5px;">Sl No : ' + item.slNo + '</div>');
  //     printDocument.write('<div class="voter-name" style="margin-top:5px;">Name : ' + item.first_name + '</div>');
  //     printDocument.write('<div class="age" style="margin-top:5px;">Age : ' + item.age + '</div>');
  //     printDocument.write('<div class="voter-id" style="margin-top:5px;">Voter ID : ' + item.voter_id + '</div>');
  //     printDocument.write('<div class="part-number" style="margin-top:5px;">Part Number : ' + item.partNumber + '</div>');
  //     printDocument.write('</div>');

  //     // Divider
  //     printDocument.write('<hr class="divider" />');

  //     // Booth Number, Voting Center
  //     printDocument.write('<div class="booth-info" style="text-align:left;">');
  //     printDocument.write('<div class="booth-number" style="margin-top:5px;">Booth Number: ' + item.polling_booth + '</div>');
  //     printDocument.write('<div class="voting-center" style="margin-top:5px;">Voting Center: ' + item.polling_booth__polling_booth_name + '</div>');
  //     printDocument.write('</div>');

  //     // Divider
  //     printDocument.write('<hr class="divider" />');

  //     // Voting Date and Time
  //     printDocument.write('<div class="voting-info" style="text-align:left;">');
  //     printDocument.write('<div class="voting-date" style="margin-top:5px;">Voting Date: ' + item.votingDate + '</div>');
  //     printDocument.write('<div class="voting-time" style="margin-top:5px;">Voting Time: ' + item.votingTime + '</div>');
  //     printDocument.write('</div>');
  //     printDocument.write('<br></br>');

  //     printDocument.write('<div class="row" style="text-align:center;">');
  //     printDocument.write('<span>*********************</span>');
  //     printDocument.write('<div class="thank-you-message">');
  //     printDocument.write('<span>THANK YOU</span>');
  //     printDocument.write('</div>');
  //     printDocument.write('<span>*********************</span>');
  //     printDocument.write('</div>');

  //     printDocument.write('</div>'); // Close the container div
  //     printDocument.write('</body></html>');
  //     printDocument.close();

  //     // Trigger printing
  //     iframe.contentWindow.print();
  //   };

  //   // Set the iframe's src to initiate loading
  //   iframe.src = 'about:blank';

  //   // To give the content time to load, add a small delay before triggering printing
  //   setTimeout(function () {
  //     // Remove the iframe after printing
  //     document.body.removeChild(iframe);
  //   }, 2000); // Adjust the delay as needed
  // };
  // const handlePrint = (item) => {
  //   const printWindow = window.open('', '_blank', 'width=800,height=600');

  //   printWindow.document.write('<html><head><style></style></head><body>');
  //   printWindow.document.write('<div class="card-container" style="display: flex; justify-content: space-between;">');
  //   printWindow.document.write('<div class="card" style="border: 2px dotted black; flex: 1;">');
  //   printWindow.document.write('<div class="image" style="text-align: center;">');
  //   printWindow.document.write('<img src="src\Assets\itoclogo.png" alt="not visible" style="max-width: 70px;" />');
  //   printWindow.document.write('</div>');
  //   printWindow.document.write('</div>');
  //   printWindow.document.write('<div class="card" style="border: 2px dotted black; flex: 1;">');
  //   printWindow.document.write('<div class="voter-info" style="text-align: left; padding: 10px;">');
  //   printWindow.document.write('<div class="sl-no" style="margin-top: 5px;">Sl No : ' + item.slNo + '</div>');
  //   printWindow.document.write('<div class="voter-name" style="margin-top: 5px;">Name : ' + item.first_name + '</div>');
  //   printWindow.document.write('<div class="age" style="margin-top: 5px;">Age : ' + item.age + '</div>');
  //   printWindow.document.write('<div class="voter-id" style="margin-top: 5px;">Voter ID : ' + item.voter_id + '</div>');
  //   printWindow.document.write('<div class="part-number" style="margin-top: 5px;">Part Number : ' + item.partNumber + '</div>');
  //   printWindow.document.write('</div>');
  //   printWindow.document.write('</div>');
  //   printWindow.document.write('</div>');
  //   printWindow.document.write('<button onclick="window.print()">Print</button>'); // Add a "Print" button
  //   printWindow.document.write('</body></html>');
  //   printWindow.document.close();

  //   // Close the popup when the user closes it
  //   printWindow.onbeforeunload = function () {
  //     printWindow.close();
  //   };
  // };
  const [searchResults, setSearchResults] = useState([]);

  return (
    <div className="voters_data_main_div d-flex">
            {loadingscreen ? <LoaderWrapper /> : null}

      <div className="top_nav_holder_main">
        <div className="d-flex flex-column votersdata_container">
          <div className="votersdata_text_maindiv">
            <span className="votersdata_text_ems">Voters Data</span>
            <div className="search_voter_id d-flex align-items-center">
              <input
                type="text"
                className="voterid_search_input"
                placeholder="Enter Voter ID Number"
                value={searchTermVoterID}
                onChange={(e) => setSearchTermVoterID(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              {/* <button
                className="search_button_voterid"
                onClick={searchbittonClickes}
              >
                Search
              </button> */}
            </div>

            {roleDataService.access.create ? (
              <button
                className="add_new_voter_btn d-flex align-items-center"
                onClick={() => {
                  handleClickedpopup();
                  setVotermoduleType("newvoter");
                }}
              >
                <img
                  className="newvoters_img"
                  src={newvoter}
                  alt="not-visible"
                />
              </button>
            ) : (
              ""
            )}
            <button
              className="refreshlogo d-flex align-items-center"
              onClick={clearfilters}
            >
              <img
                className="refresh_img d-flex align-items-center"
                src={refresh}
                alt="not-visible"
              />
            </button>
            <button
              className="filter d-flex align-items-center"
              onClick={handleclickedFilter}
            >
              {/* <img src={filter} className="filter_img" alt="not-visible" /> */}
              <img src={filterBtn} className="filter_img" alt="not-visible"  />
            </button>
            <div
              style={{ display: filterbutton ? "block" : "none" }}
              className="voters_data_filter_dropdown"
            >
              <div className="dropdown_constituency_Main_div d-flex flex-column ">
                <button
                  ref={dropdownconstituencyRef}
                  className="dropdown_constituency_sub_div"
                  style={{
                    borderColor: roledata === "MLA" ? "green" : "#d8d8d8",
                  }}
                  onKeyDown={handlekeyDownconstituency}
                  onClick={toggleConstituencydropdown}
                >
                  {roledata === "MLA" ? null : (
                    <img
                      className="constituency_polygon_img"
                      src={polygon}
                      alt="not-visible"
                      style={{
                        transform: constituencyDropdown
                          ? "rotate(3.142rad)"
                          : "rotate(0deg)",
                      }}
                    />
                  )}

                  <div className="constancy_dropdown_header d-flex align-items-center">
                    <span className="constancy_spandiv">
                      {firstSelectedConstituency}
                    </span>
                    {selectedConstituencyId.length > 1 && (
                      <span className="dropdown_selected_count">
                        +{selectedCount}more
                      </span>
                    )}
                    {roledata === "MLA"
                      ? null
                      : constituencyDropdown && (
                          <input
                            type="text"
                            className="search_option_votersdata_dropdown"
                            placeholder="Search..."
                            // value={searchTerm}
                            onChange={handleSearchconstituency}
                            // ref={searchInputRef}
                            autoFocus
                          />
                        )}
                  </div>
                  {roledata === "MLA" ? null : (
                    <div>
                      {constituencyDropdown && (
                        <div className="constancy_dropdown-constituencyOptions">
                          {filteredconstituency.length > 0 ? (
                            filteredconstituency.map((option) => (
                              <button
                                onClick={() => handleOptionClick(option)}
                                key={option.constituency_id}
                                className="dropdown-option d-flex align-items-center "
                              >
                                <span>{option.mla_constituency_name}</span>
                                <input
                                  className="votersdata_checkboxesdropdown"
                                  type="checkbox"
                                  checked={selectedConstituencyId.includes(
                                    option.constituency_id
                                  )}
                                />
                              </button>
                            ))
                          ) : (
                            <div className="nodata_found_message">
                              NO DATA FOUND
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </button>

                <div
                  className="votersdata_mandal_dropdown "
                  ref={dropdownmandalRef}
                  onKeyDown={handlekeyDownmandals}
                >
                  <button
                    className="mandal_dropdown-header d-flex align-items-center"
                    onClick={togglemandaldropdown}
                  >
                    <img
                      className="constituency_polygon_img"
                      src={polygon}
                      alt="not-visible"
                      style={{
                        transform: mandaldropdown
                          ? "rotate(3.142rad)"
                          : "rotate(0deg)",
                      }}
                    />
                    <span className="mandal_first_name_votersdata">
                      {firstSelectedmandal}
                    </span>
                    {selectedmandalcount > 0 && (
                      <span className="dropdown_selected_count">
                        +{selectedmandalcount}more
                      </span>
                    )}
                    {mandaldropdown && (
                      <input
                        type="text"
                        className="search_option_votersdata_dropdown"
                        placeholder="Search..."
                        // value={searchTerm}
                        onChange={handleSearchmandals}
                        // ref={searchInputRef}
                        autoFocus
                      />
                    )}
                  </button>
                  {mandaldropdown && (
                    <div className="dropdown-options_maindiv">
                      {filteredmandals.length > 0 ? (
                        filteredmandals.map((option) => (
                          <button
                            key={option.mandal_id}
                            className="dropdown-option d-flex align-items-center"
                            onClick={() => handleMandalOptionclick(option)}
                          >
                            <span>{option.mandal_name}</span>
                            <input
                              className="votersdata_checkboxesdropdown"
                              type="checkbox"
                              checked={selectedmandalmandalOptions.includes(
                                option.mandal_id
                              )}
                            />
                          </button>
                        ))
                      ) : (
                        <div className="nodata_found_message">
                          NO DATA FOUND
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div ref={dropdownvillagesRef} onKeyDown={handlekeyDownvilages}>
                <button
                  className="dropdown_village_subdiv d-flex align-items-center"
                  onClick={villageToggleDropdown}
                >
                  <img
                    className="constituency_polygon_img"
                    src={polygon}
                    alt="not-visible"
                    style={{
                      transform: isVillageOpen
                        ? "rotate(3.142rad)"
                        : "rotate(0deg)",
                    }}
                  />
                  <span className="mandal_first_name_votersdata">
                    {firstSelectedvillage}
                  </span>
                  {selectedvillagecount > 0 && (
                    <span className="dropdown_selected_count">
                      +{selectedvillagecount}more
                    </span>
                  )}
                  {isVillageOpen && (
                    <input
                      type="text"
                      placeholder="Search..."
                      className="search_option_votersdata_dropdown"
                      // value={searchTerm}
                      onChange={handleSearchvillages}
                      // ref={searchInputRef}
                      autoFocus
                    />
                  )}
                </button>
                {isVillageOpen && (
                  <div className="dropdown_villadedata">
                    {filteredvillages.length > 0 ? (
                      filteredvillages.map((option) => (
                        <button
                          key={option.ward_village_id}
                          className="dropdown-option d-flex align-items-center"
                          onClick={() => handleVillageclick(option)}
                        >
                          <span>{option.ward_village_name}</span>
                          <input
                            className="votersdata_checkboxesdropdown"
                            type="checkbox"
                            checked={selectedVillagesoptionIds.includes(
                              option.ward_village_id
                            )}
                          />
                        </button>
                      ))
                    ) : (
                      <div className="nodata_found_message">NO DATA FOUND</div>
                    )}
                  </div>
                )}
              </div>
              <div>
                <div
                  className="drop_down_pollingstation_main_div"
                  onKeyDown={handlekeyDownpollingstations}
                  ref={dropdownpollingstationsRef}
                >
                  <button
                    className="pollingstation_dropdown-header d-flex align-items-center"
                    onClick={pollingstationtoggle}
                  >
                    <img
                      className="constituency_polygon_img"
                      src={polygon}
                      alt="not-visible"
                      style={{
                        transform: ispollingstationoption
                          ? "rotate(3.142rad)"
                          : "rotate(0deg)",
                      }}
                    />
                    <span className="mandal_first_name_votersdata">
                      {displayText}
                    </span>
                    {selectedpollingstationcount > 0 && (
                      <span className="dropdown_selected_count">
                        +{selectedpollingstationcount}more
                      </span>
                    )}
                    {ispollingstationoption && (
                      <input
                        type="text"
                        placeholder="Search..."
                        className="search_option_votersdata_dropdown"
                        onChange={handleSearchpollingstations}
                        autoFocus
                      />
                    )}
                  </button>
                  {ispollingstationoption && (
                    <div className="dropdown-options_pollingstation">
                      {filteredpollingstations.length > 0 ? (
                        filteredpollingstations.map((option) => (
                          <button
                            key={option.polling_booth_id}
                            className="dropdown-option d-flex align-items-center"
                            onClick={() => handlepollingstationclick(option)}
                          >
                            <span>
                              {option.polling_booth_no}-
                              {option.polling_booth_name}
                            </span>
                            <input
                              className="votersdata_checkboxesdropdown"
                              type="checkbox"
                              checked={selectedpollingstationid.includes(
                                option.polling_booth_id
                              )}
                            />
                          </button>
                        ))
                      ) : (
                        <div className="nodata_found_message">
                          NO DATA FOUND
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <button
                id="canclebt_in_voters_data_dropdowns"
                className="canclebt_in_voters_data_dropdowns"
                onClick={() => setFilterbutton(false)}
              >
                Cancel
              </button>
              <button
                className="applybtn_in_voters_data_dropdowns"
                onClick={() => {
                  handleApplyClicked();
                  setFilterbutton(false);
                }}
              >
                Apply
              </button>
            </div>
          </div>
          <button
            className="excelllogo d-flex align-items-center"
            onClick={voterClicked}
          >
            <img src={excel} className="excel_img" alt="not-visible" />
          </button>
          <button
            disabled={pdfdownload ? false : true}
            onClick={downloadPdf}
            className="pdflogo d-flex align-items-center"
          >
            <div
              className="pdfImg d-flex justify-content-center align-items-center"
              alt="not-visible"
              style={{ opacity: pdfdownload ? "1" : "0.5" }}
            >
              <img src={pdf} className="pdfcenter_Logo" alt="not visible" />
            </div>
          </button>
          <button
            className="settingslogo d-flex align-items-center"
            onClick={handleclickedSettingsbutton}
          >
            <img
              src={settings}
              className="settings_btn_img"
              alt="not-visible"
            />
          </button>

          <div className="images_for_responsive">
            {pdfmessage ? (
              <div className="pdsmessage_div">
                <span>Pdf is not available more than 1 polling stations</span>
              </div>
            ) : null}
          </div>

          <div
            className="settingsoptionMainDiv"
            style={{ display: settingbutton ? "block" : "none" }}
          >
            <SettingsInfovotersdata
              selectedData={handleSelectedData}
              oncancelcolumnsection={handlecloseColumnsection}
              onhandleApplysettingclose={handleapplysettingsClose}
            />
          </div>

          {/* start mobile new code div */}

          <div
            style={{ marginTop: "35vw" }}
            className="itoconnect_voters_data_mobile_main_div"
          >
            <span
              className="floating-print-button"
              // onClick={() => setselectedview(item.voter_id)}
              onClick={() => {
                handlePrint(printVoterData);
              }}
            >
              {/* Print Voter List */}
              <img className ="voters_data_printer_img" src={printMobile} alt="print"/>
            </span>
            <div className="votersid_title_name">
              <span id="itoconnect_voters_id_title">Voter IDs</span>
            </div>
            <div className="itoconnect_voters_id_child_div_main">

            {filteredData.length === 0 ? (
    <div className="data_not_available_voters">Data not available</div>
  ) : (
    filteredData.map((item) => (
      <div key={item.id} className="">
                          <div id="extra_inputs_for_tab"></div>
                          <div className="main_div_voters_display_data">
                          <div id="title_div_voters_data_first_column">
                    <span
                      className="voters_data_voters_id_"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleClickedpopup();
                        setEditValue(item.voter_id);
                        setVotermoduleType("view");
                      }}
                    >
                      {item.voter_id}
                    </span>
                  </div>

</div>

<div className="voters_data_first_name_age_caste_main">
                    <div className="voter_data_child_div_first_name_age_caste">
                      <div className="voters_display_first_name_">
                        <span >{item.first_name}</span>
                      </div>
                      <div className="voters_display_last_name_">
                        <span>{item.last_name}</span>
                      </div>
                      <div className="voters_display_gender_">
                        <span>{item.gender}</span>
                      </div>
                      <div className="voters_display_caste_">
                        <span>{item.sub_caste__caste__caste_name}</span>
                      </div>
                    </div>
                    </div>
      </div>
    ))
  )}

              {/* {filteredData.map((item) => ( */}
                {/* // <div key={item.id} className=""> */}
                
                {/* //   <div id="extra_inputs_for_tab"></div> */}
                {/* //   <div className="main_div_voters_display_data"> */}
                  {/* <div id="title_div_voters_data_first_column">
                    <span
                      className="voters_data_voters_id_"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleClickedpopup();
                        setEditValue(item.voter_id);
                        setVotermoduleType("view");
                      }}
                    >
                      {item.voter_id}
                    </span>
                  </div> */}
                  {/* <div className="edit_icon_main_div_voters">
                  <span
                        className="voters_data_edit-icon_edit"
                        onClick={() => {
                          handleClickedpopup();
                          setEditValue(item.voter_id);
                          setVotermoduleType("Edit");
                          setNavigate("votersdata");
                        }}
                      >
                        <i class="fa fa-pencil"></i>
                      </span>
                    </div> */}
                    {/* <div className="trash_main_div_voters">
                    <div
                    className="delete_btn_voters_data_delete d-flex align-items-center"
                          onClick={() => {
                            setSelectedvoter(item.voter_id);
                          }}
                        >
                        <i class="fa fa-trash"></i>
                        </div>
                      </div>  */}


                     {/* </div> */}
                  {/* <div className="voters_data_first_name_age_caste_main"> */}
                    {/* <div className="voter_data_child_div_first_name_age_caste">
                      <div className="voters_display_first_name_">
                        <span >{item.first_name}</span>
                      </div>
                      <div className="voters_display_last_name_">
                        <span>{item.last_name}</span>
                      </div>
                      <div className="voters_display_gender_">
                        <span>{item.gender}</span>
                      </div>
                      <div className="voters_display_caste_">
                        <span>{item.sub_caste__caste__caste_name}</span>
                      </div>
                    </div>

                    
                  </div> */}


                 {/* </div> */}
              {/* ))} */}
            </div>
          </div>
        </div>
        {/* <div className="d-flex align-items-center buttons_voterdata_next_prev_main_div
          buttons_voterdata_next_prev_main_div_new_design
          "> */}
        <div
          className="d-flex align-items-center buttons_voterdata_next_prev_main_div
         buttons_voterdata_next_prev_main_div_new_design
          "
        >
          {/* <div id="votersdata" className="total_pages">
            Pages : {pagesCount}
          </div> */}
          <div className="go_topage_main_div d-flex">
            <input
              className="go_topage_inputfield"
              type="numbers"
              value={gotopage}
              placeholder="Enter Page Number"
              onChange={gotoinputChange}
            />
            <button onClick={handleGotopage}>Go</button>
          </div>
          <button
            className="prev_button_votersdata"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            ❮
          </button>
          <div className="current_page_number_votersdata">{currentPage}</div>
          <button
            className="next_button_votersdata"
            onClick={handleNextPage}
            disabled={data.length < objectsPerPage}
          >
            ❯
          </button>
        </div>

        {/* End mobile new code div */}

        <div className="parent_horizontal_scroll_votersdata">
          {/* <span
            className="floating-print-button"
            // onClick={() => setselectedview(item.voter_id)}
            onClick={() => {
              handlePrint(printVoterData);
            }}
          >
            Print Voter List
          </span> */}
          <div className="horizontal_scroll_votersdata">
            <div className="d-flex top_headings_in_voters_data_main_div">
              <div
                className="top_heading_in_voters_data"
                id="extra_inputs_for_tab"
              >
                <span>Action</span>
              </div>
              {voterid ? (
                <div
                  className="top_heading_in_voters_data"
                  id="title_div_first_column"
                >
                  <span>Voter ID Number</span>
                </div>
              ) : null}
              {firstname ? (
                <div className="top_heading_in_voters_data">
                  <span>First Name</span>
                </div>
              ) : null}
              {middlename ? (
                <div className="top_heading_in_voters_data">
                  <span>Middle Name</span>
                </div>
              ) : null}

              {lastname ? (
                <div className="top_heading_in_voters_data">
                  <span>Last Name</span>
                </div>
              ) : null}
              {dob ? (
                <div
                  className="top_heading_in_voters_data"
                  id="extra_inputs_for_tab"
                >
                  <span>DOB</span>
                </div>
              ) : null}
              {age ? (
                <div
                  className="top_heading_in_voters_data"
                  id="extra_inputs_for_tab"
                >
                  <span>Age</span>
                </div>
              ) : null}

              {gender ? (
                <div
                  className="top_heading_in_voters_data"
                  id="extra_inputs_for_tab"
                >
                  <span>Gender</span>
                </div>
              ) : null}
              {religion ? (
                <div className="top_heading_in_voters_data">
                  <span>Religion</span>
                </div>
              ) : null}
              {caste ? (
                <div className="top_heading_in_voters_data">
                  <span>Caste Type</span>
                </div>
              ) : null}
              {subcaste ? (
                <div className="top_heading_in_voters_data">
                  <span>Caste</span>
                </div>
              ) : null}

              {mobile ? (
                <div
                  className="top_heading_in_voters_data"
                  id="extra_inputs_for_tab"
                >
                  <span>Mobile number</span>
                </div>
              ) : null}
              {email ? (
                <div className="top_heading_in_voters_data">
                  <span>Email</span>
                </div>
              ) : null}
              {company ? (
                <div className="top_heading_in_voters_data">
                  <span>Company Name</span>
                </div>
              ) : null}
              {title ? (
                <div className="top_heading_in_voters_data">
                  <span>Title</span>
                </div>
              ) : null}
              {designation ? (
                <div className="top_heading_in_voters_data">
                  <span>Designation</span>
                </div>
              ) : null}
              {mandal ? (
                <div className="top_heading_in_voters_data">
                  <span>Mandal</span>
                </div>
              ) : null}
              {village ? (
                <div className="top_heading_in_voters_data">
                  <span>Village</span>
                </div>
              ) : null}
              {votertype ? (
                <div className="top_heading_in_voters_data">
                  <span>Voter Type</span>
                </div>
              ) : null}
              {votefavour ? (
                <div className="top_heading_in_voters_data">
                  <span>Favour Party</span>
                </div>
              ) : null}
              {relationtype ? (
                <div className="top_heading_in_voters_data">
                  <span>Relation Type</span>
                </div>
              ) : null}
              {relationname ? (
                <div className="top_heading_in_voters_data">
                  <span>Relation Name</span>
                </div>
              ) : null}
              {houseno ? (
                <div className="top_heading_in_voters_data">
                  <span>House No</span>
                </div>
              ) : null}
              {street ? (
                <div className="top_heading_in_voters_data">
                  <span>Street</span>
                </div>
              ) : null}
              {revenueDivision ? (
                <div className="top_heading_in_voters_data">
                  <span>Revenue Devision</span>
                </div>
              ) : null}
              {sectionName ? (
                <div className="top_heading_in_voters_data">
                  <span>Section Name</span>
                </div>
              ) : null}
              {pollingbootheId ? (
                <div className="top_heading_in_voters_data">
                  <span>Polling Booth ID</span>
                </div>
              ) : null}
              {pollingboothadress ? (
                <div className="top_heading_in_voters_data">
                  <span>Polling Booth Address</span>
                </div>
              ) : null}
              {city ? (
                <div className="top_heading_in_voters_data">
                  <span>City</span>
                </div>
              ) : null}
              {postalcode ? (
                <div className="top_heading_in_voters_data">
                  <span>Postalcode</span>
                </div>
              ) : null}
              {state ? (
                <div className="top_heading_in_voters_data">
                  <span>State</span>
                </div>
              ) : null}
              {xx ? (
                <div className="top_heading_in_voters_data">
                  <span>Status</span>
                </div>
              ) : null}

              {currentLoc ? (
                <div className="top_heading_in_voters_data">
                  <span>Location</span>
                </div>
              ) : null}
              {houseHead ? (
                <div className="top_heading_in_voters_data">
                  <span>Head of House</span>
                </div>
              ) : null}
              {ipvid ? (
                <div className="top_heading_in_voters_data">
                  <span>Important Person VID</span>
                </div>
              ) : null}
              {ipname ? (
                <div className="top_heading_in_voters_data">
                  <span>Important Person Name</span>
                </div>
              ) : null}
              {ipph ? (
                <div className="top_heading_in_voters_data">
                  <span>Important Person Phone No</span>
                </div>
              ) : null}
              {refname ? (
                <div className="top_heading_in_voters_data">
                  <span>Reference Name</span>
                </div>
              ) : null}
              {refvid ? (
                <div className="top_heading_in_voters_data">
                  <span>Reference Voter ID</span>
                </div>
              ) : null}
              {refph ? (
                <div className="top_heading_in_voters_data">
                  <span>Reference Phone No</span>
                </div>
              ) : null}
              {voterCount ? (
                <div className="top_heading_in_voters_data">
                  <span>Count</span>
                </div>
              ) : null}
              {Notes ? (
                <div className="top_heading_in_voters_data">
                  <span>Notes</span>
                </div>
              ) : null}
              {occupation ? (
                <div className="top_heading_in_voters_data">
                  <span>Occupation</span>
                </div>
              ) : null}
              {education ? (
                <div className="top_heading_in_voters_data">
                  <span>Education</span>
                </div>
              ) : null}
            </div>
            <div className="voters_data_holder_main_div">
              {data.map((item) => (
                <div key={item.id} className="d-flex">
                  <div
                    className="titleDiv_Edit_votersdata d-flex"
                    id="extra_inputs_for_tab"
                  >
                    <span
                      onClick={() => {
                        handleClickedpopup();
                        setEditValue(item.voter_id);
                        setVotermoduleType("Edit");
                        setNavigate("votersdata");
                      }}
                    >
                      Edit |
                    </span>
                    <img
                      onClick={() => handleTitleClick(item.voter_id)}
                      key={item.id}
                      className="polygon__votersdata"
                      src={polygon}
                      alt="not_visible"
                      style={{ cursor: "pointer" }}
                    />
                    {item.showDeleteButton && (
                      <div className="voters_data_view_delete_maindiv d-flex align-items-center modal">
                        <span
                          // onClick={() => setselectedview(item.voter_id)}
                          onClick={() => {
                            handleClickedpopup();
                            setEditValue(item.voter_id);
                            setVotermoduleType("view");
                          }}
                        >
                          View
                        </span>
                        <hr className="hrline_view_delete" />
                        {/* <span
                            // onClick={() => setselectedview(item.voter_id)}
                            onClick={() => {
                              handlePrint(printVoterData);
                              setEditValue(item.voter_id);
                              setVotermoduleType("view");
                            }}
                          >
                            Print
                          </span> */}
                        <div
                          className="delete_btn_voters_data d-flex align-items-center"
                          onClick={() => {
                            setSelectedvoter(item.voter_id);
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                  {voterid ? (
                    <div
                      className="titleDiv_votersdata"
                      id="title_div_first_column"
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleClickedpopup();
                          setEditValue(item.voter_id);
                          setVotermoduleType("view");
                        }}
                      >
                        {item.voter_id}
                      </span>
                    </div>
                  ) : null}
                  {firstname ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.first_name}</span>
                    </div>
                  ) : null}
                  {middlename ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.middle_name}</span>
                    </div>
                  ) : null}

                  {lastname ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.last_name}</span>
                    </div>
                  ) : null}
                  {dob ? (
                    <div
                      className="titleDiv_votersdata"
                      id="extra_inputs_for_tab"
                    >
                      <span>{item.dob}</span>
                    </div>
                  ) : null}
                  {age ? (
                    <div
                      className="titleDiv_votersdata"
                      id="extra_inputs_for_tab"
                    >
                      <span>{item.age}</span>
                    </div>
                  ) : null}

                  {gender ? (
                    <div
                      className="titleDiv_votersdata"
                      id="extra_inputs_for_tab"
                    >
                      <span>{item.gender}</span>
                    </div>
                  ) : null}
                  {religion ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.religion__religion_name}</span>
                    </div>
                  ) : null}
                  {caste ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.sub_caste__caste__caste_name}</span>
                    </div>
                  ) : null}
                  {subcaste ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.sub_caste__sub_caste_name}</span>
                    </div>
                  ) : null}

                  {mobile ? (
                    <div
                      className="titleDiv_votersdata"
                      id="extra_inputs_for_tab"
                    >
                      <span>{item.phone_no}</span>
                    </div>
                  ) : null}
                  {email ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.email_id}</span>
                    </div>
                  ) : null}
                  {company ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.company_name}</span>
                    </div>
                  ) : null}
                  {title ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.title}</span>
                    </div>
                  ) : null}
                  {designation ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.designation}</span>
                    </div>
                  ) : null}
                  {mandal ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.mandal}</span>
                    </div>
                  ) : null}
                  {village ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.ward_village__ward_village_name}</span>
                    </div>
                  ) : null}
                  {votertype ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.voter_type__voter_type_name}</span>
                    </div>
                  ) : null}
                  {votefavour ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.voter_favour_to__party_name}</span>
                    </div>
                  ) : null}
                  {relationtype ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.relation_type}</span>
                    </div>
                  ) : null}
                  {relationname ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.relation_first_name}</span>
                    </div>
                  ) : null}
                  {houseno ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.house_no_name}</span>
                    </div>
                  ) : null}
                  {street ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.street}</span>
                    </div>
                  ) : null}
                  {revenueDivision ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.revenue_division}</span>
                    </div>
                  ) : null}
                  {sectionName ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.section_name}</span>
                    </div>
                  ) : null}
                  {pollingbootheId ? (
                    <div className="titleDiv_votersdata">
                      <span>
                        {item.polling_booth__polling_booth_no}-
                        {item.polling_booth__polling_booth_name}
                      </span>
                    </div>
                  ) : null}
                  {pollingboothadress ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.polling_booth__polling_booth_address}</span>
                    </div>
                  ) : null}
                  {city ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.city}</span>
                    </div>
                  ) : null}
                  {postalcode ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.postalcode}</span>
                    </div>
                  ) : null}

                  {state ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.state__state_name}</span>
                    </div>
                  ) : null}

                  {xx ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.xx}</span>
                    </div>
                  ) : null}
                  {currentLoc ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.curr_loc}</span>
                    </div>
                  ) : null}
                  {houseHead ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.house_head}</span>
                    </div>
                  ) : null}
                  {ipvid ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.ip_vid}</span>
                    </div>
                  ) : null}
                  {ipname ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.ip_name}</span>
                    </div>
                  ) : null}
                  {ipph ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.ip_ph}</span>
                    </div>
                  ) : null}

                  {refname ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.ref_name}</span>
                    </div>
                  ) : null}
                  {refvid ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.ref_vid}</span>
                    </div>
                  ) : null}
                  {refph ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.ref_ph}</span>
                    </div>
                  ) : null}
                  {voterCount ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.voters_count}</span>
                    </div>
                  ) : null}
                  {Notes ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.notes}</span>
                    </div>
                  ) : null}
                  {occupation ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.occupation}</span>
                    </div>
                  ) : null}
                  {education ? (
                    <div className="titleDiv_votersdata">
                      <span>{item.education}</span>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="votersdata_underlines_holder">
          <div className="voters_data_underline_one"></div>
          <div className="voters_data_underline_one"></div>
        </div>
        {/* <div className="d-flex align-items-center buttons_voterdata_next_prev_main_div  ">
          <div id="votersdata" className="total_pages">
            Pages : {pagesCount}
          </div>
          <div className="go_topage_main_div d-flex">
            <input
              className="go_topage_inputfield"
              type="numbers"
              value={gotopage}
              placeholder="Enter Page Number"
              onChange={gotoinputChange}
            />
            <button onClick={handleGotopage}>Go</button>
          </div>
          <button
            className="prev_button_votersdata"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <div className="current_page_number_votersdata">{currentPage}</div>
          <button
            className="next_button_votersdata"
            onClick={handleNextPage}
            disabled={data.length < objectsPerPage}
          >
            Next
          </button>
        </div> */}
      </div>

      {addvotersclicked ? (
        <AddVoterspopup
          cancleaddvoters={cancleaddvoters}
          constituencyvalue={selectedConstituencies}
          oncancleclick={cancleaddvoters}
          addVoterclosed={handleAddvoterscloseClicked}
          Editvalue={Editvalue}
          votermoduleType={votermoduleType}
          navigateto={navigate}
        />
      ) : null}

      {selectedvoter ? (
        <div>
          <div className="delete_popup_votersdata d-flex align-items-center justify-content-center"></div>
          <div className="delete_popup_voters_subDiv">
            <div className="delete_popup_voters_innerDiv">
              <div className="delete_popup_voters_data_delete_text_div">
                <span>Do you want to delete this User details?</span>
                <button
                  className="delete_voters_dismiss"
                  onClick={handlecancleDeletevoter}
                >
                  Dismiss
                </button>
                <button
                  className="delete_voters_continue"
                  onClick={handledeleteVoter}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isLoading ? <LoaderWrapper /> : null}
    </div>
  );
}

export default Votersdata;
