import axiosInstance from './AxiosInstance';

const url  = process.env.REACT_APP_SERVER_CONFIG;
export const eventsData = async () => {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const dashboard = await axiosInstance.get('/event/')
    
    // const dashboard = await fetch(`${url}/event/`, {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${tokenData.token}`,
    //   },
    // });
    if (dashboard.status !== 404) {
      return dashboard.data;
    }
  };
  
  
  export const postEvents = async (
    title,
    meetDate,
    meetFrom,
    meetTo,
    meetDesc
  ) => {
    // const tokenData = JSON.parse(localStorage.getItem("data"));
    const postData = {
      event_title: title,
      event_start: `${meetDate}T${meetFrom}`,
      event_end: `${meetDate}T${meetTo}`,
      event_desc: meetDesc,
      created_id: "1",
      updated_id: "1",
    }
    console.log('post',postData);
    const response = await axiosInstance.post('/event/',postData);
    return response.data;
    // const response = await fetch(`${url}/event/`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${tokenData.token}`,
    //   },
    //   body: JSON.stringify(),
    // });
    // const postdata = response.json();
    // return postdata;
  };
  
  export const deleteEvent = async (eventID) => {
    // const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.delete(`/event/${eventID}/`)
    const postdata = response;
    return postdata;
    // const response = await fetch(`${url}/event/${eventID}/`, {
    //   method: "DELETE",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${tokenData.token}`,
    //   },
    //   body: JSON.stringify({}),
    // });
    // console.log(response,"response from delete");
    // const postdata = response;
    // return postdata;
  };
  

  // export const deleteEvent = async (eventID) => {
  //   try {
  //     console.log("Deleting event with ID:", eventID);
  
  //     const response = await axiosInstance.delete(`/event/${eventID}/`);
  //     console.log("Delete response:", response);
  
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error deleting event:", error);
  //     throw error; // Rethrow the error to handle it in the calling code
  //   }
  // };
  
  
  
  
  
  
  
  export const EditEvents = async (
    id,
    title,
    meetDate,
    meetFrom,
    meetTo,
    meetDesc
  ) => {
    const tokenData = JSON.parse(localStorage.getItem("data"));

    const updateData = JSON.stringify({
      event_title: title,
      event_start: `${meetDate}T${meetFrom}`,
      event_end: `${meetDate}T${meetTo}`,
      event_desc: meetDesc,
      created_id: "1",
      updated_id: "1",
    })
    const response = await axiosInstance.patch(`/event/${id}/`,updateData);
    return response.data;

    // const response = await fetch(`${url}/event/${id}/`, {
    //   method: "PATCH",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${tokenData.token}`,
    //   },
    //   body: JSON.stringify(),
    // });
    // const postdata = response.json();
    // return postdata;
  };