import React from 'react';
import loadingring from "../Assets/loadingring.png";
import loadinglogo from "../Assets/logoitoconnect.png";
import '../components/LoaderWrapper.css';

const LoaderWrapper = () => {
  return (
    <div>
      <div className="loadingscreenI2connect_votersdata d-flex justify-content-center align-items-center">
        <div className="parent_Loading_holder d-flex justify-content-center align-items-center">
          <img className="Loading_ring" src={loadingring} />
          <img className="loading_logo" src={loadinglogo} />
        </div>
      </div>
    </div>
  )
 
};

export default LoaderWrapper;
