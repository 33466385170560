import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';

const Map = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserLocations, setSelectedUserLocations] = useState([]);

  useEffect(() => {
    // Fetch user data from your API endpoint
    const apiUrl = 'https://aum2086ycf.execute-api.us-east-1.amazonaws.com/dev/getlocationdetails';

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error('Error fetching user data:', error));
  }, []);

  const handleUserClick = (user) => {
    setSelectedUser(user);

    // Filter the locations for the selected user
    const userLocations = users.filter((location) => location.user_id === user.user_id);
    setSelectedUserLocations(userLocations);
  };

  const calculateTimeDifference = (startTime, endTime) => {
    const startTimestamp = new Date(startTime).getTime();
    const endTimestamp = new Date(endTime).getTime();

    const timeDiff = Math.abs(endTimestamp - startTimestamp);
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours} hours and ${minutes} minutes`;
  };

  const calculateCenter = (locations) => {
    const sumLat = locations.reduce((sum, location) => sum + parseFloat(location.latitude), 0);
    const sumLng = locations.reduce((sum, location) => sum + parseFloat(location.longitude), 0);

    const avgLat = sumLat / locations.length;
    const avgLng = sumLng / locations.length;

    return [avgLat, avgLng];
  };

  const center = selectedUser ? calculateCenter(selectedUserLocations) : [17.585044, 78.486671];

  return (
    <div>
      <MapContainer center={center} zoom={8} style={{ height: '1000px', width: '100%' }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {users.map((user) => (
          <Marker
            key={user.id}
            position={[parseFloat(user.latitude), parseFloat(user.longitude)]}
            icon={new L.Icon({
              iconUrl: 'https://cdn.pixabay.com/animation/2022/08/15/08/47/08-47-46-763_512.gif',
              iconSize: [60, 60],
              iconAnchor: [36, 62],
              popupAnchor: [0, -32],
            })}
            eventHandlers={{
              click: () => handleUserClick(user),
            }}
          >
            <Popup>
              {user.name}
              <br />
              Last Tracked Time: {user.timestamp}
            </Popup>
          </Marker>
        ))}

        {selectedUserLocations.length > 0 && (
          <Polyline
            positions={selectedUserLocations.map((location) => [
              parseFloat(location.latitude),
              parseFloat(location.longitude),
            ])}
            color="blue"
            weight={5}
            opacity={0.7}
          />
        )}

        {selectedUser && (
          <Popup
            position={[
              parseFloat(selectedUser.latitude),
              parseFloat(selectedUser.longitude),
            ]}
          >
             {selectedUser.user_name}
              <br />
              Last Tracked Time: {selectedUser.timestamp}
              <br />
            Travel Time: {calculateTimeDifference(
              selectedUserLocations[selectedUserLocations.length - 1]?.timestamp,
              selectedUserLocations[0]?.timestamp
            )}
          </Popup>
        )}
      </MapContainer>
{/* 
      <div style={{ width: '30%', float: 'right' }}>
        <h2>User Information</h2>
        {selectedUser && (
          <div>
            <p>User ID: {selectedUser.user_id}</p>
            <p>Name: {selectedUser.name}</p>
            <p>Last Tracked Time: {selectedUser.timestamp}</p>
            <p>Travel Time: {calculateTimeDifference(
              selectedUserLocations[selectedUserLocations.length - 1]?.timestamp,
              selectedUserLocations[0]?.timestamp
            )}</p>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default Map;
