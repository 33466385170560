import React from "react";
import "../components/Forgotpassword.css";
// import Emslogo from "../Assets/emslogo.png";
import Emslogo from "../../src/Assets/itoclogo.png";
import { useState, useEffect } from "react";
import { forgotPassword } from "../Service/service";
import LoaderWrapper from '../components/LoaderWrapper'
import { encode } from 'base-64';

function Forgotpassword() {
  const [resetPassword, setresetPassword] = useState("");
  const [status, setStatus] = useState("");
  const [statusColorClass, setStatusColorClass] = useState("");
  const [rstPwd, setRstpwd] = useState();
  const [email, setEmail] = useState();
  const [loadingscreen, setLoadingscreen] = useState(false);
  



  const updatePassword = () => {
    // var rstPwd = document.getElementById("resetPassword").value;
    setLoadingscreen(true);
    forgotPassword(rstPwd)
      .then((result) => {
        setStatus(result);
        if (result.status == false) {
          setStatusColorClass(result.message);
          setLoadingscreen(false);
        } else {
          setStatusColorClass(result);
          setLoadingscreen(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setStatus(error);
      })
    setresetPassword(rstPwd);
  };

  const redirectLogin = () => {
    window.location.href = "/";
  };

  return (
    <div style={{
      // background:'linear-gradient(123deg, #ffffff 50%, rgb(255, 103, 31) 150%)',marginTop:'2rem'}}
      background: 'linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%)'}}
      >
      {loadingscreen ? <LoaderWrapper /> : null}

      <div className="fp_emslogo">
        <img src={Emslogo} alt="Waiting..." />
      </div>
      <div className="fp_emslogo">
      <span className = "fp_emslogo_title_text" style={{color:'green',fontSize:'30px',fontWeight:'800',textAlign:'center'}}>ItoConnect</span>
      </div>
      <div className="fpword__main">
        <p className="fpword__text">
          Enter your email address or phone number and we'll send you a <br />
          link to reset your password.
        </p>
        <div className="fpword__sub">
          <div className="fpword__heading">
            <span className="fpword__hrline" />
            <h3>Forgot Password</h3>
            <span className="fpword__hrline" />
          </div>
          <input
            placeholder="Enter your email address/phone number"
            id="resetPassword"
            value={rstPwd}
            onChange={(e) => {
              const enteredValue = e.target.value.toLowerCase();
              setRstpwd(enteredValue);
              setEmail(enteredValue);
            }}
          />

          <span className={`fpword__sub_statusMsg ${statusColorClass}`}>
            {statusColorClass}
          </span>
          <button className="resetpword__button" onClick={updatePassword}>
            {/* Reset Password */}
            Get OTP
          </button>
        </div>
        <p style={{color:'black',marginTop:'1rem'}} className="fpword_bottom_text" onClick={redirectLogin}>
          Log in to Application
        </p>
      </div>
      {/* <div className="fp_emptyspace"></div>
      <div className="copyright">© 2023 EMS ----- All Right Reserved</div> */}
    </div>
  );
}

export default Forgotpassword;
