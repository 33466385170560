import React, { useState } from "react";
import "../components/ChangePasswordpopup.css";
import { passwrodChange } from "../Service/service";


function ChangePasswordpopup(prop) {

  const [oldpassword, setOldpassword] = useState(null);
  const [newpassword, setNewpassword] = useState(null);
  const [Confirmpassword, setConfirmpassword] = useState(null)


  const cancleChangepassword = () => {
    prop.cancledPasswordChange()
  }

  const handleoldPassword = (e) => {
    setOldpassword(e.target.value)
  }

  const handleNewpassword = (e) => {
    setNewpassword(e.target.value);
  }

  const handleconfirmpasswrod = (e) => {
    setConfirmpassword(e.target.value);
  }
  const saveChangePassword = () => {
    if (!newpassword || !Confirmpassword || /^\s*$/.test(newpassword) || /^\s*$/.test(Confirmpassword)) {
      alert("New password and confirm password fields should not be empty or contain only spaces");
      return;
    }
    if (newpassword === Confirmpassword) {
      passwrodChange(oldpassword, newpassword).then((resp) => {
        if (resp.status === true) {
          alert(resp.message);
          prop.cancledPasswordChange();
        } else {
          alert(resp.message);
        }
      });
    } else {
      alert("Confirm password is not correct");
    }
  };


  return (
    <div className="changepassowrd_main_div d-flex align-items-center justify-content-center">
      <div className="changepassword_sub_div d-flex flex-column">
        <div className="changepassword_heading_div d-flex align-items-center justify-content-center">
          <span className="changepassword_text">Change Password</span>
          <div className="cross_close_bt_main_div" onClick={cancleChangepassword}>
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 22 22" fill="none">
              <path d="M5.33819 3.32935L10.9995 8.99068L16.6315 3.35868C16.7559 3.22627 16.9058 3.12034 17.0721 3.04725C17.2385 2.97417 17.4178 2.93542 17.5995 2.93335C17.9885 2.93335 18.3616 3.08787 18.6366 3.36293C18.9117 3.63798 19.0662 4.01103 19.0662 4.40002C19.0696 4.57983 19.0362 4.75845 18.9682 4.92491C18.9001 5.09137 18.7987 5.24215 18.6702 5.36802L12.9649 11L18.6702 16.7054C18.9119 16.9418 19.0537 17.2621 19.0662 17.6C19.0662 17.989 18.9117 18.3621 18.6366 18.6371C18.3616 18.9122 17.9885 19.0667 17.5995 19.0667C17.4126 19.0744 17.2261 19.0432 17.0519 18.9751C16.8777 18.9069 16.7195 18.8032 16.5875 18.6707L10.9995 13.0093L5.35286 18.656C5.22893 18.784 5.08088 18.8862 4.91726 18.9567C4.75363 19.0272 4.57767 19.0646 4.39952 19.0667C4.01054 19.0667 3.63749 18.9122 3.36243 18.6371C3.08738 18.3621 2.93286 17.989 2.93286 17.6C2.92944 17.4202 2.9628 17.2416 3.0309 17.0751C3.09899 16.9087 3.20039 16.7579 3.32886 16.632L9.03419 11L3.32886 5.29468C3.08713 5.0582 2.94538 4.73795 2.93286 4.40002C2.93286 4.01103 3.08738 3.63798 3.36243 3.36293C3.63749 3.08787 4.01054 2.93335 4.39952 2.93335C4.75152 2.93775 5.08886 3.08002 5.33819 3.32935Z" fill="#0F2540" />
            </svg>
          </div>
        </div>
        <label className="labels_in_change_password">Old Password </label>
        <div className="inputs_change_password d-flex align-items-center justify-content-center">
          <input type={"text"} placeholder="Enter Old Password" onChange={handleoldPassword} />
        </div>
        <label className="labels_in_change_password">New Password </label>
        <div className="inputs_change_password d-flex align-items-center justify-content-center">
          <input type={"text"} placeholder="Enter New Password" onChange={handleNewpassword} />
        </div>
        <label className="labels_in_change_password">Confirm Password </label>
        <div className="inputs_change_password d-flex align-items-center justify-content-center">
          <input type={"text"} placeholder="Enter Confirm Password" onChange={handleconfirmpasswrod} />
        </div>
        <div className="changepassword_main_div d-flex">
          <button className="cancel_button_changepassword" onClick={cancleChangepassword}>Cancel</button>
          <button className='changepassword_save_button' onClick={saveChangePassword}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordpopup;
