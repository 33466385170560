import React, { useMemo } from "react";
import {
  Chart,
  SeriesTemplate,
  CommonSeriesSettings,
  Label,
  ValueAxis,
  Grid,
  Tooltip,
} from "devextreme-react/chart";
import maxmize from "../../Assets/maxmize.png";

import "./Bargraph.css";
import { SpeedDialAction } from "devextreme-react";

const Bargraph = (props) => {
  const load = props.loadingvoter
  const formatNumber = (value) => {
    if (typeof value === "string") {
      value = value.replace(/,/g, "");
    }

    if (value >= 10000000) {
      return `${(value / 10000000).toFixed(1)}Cr`;
    } else if (value >= 100000) {
      return `${(value / 100000).toFixed(1)}L`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    } else {
      return `${value}`;
    }
  };

  const dataSourceMemoizedData = useMemo(() => {
    // Perform some expensive computation on the data
    
    return [
      {
        gender: "Total",
        number: props.voters?.total_voters,
        color: "#00C797",
        
        
      },
      {
        gender: "Male",
        number: props.voters?.male_voters,
        color: "#FD7676",
        // color:"#00C797"
      },
      {
        gender: "Female",
        number: props.voters?.female_voters,
        color: "#F46DC6",
      },
      {
        gender: "Others",
        number: props.voters?.other_voters,
        color: "#A79797",
      },
    ];
  }, [props]);
  const labelFont = {
    color: "#263659",
  };

  const handleButtonmaxmize = () => {
    props.onClick();
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    barWidth: 20, // Set the width of each bar
    barPercentage: 1, // Set the percentage of the bar width to use
    categorySpacing: 1, // Set the spacing between each category of bars
    datasetSpacing: 0, // Set the spacing between each dataset of bars
  };

  return (
    <div className="bargraphone_main_div">
      <button className="maxmizebtn_charts" onClick={handleButtonmaxmize}>
      <img
        src={maxmize}
        alt="not_visible"
        
      />
      </button>

      {/* <span className="charts_viewport_text">View report</span> */}
      <div className="voters_favourtext_main_div d-flex flex-column justify-content-center">
        <span>Voters</span>
        <div className="ems_admin_dashboara_voters_chart_text_underline underline_1"></div>
        {/* <div className="underline_1"></div> */}

      </div>
      <Chart
        className="dx-chart"
        id="chart"
        palette={["#00C797", "#FD7676", "#F46DC6", "#A79797"]}
        dataSource={load?null:dataSourceMemoizedData}      >
        <ValueAxis maxValueMargin={0.25} >
        <Grid  visible={true} width={1} color={'#F0F0F0'} />
        </ValueAxis>
        <CommonSeriesSettings
          argumentField="gender"
          valueField="number"
          type="bar"
          ignoreEmptyPoints={true}
          minBarSize={8}
          barWidth={30}
          maxBarSize={50}
        >
          <Label
            visible={true}
            backgroundColor={"none"}
            font={labelFont}
            position={"outside"}
            minBarSize={10}
            format={formatNumber}
          ></Label>
        </CommonSeriesSettings>
        <SeriesTemplate nameField="gender" />
        <Tooltip
          enabled={true}
          customizeTooltip={(arg) => {
            const color = arg.point.getColor();
            return {
              text: `<span className="hover_text_graphs" style="color: ${color}"> <p class='text_p_tag'>${arg.argumentText}: ${arg.valueText} </p></span>`,
            };
          }}
        />
      </Chart>
     
       
      
      
      <div className="d-flex flex-column castedetails_main_div ">
        {dataSourceMemoizedData.map((value) => {
          return (
            <div className="d-flex align-items-center castecolors_caste_div_main">
              <div 
                className="castecolo_div"
                style={{ background: `${value.color}` }}
              ></div>
              <span className="castecolors_caste_div">{value.gender}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Bargraph;
