import React, { useContext, useEffect, useState } from "react";
import Delete from "../../Assets/Delete.png";
import {
 
  tododeleteDashboard,
  todogetDashboard,
  todopostDashboard,
} from "../../Service/service";
import { RoleDataContext } from "../../AppContextComponent";

const ToDo = () => {
  const [todogetdata, setTodogetdata] = useState([]);
  const { roleDataService } = useContext(RoleDataContext);

  const handleChangeDELETETODO = (deletetodo) => {
    tododeleteDashboard(deletetodo).then((deltodo) => {
      todogetDashboard().then((resp) => setTodogetdata(resp));
    });
  };


  const addedtodoslist = (event) => {
    event.preventDefault();
    const toDoInput = document.getElementsByClassName("addyour_new_todo_input")[0];
    const errMsg = document.getElementsByClassName("inValidtodo")[0];
    const val = toDoInput.value.trim(); // Trim the input value
  
    // Submit the form if the trimmed input value is not empty
    if (val !== '' && val.length <= 100) {
      // Make API call here
      todopostDashboard(val).then((posteddata) => {
        tododataget();
        toDoInput.value = ""; // Clear the input field
      });
      errMsg.style.display = "none";
    } else {
      errMsg.style.display = "block";
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addedtodoslist(event);
    }
  };

  
  const tododataget = () => {
    todogetDashboard().then((todoget) => setTodogetdata(todoget));
  };
  useEffect(() => {
    tododataget();
  }, []);

  return (
    <>
      <div className="admindashboard_important_text_div d-flex flex-column">
        <div className="d-flex flex-column todolist_div">
          <span className="important_people_text ">To Do List</span>
          {/* <div className="importantpeople_todo_underline"></div> */}
        </div>
      </div>
      <div className="d-flex align-items-center todo_add_main_div">
        <div className="addyour_new_todo d-flex justify-content-center align-items-center">
          <input
            className="addyour_new_todo_input"
            type={"text"}
            placeholder="Add your new to do"
            onKeyPress={handleKeyPress}
          />
        </div>
        {roleDataService.access.create ? <button
          className="add_todos_btn d-flex align-items-center justify-content-center"
          onClick={addedtodoslist}
        >
          <span className="add_todos_plus">+</span>
        </button> : '' }
      </div>
      <span className="inValidtodo"> limit: 2-100 chars </span>
      <div>
        <div className="todo_sroll_div">
        {
        todogetdata.length>0?(todogetdata.map((todoitems) => {
          return (
            <div
              key={todoitems.todo_id}
              className="call_to_contractor d-flex align-items-center"
            >
              <span>{todoitems.todo_title}</span>
              {roleDataService.access.delete ? <div className="delete_option_todolist d-flex justify-content-center align-items-center">
                <img
                  className="delete_option_totolist_img"
                  src={Delete}
                  alt="not visible"
                  onClick={() => handleChangeDELETETODO(todoitems.todo_id)}
                />
              </div> : '' }
            </div>
          );
        })):<p className="nodata_filters_data">No Data Found</p>
        }
        
        </div>
      </div>
    </>
  );
};

export default ToDo;
