import { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  calenderFromToHrs,
  createEventId,
  defTimeFrom,
  defTimeTo,
} from "../Utils/utils";
import { Button, Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import CloseIcon from "../Assets/CloseIcon.png";
import CalendarEditIcon from "../Assets/CalenderEdit.png";
import CalendarDeleteIcon from "../Assets/CalenderDelete.png";
import CalendarClock from "../Assets/Calender-Clock.png";
import CalendarDesc from "../Assets/Calender-desc.png";
import CalendarLocation from "../Assets/Calender-location.png";

import CreateIcon from "../Assets/Create-icon.png";
import {
  deleteEvent,
  EditEvents,
  eventsData,
  postEvents,
} from "../Service/CalenderService";
import "../Pages/AddCalender.scss";
import moment from "moment-timezone";
import ShowMoreText from "react-show-more-text";
import LoaderWrapper from "../components/LoaderWrapper";

const AddCalender = () => {
  const [closenav, setClosenav] = useState(false);
  const [respclosenav, setRespClosenav] = useState(false);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [showEventPopup, setShowEventPopup] = useState(false);
  const [meetID, setMeetID] = useState("");
  const [title, setTitle] = useState("");
  const [meetDate, setDate] = useState(moment().format("DD-MM-YYYY"));
  const [meetFrom, setMeetfrom] = useState(
    moment(defTimeFrom, "hh:mm A").format("HH:mm")
  );
  const [meetTo, setMeetto] = useState(
    moment(defTimeTo, "hh:mm A").format("HH:mm")
  );
  const [meetDesc, setMeetDesc] = useState("");
  const [isApiCallActive, setIsApiCallActive] = useState(false);
  const handleMeetDescChange = (value) => {
    setMeetDesc(value);
  };
  const [meetSelectInfo, setmeetSelectInfo] = useState({});
  const [events, setEvents] = useState([]);
  const [oneventClkModal, setOneventClkModal] = useState(false);
  const [oneventClkData, setOneventClkData] = useState({});
  const [editBtn, setEditBtn] = useState(false);
  const [createBtn, setCreateBtnn] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const getDefaultEvents = () => {
    eventsData().then((resp) => {
      const newResponse = resp.map((obj) => {
        return {
          id: obj.event_id,
          title: obj.event_title,
          start: obj.event_start.slice(0, -1),
          end: obj.event_end.slice(0, -1),
          description: obj.event_desc,
        };
      });
      setEvents(newResponse);
    });
  };
  useEffect(() => {
    getDefaultEvents();
  }, []);
  const mainContentStyle = {
    width: !closenav ? "100%" : "calc(100vw - 14vw)",
  };
  const handleDateSelect = (selectInfo) => {
    let meetStart = moment(selectInfo.startStr)
      .tz("Asia/Kolkata")
      .format("HH:mm");
    const asiaKolkataTime = moment.tz(selectInfo.endStr, "Asia/Kolkata");
    const meetEnd = asiaKolkataTime.add(30, "minutes").format("HH:mm");
    setMeetfrom(meetStart);
    setMeetto(meetEnd);
    setDate(moment(selectInfo.startStr).format("DD-MM-YYYY"));
    setShowEventPopup(true);
    setmeetSelectInfo(selectInfo);
  };

  const handleEventClick = (clickInfo) => {
    setOneventClkData({
      event_id: clickInfo.event.id,
      title: clickInfo.el.fcSeg.eventRange.def.title,
      date: clickInfo.el.fcSeg.start,
      clkMeetFrom: clickInfo.event.startStr,
      clkMeetTo: clickInfo.event.endStr,
      description: clickInfo.el.fcSeg.eventRange.def.extendedProps.description,
    });
    setOneventClkModal(true);
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div>
        <div className={"event-content"}>
          <span>{eventInfo.event.title}</span> &nbsp; &nbsp;
          <span>
            {moment(eventInfo.event.startStr).format("h:mm A")} -
            {moment(eventInfo.event.end).format("h:mm A")}
          </span>
        </div>
      </div>
    );
  };

  const handleEventSubmit = (selectInfo) => {
    // Handle submission logic here, e.g. using an API call

    setShowEventPopup(false);
  };

  const toggleClicked = () => {
    setClosenav(true);
    setRespClosenav(true);
    const calendarPageSection = document.getElementsByClassName(
      "fc-view-harness-active"
    )[0];
    // calendarPageSection.style.width=`100vw`;
    calendarPageSection.style.width = `calc(100vw - 14vw)`;
  };

  const toggleopend = () => {
    setClosenav(false);
    setRespClosenav(false);
    const calendarPageSection = document.getElementsByClassName(
      "fc-view-harness-active"
    )[0];
    // calendarPageSection.style.width=`calc(100vw - 14vw)`;
    calendarPageSection.style.width = `100vw`;
    // fc-view-harness fc-view-harness-active
  };

  const handleCloseModal = () => {
    setShowEventPopup(false);
    setTitle("");
    setMeetDesc("");
    setDate("");
    setMeetto(defTimeTo);
    setMeetfrom(defTimeFrom);
  };

  const handleDateFrom = (item) => {
    setMeetfrom(item);
    document.body.click();
  };

  const handleSaveClk = () => {
    setIsApiCallActive(true);
    let calendarApi = meetSelectInfo?.view?.calendar || undefined;
    console.log(calendarApi, "calendarApi");
    let meetFromDateSplit = meetFrom.split(" ")[0];
    let meetToDateSplit = meetTo.split(" ")[0];
    let meetDateFormated = moment(meetDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    if (createBtn === true) {
      postEvents(
        title,
        meetDateFormated,
        meetFromDateSplit,
        meetToDateSplit,
        meetDesc
      ).then((posteddata) => {
        if (posteddata?.event_id) {
          getDefaultEvents();
        }
      });
      setShowEventPopup(false);
    } else if (editBtn === true) {
      EditEvents(
        meetID,
        title,
        meetDateFormated,
        meetFromDateSplit,
        meetToDateSplit,
        meetDesc
      ).then((posteddata) => {
        getDefaultEvents();
      });
      setEditBtn(false);
    } else {
      if (calendarApi) {
        calendarApi.addEvent({
          id: createEventId(),
          title: title,
          start: `${meetDateFormated}T${meetFromDateSplit}+05:30`,
          end: `${meetDateFormated}T${meetToDateSplit}+05:30`,
          description: meetDesc,
        });
      }
      postEvents(
        title,
        meetDateFormated,
        meetFromDateSplit,
        meetToDateSplit,
        meetDesc
      ).then((posteddata) => {
        if (posteddata?.event_id) {
          getDefaultEvents();
        }
      });
    }
    setTitle("");
    setMeetDesc("");
    setShowEventPopup(false);
    setCreateBtnn(false);
  };

  useEffect(() => {
    if (!showEventPopup) {
      setIsApiCallActive(false);
    }
  }, [showEventPopup]);

  const deleteEventClk = (eventID) => {
    deleteEvent(eventID).then((posteddata) => {
      setOneventClkModal(false);
      console.log("statuS", posteddata);
      if (posteddata.status === 204) {
        getDefaultEvents();
      }
    });
  };

  const editEventClk = (event) => {
    setOneventClkModal(false);
    setShowEventPopup(true);
    setMeetID(event.event_id);
    setTitle(event.title);
    setDate(moment(event.date).tz("Asia/Kolkata").format("DD-MM-YYYY"));
    setMeetfrom(moment(event.clkMeetFrom).format("HH:mm"));
    setMeetto(moment(event.clkMeetTo).format("HH:mm"));
    setMeetDesc(event.description);
    setEditBtn(true);
  };
  const getDateWithoutTime = (dt) => {
    dt.setHours(0, 0, 0, 0);
    return dt;
  };

  return (
    <>
      <div className="d-flex calender_container">
        <div className="top_nav_holder_main" id="top_nav_holder_main">
          <div
            className="d-flex flex-column calendar_main_div"
            style={mainContentStyle}
          >
            <div style={{ position: "relative" }}>
              <div>
                <div className="calendar_heading">
                  <Form.Label className="calnr-head">Calendar</Form.Label>
                </div>
                <div
                  style={{ position: "absolute", top: "1.5em", right: "2.38%" }}
                  className="create_button"
                >
                  <Button
                    className="create-btn"
                    onClick={() => {
                      setShowEventPopup(true);
                      setCreateBtnn(true);
                    }}
                  >
                    {/* <img
                      src={CreateIcon}
                      alt="createBtn"
                      style={{ marginRight: "5px", marginBottom: "3px" }}
                    /> */}
                    {/* Create */}
                    Notes
                  </Button>
                </div>
                <hr class="hr_line_in_responsive" />
              </div>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: `timeGridDay,timeGridWeek,dayGridMonth`,
                  center: "prev,next",
                  right: "title",
                }}
                initialView="timeGridDay"
                handleWindowResize={true}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                select={handleDateSelect}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                eventsSet={handleEvents}
                events={events}
                // validRange: { start: today,
                //   end: ‘2025-06-01’
                // }
                selectAllow={(info) =>
                  info.start >= getDateWithoutTime(new Date())
                }
                allDaySlot={false}
                dayHeaderContent={({ date }) => (
                  // <span>{moment(date).format("dddd D")}</span>
                  // <span class="date-style">{moment(date).format("dddd ")}<span class="day-style">{moment(date).format("D")}</span></span>
                  <div class="date-container">
                    <span class="day-of-week">
                      {moment(date).format("ddd")}
                    </span>
                    <span class="day-of-month">{moment(date).format("D")}</span>
                  </div>
                )}
                id="calendar"
              />{" "}
              <Modal
                show={showEventPopup}
                onHide={handleCloseModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ padding: "10px 20px" }}
                className="modal-parent"
              >
                {/* <Modal.Header 
                className="modal-close-icon"
                >
                  <img
                    src={CloseIcon}
                    alt="Close-icon"
                    onClick={handleCloseModal}
                  ></img>
                </Modal.Header> */}
                <Modal.Body className="popup_text_container">
                  <form>
                    <input
                      type="text"
                      placeholder="Add Title"
                      value={title}
                      className="add-title"
                      onChange={(event) => setTitle(event.target.value)}
                      style={{
                        border: "none",
                        padding: "0px",
                        fontSize: "4vw",
                        textAlign:'center',
                        height: "8vh",
                        fontWeight: 400,
                        color: "#787B7F",
                      }}
                    />
                    <div className="modal-close-icon">
                    <img
                    src={CloseIcon}
                    alt="Close-icon"
                    onClick={handleCloseModal}
                  ></img>
                    </div>
                    <hr className="addtitle_hrline" />
                    <div className="date_time_select_container">
                      <img
                        className="calendarclock"
                        src={CalendarClock}
                        alt="CalendarClock"
                        style={{
                          height: "25px",
                          width: "25px",
                          // marginRight: "20px",
                        }}
                      ></img>
                      <DatePicker
                        dateFormat="DD/MM/YYYY"
                        value={meetDate}
                        className="date-picker"
                        placeholderText={"Select date"}
                        onChange={(date) =>
                          setDate(moment(date).format("DD-MM-YYYY"))
                        }
                        
                      />
                      <OverlayTrigger
                        trigger="click"
                        key="bottom"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover id={`popover-positioned-bottom`}>
                            <Popover.Body
                              className="scrollable-content"
                              style={{
                                display: "grid",
                                maxHeight: "150px",
                                overflowY: "scroll",
                              }}
                            >
                              {calenderFromToHrs.map((item) => {
                                return (
                                  <Form.Label
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDateFrom(item.value)}
                                  >
                                    {item.key}
                                  </Form.Label>
                                );
                              })}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button
                          variant="outline-secondary"
                          className="meetBtn"
                          style={{
                            // marginRight: "10px",
                            backgroundColor: "transparent",
                            color: "#263659",
                            fontSize: "3.88vw",
                            fontWeight: "600"
                          }}
                        >
                          {moment(meetFrom, "HH:mm").format("hh:mm a")}
                        </Button>
                        {/* <input type="time" onSubmit={handleSubmit} placeholder="From" value={startTime} onChange={(event) => setStartTime(event.target.value)} step="900" /> */}
                      </OverlayTrigger>
                      <OverlayTrigger
                        trigger="click"
                        key="bottom"
                        placement="bottom"
                        rootClose
                        overlay={
                          <Popover id={`popover-positioned-bottom`}>
                            <Popover.Body
                              className="scrollable-content"
                              style={{
                                display: "grid",
                                maxHeight: "150px",
                                overflowY: "scroll",
                              }}
                            >
                              {calenderFromToHrs.map((item) => {
                                return (
                                  <Form.Label
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setMeetto(item.value);
                                      document.body.click();
                                    }}
                                  >
                                    {item.key}
                                  </Form.Label>
                                );
                              })}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <Button
                          variant="outline-secondary"
                          className="meetBtn"
                          style={{
                            backgroundColor: "transparent",
                            // color: "#111",
                            color: "#263659",
                            fontSize: "3.88vw",
                            fontWeight: "600"
                          }}
                        >
                          {moment(meetTo, "HH:mm").format("hh:mm a")}
                        </Button>
                        {/* <input type="time" onSubmit={handleSubmit} placeholder="To" value={endTime} onChange={(event) => setEndTime(event.target.value)} step="900" /> */}
                      </OverlayTrigger>
                    </div>
                    <div>
                      {moment.duration(meetFrom, "HH:mm").asMinutes() >=
                      moment.duration(meetTo, "HH:mm").asMinutes() ? (
                        <p className={"error-message"}>
                          Please select valid time.
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div style={{ display: "flex" }}>
                       <div>
                       <img
                        src={CalendarLocation}
                        alt="CalendarDesc"
                        style={{ height: "20px", marginLeft: "5px",marginTop:'10px' }}
                      ></img>
                          <img
                        src={CalendarDesc}
                        alt="CalendarDesc"
                        style={{ height: "10px", marginLeft: "5px",marginTop:'30px' }}
                      ></img>
                       </div>
                    
                      {/* <Form.Control
                          as="textarea"
                          type="text"
                          className="adddescription_text"
                          placeholder="Add Description"
                          rows={4}
                          value={meetDesc}
                          onChange={(event) => setMeetDesc(event.target.value)}
                        /> */}

                      <ReactQuill
                        theme="snow"
                        className="adddescription_text"
                        placeholder="Add Description"
                        value={meetDesc}
                        onChange={handleMeetDescChange}
                      />
                    </div>
                    
                  </form>
                </Modal.Body>
                <Modal.Footer style={{ border: "none" }} className="calender_footer">
                  <Button
                    className="save_button"
                    variant="primary"
                    disabled={
                      isApiCallActive ||
                      moment.duration(meetFrom, "HH:mm").asMinutes() >
                        moment.duration(meetTo, "HH:mm").asMinutes()
                    }
                    onClick={handleSaveClk}
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* On click event modal */}
              <Modal
                show={oneventClkModal}
                onHide={() => setOneventClkModal(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                className="save-modal"
              >
                <Modal.Header className="modal-title-edit">
                  <Modal.Title id="example-custom-modal-styling-title">
                    <img
                      src={CalendarEditIcon}
                      alt="CalendarEditIcon"
                      style={{ marginRight: "15px", cursor: "pointer" }}
                      onClick={() => {
                        editEventClk(oneventClkData);
                      }}
                    ></img>
                    <img
                      src={CalendarDeleteIcon}
                      alt="CalendarDeleteIcon"
                      style={{ marginRight: "15px", cursor: "pointer" }}
                      onClick={() => {
                        deleteEventClk(oneventClkData.event_id);
                      }}
                    ></img>
                    <img
                      src={CloseIcon}
                      alt="CloseIcon"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOneventClkModal(false);
                      }}
                    ></img>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalBodyStyle">
                  <Form.Label>
                    <b>{oneventClkData.title}</b>
                  </Form.Label>
                  <br />
                  <Form.Label style={{ marginRight: "15px" }}>
                    {moment(oneventClkData.clkMeetFrom).format("dddd, MMMM D")}
                  </Form.Label>
                  <Form.Label>
                    {moment(oneventClkData.clkMeetFrom).format("hh:mm A")}
                  </Form.Label>{" "}
                  -{" "}
                  <Form.Label>
                    {moment(oneventClkData.clkMeetTo).format("hh:mm A")}
                  </Form.Label>
                  <div
                    style={{
                      border: "1px solid darkgray",
                      borderRadius: "4px",
                      background: "#F2F8FC",
                    }}
                  >
                    <ShowMoreText
                      className="parent_textseemore"
                      lines={3}
                      more={
                        <div
                          style={{ color: "#528BB8", textDecoration: "none" }}
                        >
                          See more
                        </div>
                      }
                      less={
                        <div
                          style={{ color: "#528BB8", textDecoration: "none" }}
                        >
                          See less
                        </div>
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: oneventClkData.description,
                        }}
                      />
                    </ShowMoreText>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCalender;
