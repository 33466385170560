import React, { useEffect, useRef, useState } from "react";
import "./AddVoterspopup.css";
import Addpicture from "../Assets/HumanLogo.png";
import Pencilicon from "../Assets/pencil_iconmobile.png";
import Delteicon from "../Assets/delete_mobile.png";
import Closecrossicon from "../Assets/closecrossicon.png";
// import arrow from "../Assets/dropdownarrow.png";
import Dropdown from "react-bootstrap/Dropdown";

import { FaAsterisk } from "@react-icons/all-files/fa/FaAsterisk";

import {
  addvoter,
  checkvoterId,
  constituencynewvoters,
  newvotersDropdown,
  newvotersMandals,
  newvotersPollingstations,
  newvotersVillages,
  subCasteDropdown,
  updatevoterData,
} from "../Service/Addvoters.service";

import {
  votersData,
  votersDataConstituency,
  votersDataMandal,
  votersDataVillage,
  votersDatapollingStation,
  csvconverter,
  pdfconverter,
  deleteVoter,
  searchvoterId,
  getvoterPrintList,
} from "../Service/voterdataservice";
const relationtypeData = [
  {
    id: 1,
    relation_type: "Father",
    relation: "F",
  },
  {
    id: 2,
    relation_type: "Mother",
    relation: "M",
  },
  {
    id: 3,
    relation_type: "Husband",
    relation: "H",
  },
  {
    id: 4,
    relation_type: "Others",
    relation: "O",
  },
];

const Gender = [
  {
    id: 1,
    gender: "Male",
    shortform: "M",
  },
  {
    id: 2,
    gender: "Female",
    shortform: "F",
  },
  {
    id: 3,
    gender: "Others",
    shortform: "O",
  },
];

function AddVoterspopup(props) {
  const [selectedvoter, setSelectedvoter] = useState();

  const [addnewvotersData, setAddnewvotersData] = useState({
    voter_id: "",
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    age: null,
    phone_no: null,
    email_id: "",
    company_name: "",
    designation: "",
    relation_name: "",
    house_no_name: "",
    street: "",
    revenue_division: "",
    section_name: "",
    city: "",
    postalcode: null,
    country: 1,
    xx: "",
    HH: "",
    LOC: "",
    ipvid: "",
    ipname: "",
    ipph: "",
    refname: "",
    refvid: "",
    refph: "",
    notes: "",
    count: "",
    occupation: "",
    education: "",
  });

  const navigate = props.navigateto;
  console.log(navigate);
  const Edit = props.Editvalue;
  const [module, setModule] = useState(props.votermoduleType);
  // let module = props.votermoduleType;
  const voterid = addnewvotersData.voter_id;
  const title = addnewvotersData.title;
  const firstName = addnewvotersData.first_name;
  const middleName = addnewvotersData.middle_name;
  const lastName = addnewvotersData.last_name;
  const age = addnewvotersData.age;
  const mobile = addnewvotersData.phone_no;
  const email = addnewvotersData.email_id;
  const designation = addnewvotersData.designation;
  const houseno = addnewvotersData.house_no_name;
  const street = addnewvotersData.street;
  const city = addnewvotersData.city;
  const postalcode = addnewvotersData.postalcode;
  const sectionname = addnewvotersData.section_name;
  const relationname = addnewvotersData.relation_name;
  const revenuedivision = addnewvotersData.revenue_division;
  const companyname = addnewvotersData.company_name;
  const paramxx = addnewvotersData.xx;
  const paramHH = addnewvotersData.HH;
  const paramLOC = addnewvotersData.LOC;
  const paramipvid = addnewvotersData.ipvid;
  const paramipname = addnewvotersData.ipname;
  const paramipph = addnewvotersData.ipph;
  const paramrefname = addnewvotersData.refname;
  const paramrefvid = addnewvotersData.refvid;
  const paramrefph = addnewvotersData.refph;
  const paramnotes = addnewvotersData.notes;
  const paramcount = addnewvotersData.count;
  const paramsoccupation = addnewvotersData.occupation;
  const paramseducation = addnewvotersData.education;

  const [disabledropdowns, setDisabledropdowns] = useState(false);

  useEffect(() => {
    if ((Edit && module === "Edit") || (Edit && module === "view")) {
      setAddnewvotersData((prevState) => ({
        ...prevState,
        voter_id: Edit,
      }));
    } else if (module === "newvoters") {
      setAddnewvotersData((prevState) => ({
        ...prevState,
        voter_id: null,
      }));
    }
    if (module === "view") {
      setDisabledropdowns(true);
    }
  }, []);

  useEffect(() => {
    if (Edit && voterid !== "") {
      checkvoterId(voterid).then((resp) => {
        const respdata = resp.message;
        if (Array.isArray(respdata)) {
          respdata.map((items) => {
            setAlreadyExitData(items);
          });
        } else {
          console.error("respdata is not an array.");
        }
        setUserStatus(resp.status);
      });
    }
  }, [voterid]);

  const [isReset, setIsReset] = useState(false);
  const [agevalue, setAgevalue] = useState();
  const [selectedDateofbirth, setSelectedDateofbirth] = useState("");

  const handleDateChange = (event) => {
    setSelectedDateofbirth(event.target.value);
    setIsReset(false);
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - selectedDate.getFullYear();
    setAddnewvotersData({
      ...addnewvotersData,
      age: parseInt(age),
    });
  };

  const [selectedpollings, setSelectedPollings] = useState({
    polling_booth_id: "",
    polling_booth_no: "",
    polling_booth_name: "",
    polling_booth_address: "",
  });

  const handleselectedpollingstation = (value) => {
    setSelectedPollings(value);
  };

  const [caste, setCaste] = useState([]);
  const [religion, setReligion] = useState([]);
  const [state, setState] = useState([]);
  const [voterfavour, setVoterfavour] = useState([]);
  const [votertype, setVotertype] = useState([]);
  const [subcaste, setSubcaste] = useState([]);
  const [selectedCaste, setSelectedCaste] = useState();
  const [selectedSubcaste, setSelectedSubcaste] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedVotfavour, setSelectedVotfavour] = useState();
  const [selectedvotertype, setselectedVotertype] = useState();
  const [selectedreligion, setSelectedReligion] = useState();
  const [selectedgender, setSelectedGender] = useState();
  const [gendername, setGendername] = useState();
  const [constituencyname, setConstituencyName] = useState();
  const [mandalname, setMandalName] = useState();
  const [villagename, setVillageName] = useState();
  const [votertypename, setvotertypeName] = useState();
  const [votefavourname, setvotefavourName] = useState();
  const [castename, setCasteName] = useState();
  const [subcastename, setsubcasteName] = useState();
  const [religionname, setReligionName] = useState();
  const [relationtypename, setRelationtypeName] = useState();
  const [statename, setStateName] = useState();
  const [voteraddedPopup, setVoteraddedpopup] = useState(false);

  // const [Editvalue, setEditValue] = useState();
  // const [votermoduleType, setVotermoduleType] = useState();

  useEffect(() => {
    newvotersDropdown().then((resp) => {
      setCaste(resp.caste);
      setSubcaste(resp.sub_caste);
      setReligion(resp.religion);
      setState(resp.state);
      setVoterfavour(resp.voter_favour);
      setVotertype(resp.voter_type);
    });
  }, []);

  const [constituencyvaluenewvoters, setConstituencyvaluenewvoters] =
    useState();
  const [dropdownConstituencies, setDropdownConstituencies] = useState([]);
  const [dropdownmandal, setDropdownMandals] = useState([]);
  const [selectedMandaldropdown, setSelectedmandaldropdown] = useState();
  const [dropdownvillages, setDropdownVillages] = useState([]);
  const [selectedVillagedropdown, setSelectedvillagedropdown] = useState();
  const [selectedRelationtype, setRelationtype] = useState();
  const [savevoterPopup, setSavevoterPopup] = useState(false);
  const [pollingStations, setPollingStations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [Subcastesearch, setSubcastesearch] = useState("");

  useEffect(() => {
    constituencynewvoters().then((resp) => {
      const constconstituencynames = resp.constituencies;
      setDropdownConstituencies(constconstituencynames);
    });
  }, []);

  useEffect(() => {
    if (disabledropdowns !== true) {
      newvotersMandals(constituencyvaluenewvoters).then((resp) => {
        if (constituencyvaluenewvoters) {
          setDropdownMandals(resp);
        } else {
          setDropdownMandals(resp.mandal);
        }
      });
    }
  }, [constituencyvaluenewvoters]);

  useEffect(() => {
    if (disabledropdowns !== true) {
      newvotersVillages(selectedMandaldropdown).then((resp) => {
        if (selectedMandaldropdown) {
          setDropdownVillages(resp);
        } else {
          setDropdownVillages(resp.villages);
        }
      });
    }
  }, [selectedMandaldropdown]);

  useEffect(() => {
    if (disabledropdowns !== true) {
      newvotersPollingstations(selectedVillagedropdown).then((resp) => {
        if (selectedVillagedropdown) {
          setPollingStations(resp);
        } else {
          setPollingStations(resp.polling);
        }
      });
    }
  }, [selectedVillagedropdown]);

  const prevconstituenciesdropdown = useRef();
  const prevselectedMandaldropdown = useRef();
  const prevselectedVillagedropdown = useRef();

  const clickedConstituencies = () => {
    if (constituencyvaluenewvoters !== prevconstituenciesdropdown.current) {
      setSelectedmandaldropdown();
      setMandalName();
      setSelectedvillagedropdown();
      setVillageName();
      setSelectedPollings({
        polling_booth_id: "",
        polling_booth_name: "",
        polling_booth_address: "",
      });
    }
  };

  const clickedMandals = () => {
    if (selectedMandaldropdown !== prevselectedMandaldropdown.current) {
      setSelectedvillagedropdown();
      setVillageName();
      setSelectedPollings({
        polling_booth_id: "",
        polling_booth_name: "",
        polling_booth_address: "",
      });
    }
  };

  const setvillageClicked = () => {
    if (selectedVillagedropdown !== prevselectedVillagedropdown.current) {
      setSelectedPollings({
        polling_booth_id: "",
        polling_booth_name: "",
        polling_booth_address: "",
      });
    }
  };

  // caste and subcaste code

  const prevcaste = useRef();
  useEffect(() => {
    if (
      typeof prevcaste.current === typeof selectedCaste &&
      selectedCaste !== prevcaste.current
    ) {
      setSelectedSubcaste();
      setsubcasteName();
      setSubcastesearch("");
    }
    prevcaste.current = selectedCaste;
  }, [selectedCaste]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (selectedCaste) {
      subCasteDropdown(selectedCaste).then((resp) =>
        setSubcaste(resp.subcaste)
      );
      isFirstRender.current = false;
    }
  }, [selectedCaste]);

  const [selectedsubcastesData, setSelectedSubCastesData] = useState();
  useEffect(() => {
    const selectedsubcastes = parseInt(selectedSubcaste);
    setSelectedSubCastesData(selectedsubcastes);
  });

  const selectedpollingbootid = selectedpollings.polling_booth_id;
  const cancleaddvoters = () => {
    props.oncancleclick();
  };

  const [alreadayExistData, setAlreadyExitData] = useState([]);
  const [userSatus, setUserStatus] = useState(true);
  const [Saveandnew, setSaveandnew] = useState(false);
  const [voteriddispble, setvoterIdDisable] = useState(false);

  useEffect(() => {
    if (userSatus === false) {
      setSaveandnew(true);
      setvoterIdDisable(true);
    }
  }, [userSatus]);

  function handleBlur() {
    checkvoterId(voterid).then((resp) => {
      if (resp.status === false) {
        const populatedata = resp.message;
        populatedata.map((items) => {
          setAlreadyExitData(items);
        });
        // setAlreadyExitData(resp.message);
        setUserStatus(resp.status);
      } else if (resp.status === true) {
        setAlreadyExitData(null);
        setUserStatus(resp.status);
      }
    });
  }

  const [voteridError, setVoterIdError] = useState();
  const [firstnameError, setfirstnameError] = useState();
  // const [middleNameError, setmiddleNameError] = useState();
  const [lastnameError, setLastnameError] = useState();
  const [genderError, setGenderError] = useState();
  const [dobError, setDobError] = useState();
  const [ageerror, setAgeError] = useState();
  const [mobileError, setMobileError] = useState();
  const [constituencyerror, setconstituencyerror] = useState();
  const [mandalError, setMandalError] = useState();
  const [housenumberError, sethousenumberError] = useState();
  const [sectionnameError, setSectionnameError] = useState();
  const [villagesError, setVillageError] = useState();
  const [pollingbootherror, setpollingboothError] = useState();
  const [subcaste_caste, setSubcaste_caste] = useState();

  useEffect(() => {
    if (selectedgender === "M") {
      setGendername("Male");
    } else if (selectedgender === "F") {
      setGendername("Female");
    } else if (selectedgender === "O") {
      setGendername("Others");
    }
    if (relationtypename === "F") {
      setRelationtypeName("Father");
    } else if (relationtypename === "M") {
      setRelationtypeName("Mother");
    } else if (relationtypename === "H") {
      setRelationtypeName("Husband");
    } else if (relationtypename === "O") {
      setRelationtypeName("Others");
    }
  }, [selectedgender]);

  // save and new
  const dataRef = useRef(false);
  const handleClickedSaveandNew = () => {
    dataRef.current = true;
    savedvoter();
  };

  useEffect(() => {
    if (userSatus === false) {
      setConstituencyvaluenewvoters(alreadayExistData.constituency);
      setConstituencyName(
        alreadayExistData.constituency__mla_constituency_name
      );
      setSelectedmandaldropdown(alreadayExistData.mandal);
      setMandalName(alreadayExistData.mandal__mandal_name);
      setSelectedvillagedropdown(alreadayExistData.ward_village);
      setVillageName(alreadayExistData.ward_village__ward_village_name);
      setvotertypeName(alreadayExistData.voter_type__voter_type_name);
      setselectedVotertype(alreadayExistData.voter_type);
      setSelectedVotfavour(alreadayExistData.voter_favour_to);
      setvotefavourName(alreadayExistData.voter_favour_to__party_name);
      setCasteName(alreadayExistData.sub_caste__caste__caste_name);
      setSelectedCaste(alreadayExistData.sub_caste__caste__caste_id);
      setSelectedSubcaste(alreadayExistData.sub_caste);
      setsubcasteName(alreadayExistData.sub_caste__sub_caste_name);
      setSelectedReligion(alreadayExistData.religion);
      setReligionName(alreadayExistData.religion__religion_name);
      setRelationtype(alreadayExistData.relation_type);
      setRelationtypeName(alreadayExistData.relation_type);
      setSelectedDateofbirth(alreadayExistData.dob);
      setSelectedState(alreadayExistData.state);
      setStateName(alreadayExistData.state__state_name);
      setSelectedGender(alreadayExistData.gender);

      setAddnewvotersData((prevState) => ({
        ...prevState,
        first_name: alreadayExistData.first_name,
        middle_name: alreadayExistData.middle_name,
        last_name: alreadayExistData.last_name,
        age: alreadayExistData.age,
        phone_no: alreadayExistData.phone_no,
        email_id: alreadayExistData.email_id,
        company_name: alreadayExistData.company_name,
        title: alreadayExistData.title,
        designation: alreadayExistData.designation,
        relation_name: alreadayExistData.relation_first_name,
        house_no_name: alreadayExistData.house_no_name,
        revenue_division: alreadayExistData.revenue_division,
        section_name: alreadayExistData.section_name,
        street: alreadayExistData.street,
        city: alreadayExistData.city,
        postalcode: alreadayExistData.postalcode,
        xx: alreadayExistData.xx,
        HH: alreadayExistData.house_head,
        LOC: alreadayExistData.curr_loc,
        ipvid: alreadayExistData.ip_vid,
        ipname: alreadayExistData.ip_name,
        ipph: alreadayExistData.ip_ph,
        refname: alreadayExistData.ref_name,
        refvid: alreadayExistData.ref_vid,
        refph: alreadayExistData.ref_ph,
        notes: alreadayExistData.notes,
        count: alreadayExistData.voters_count,
        occupation: alreadayExistData.occupation,
        education: alreadayExistData.education,
      }));
      setSelectedPollings((prevState) => ({
        ...prevState,
        polling_booth_id: alreadayExistData.polling_booth,
        polling_booth_no: alreadayExistData.polling_booth__polling_booth_no,
        polling_booth_name: alreadayExistData.polling_booth__polling_booth_name,
        polling_booth_address:
          alreadayExistData.polling_booth__polling_booth_address,
      }));
    }
  }, [userSatus]);

  const savedvoter = () => {
    if (userSatus === false) {
      updatevoterData(
        selectedMandaldropdown,
        selectedgender,
        selectedRelationtype,
        selectedVillagedropdown,
        selectedpollingbootid,
        selectedSubcaste,
        selectedState,
        selectedreligion,
        selectedvotertype,
        constituencyvaluenewvoters,
        selectedVotfavour,
        voterid,
        title,
        firstName,
        middleName,
        lastName,
        age,
        mobile,
        email,
        designation,
        houseno,
        street,
        city,
        postalcode,
        sectionname,
        relationname,
        revenuedivision,
        companyname,
        selectedDateofbirth,
        paramxx,
        paramHH,
        paramLOC,
        paramipvid,
        paramipname,
        paramipph,
        paramrefname,
        paramrefvid,
        paramrefph,
        paramnotes,
        paramcount,
        paramsoccupation,
        paramseducation
      ).then((resp) => {
        alert("voter updated successfully");
        if (navigate == "wardvillage") {
          window.location.href = "/wardvillageissues";
        }
        if (navigate == "votersdata") {
          window.location.href = "/votersdata";
        }
      });
    } else {
      const nameRegex = /^[A-Z][a-z]*(?: [A-Z][a-z]*)?$/;
      const phoneNumberRegex = /^\d{10}$/;
      if (
        voterid.trim() === "" ||
        firstName.trim() === "" ||
        !nameRegex.test(firstName) ||
        lastName.trim() === "" ||
        !nameRegex.test(lastName) ||
        selectedgender === undefined ||
        selectedDateofbirth === "" ||
        age === null ||
        age > 200 ||
        !phoneNumberRegex.test(mobile) ||
        houseno === "" ||
        sectionname === "" ||
        constituencyvaluenewvoters === undefined ||
        selectedpollingbootid === "" ||
        !nameRegex.test(sectionname)
      ) {
        setVoterIdError(voterid.trim() === "" ? "Voter Id required" : "");
        setfirstnameError(
          firstName.trim() === "" || !nameRegex.test(firstName)
            ? "Firstname required"
            : ""
        );

        setLastnameError(
          lastName.trim() === "" || !nameRegex.test(lastName)
            ? "Lastname required"
            : ""
        );
        setGenderError(
          selectedgender === undefined ? "Gender is required" : ""
        );
        setDobError(selectedDateofbirth === "" ? "DOB is required" : "");
        // setAgeError(age === null ? "Age is required" : "");
        setAgeError(
          !age
            ? "Age required must be at least 18"
            : age < 18
            ? "Age must be at least 18"
            : age > 200
            ? "Age must be less than or equal to 200"
            : ""
        );
        setMobileError(
          !phoneNumberRegex.test(mobile)
            ? "Ph-No required,must have 10 digits"
            : ""
        );
        setIsValid(!phoneNumberRegex.test(mobile) ? false : true);
        setconstituencyerror(
          constituencyvaluenewvoters === undefined
            ? "Constituency required"
            : ""
        );
        sethousenumberError(houseno === "" ? "House Number required" : "");
        setpollingboothError(
          selectedpollingbootid === "" ? "Polling Booth Number required" : ""
        );
        setSectionnameError(
          sectionname === "" || !nameRegex.test(sectionname)
            ? "Section Name required"
            : ""
        );
      } else {
        addvoter(
          selectedMandaldropdown,
          selectedgender,
          selectedRelationtype,
          selectedVillagedropdown,
          selectedpollingbootid,
          selectedSubcaste,
          selectedState,
          selectedreligion,
          selectedvotertype,
          constituencyvaluenewvoters,
          selectedVotfavour,
          voterid,
          title,
          firstName,
          middleName,
          lastName,
          age,
          mobile,
          email,
          designation,
          houseno,
          street,
          city,
          postalcode,
          sectionname,
          relationname,
          revenuedivision,
          companyname,
          selectedDateofbirth,
          paramxx,
          paramHH,
          paramLOC,
          paramipvid,
          paramipname,
          paramipph,
          paramrefname,
          paramrefvid,
          paramrefph,
          paramnotes,
          paramcount,
          paramsoccupation,
          paramseducation
        )
          .then((resp) => {
            setVoteraddedpopup(true);
            if (dataRef.current) {
              setAddnewvotersData({
                voter_id: "",
                title: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                age: null,
                phone_no: null,
                email_id: "",
                company_name: "",
                designation: "",
                relation_name: "",
                house_no_name: "",
                street: "",
                revenue_division: "",
                section_name: "",
                city: "",
                postalcode: "",
                country: 1,
                xx: "",
                HH: "",
                LOC: "",
                ipvid: "",
                ipname: "",
                ipph: "",
                refname: "",
                refvid: "",
                refph: "",
                notes: "",
                count: "",
                paramsoccupation: "",
                paramseducation: "",
              });
              setSelectedDateofbirth("");
              setConstituencyName();
              setMandalName();
              setVillageName();
              setSelectedGender();
              setGendername();
              setSelectedDateofbirth("dd-mm-yy");
              setIsReset(true);
              setSelectedPollings({
                polling_booth_id: "",
                polling_booth_name: "",
                polling_booth_address: "",
              });
            } else {
              alert("voter updated successfully");
              if (navigate == "wardvillage") {
                window.location.href = "/wardvillageissues";
              }
              if (navigate == "votersdata") {
                window.location.href = "/votersdata";
              }
            }
          })
          .catch((error) => {
            alert(error);
          });
      }
    }
  };

  useEffect(() => {
    if (voteraddedPopup) {
      const timeout = setTimeout(() => {
        setVoteraddedpopup(false);
        setVoterIdError();
        setfirstnameError();
        setLastnameError();
        setGenderError();
        setDobError();
        setAgeError();
        setMobileError();
        setMandalError();
        sethousenumberError();
        setSectionnameError();
        setVillageError();
        setpollingboothError();
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [voteraddedPopup]);

  const handleAddvotersClosed = () => {
    props.addVoterclosed();
  };

  const [isValid, setIsValid] = useState(true);

  const handlemobileBlur = () => {
    const phoneNumber = addnewvotersData.phone_no;
    const isValidNumber = /^\d{10}$/.test(phoneNumber);
    setIsValid(isValidNumber);
  };

  // code for drop down search filter  for mandal
  const [alpahbateConstituency, setAlphabateConstituency] = useState("");
  const [alphabatesMandal, setAlphabetsMandal] = useState("");
  const [alpahbateVillages, setAlphabateVillages] = useState("");

  const handleConstituencyKeydown = (event) => {
    if (/[a-z]/i.test(event.key)) {
      setAlphabateConstituency(event.key.toUpperCase());
    } else {
      setAlphabateConstituency("");
    }
  };

  const handleMandalKeydown = (event) => {
    if (/[a-z]/i.test(event.key)) {
      setAlphabetsMandal(event.key.toUpperCase());
    } else {
      setAlphabetsMandal("");
    }
  };

  const handleVillageKeydown = (event) => {
    if (/[a-z]/i.test(event.key)) {
      setAlphabateVillages(event.key.toUpperCase());
    } else {
      setAlphabateVillages("");
    }
  };

  const filteredConstituency = alpahbateConstituency
    ? dropdownConstituencies
        .filter(
          (constituencies) =>
            constituencies.mla_constituency_name &&
            (constituencies.mla_constituency_name
              .toUpperCase()
              .startsWith(alpahbateConstituency) ||
              constituencies.mla_constituency_name
                .toUpperCase()
                .includes(alpahbateConstituency))
        )
        .sort((a, b) => {
          const aStartsWith = a.mla_constituency_name
            .toUpperCase()
            .startsWith(alpahbateConstituency);
          const bStartsWith = b.mla_constituency_name
            .toUpperCase()
            .startsWith(alpahbateConstituency);
          if (aStartsWith && !bStartsWith) {
            return -1;
          } else if (!aStartsWith && bStartsWith) {
            return 1;
          } else {
            return 0;
          }
        })
    : dropdownConstituencies;

  const filteredMandalItems = alphabatesMandal
    ? dropdownmandal
        .filter(
          (villages) =>
            villages.mandal_name &&
            (villages.mandal_name.toUpperCase().startsWith(alphabatesMandal) ||
              villages.mandal_name.toUpperCase().includes(alphabatesMandal))
        )
        .sort((a, b) => {
          const aStartsWith = a.mandal_name
            .toUpperCase()
            .startsWith(alphabatesMandal);
          const bStartsWith = b.mandal_name
            .toUpperCase()
            .startsWith(alphabatesMandal);
          if (aStartsWith && !bStartsWith) {
            return -1;
          } else if (!aStartsWith && bStartsWith) {
            return 1;
          } else {
            return 0;
          }
        })
    : dropdownmandal;

  const filteredVillageItems = alpahbateVillages
    ? dropdownvillages
        .filter(
          (mandals) =>
            mandals.ward_village_name &&
            (mandals.ward_village_name
              .toUpperCase()
              .startsWith(alpahbateVillages) ||
              mandals.ward_village_name
                .toUpperCase()
                .includes(alpahbateVillages))
        )
        .sort((a, b) => {
          const aStartsWith = a.ward_village_name
            .toUpperCase()
            .startsWith(alpahbateVillages);
          const bStartsWith = b.ward_village_name
            .toUpperCase()
            .startsWith(alpahbateVillages);
          if (aStartsWith && !bStartsWith) {
            return -1;
          } else if (!aStartsWith && bStartsWith) {
            return 1;
          } else {
            return 0;
          }
        })
    : dropdownvillages;

  let text;
  if (module === "view") {
    text = "View Voters";
  } else if (module === "Edit") {
    text = "Edit Voters";
  } else {
    text = "New Voters";
  }

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterData = (searchTerm) => {
    return caste.filter((Caste) => {
      return Caste.caste_name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  const handleChangeSubcastesearch = (event) => {
    setSubcastesearch(event.target.value);
  };

  const filterDatasubcastesearch = (Subcastesearch) => {
    return subcaste.filter((subCaste) => {
      return (
        subCaste.sub_caste_name &&
        subCaste.sub_caste_name
          .toLowerCase()
          .includes(Subcastesearch.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const foundObject = caste.find((obj) => obj.caste_id === subcaste_caste);
    setSelectedCaste(foundObject ? foundObject.caste_id : "");
    setCasteName(foundObject ? foundObject.caste_name : "");
  }, [subcaste_caste]);

  const isValidVoterID = (input) => {
    return /^ABCEDEFHIKLMANOPQRSTUVWXYZ\d{10}$/.test(input); // Checks for format "AJN" followed by 7 digits
  };
  const [deleteVoters, setDeletevoters] = useState(1);
  const [SearchtermvoterID, setSearchtermvoterID] = useState("");

  const handleclickEdit = () => {
    setModule("Edit");
  };

  console.log("m", module);
  const handledeleteVoter = () => {
    deleteVoter(selectedvoter)
      .then((resp) => {
        setSelectedvoter();
      })
      .then(() => {
        setDeletevoters((prevState) => prevState + 1);
      })
      .finally(() => {
        setSearchtermvoterID("");
      });
  };
  const handlecancleDeletevoter = () => {
    setSelectedvoter();
  };

  const handleclickDelete = () => {
    deleteVoter(addnewvotersData.voter_id).then((resp) => {
      console.log(resp);
    });
  };

  return (
    <div>
      <div
        className="add_voters_popup_main_div d-flex justify-content-center align-items-center"
        onClick={handleAddvotersClosed}
      ></div>
      {/* start if module == view popup from bottom */}
      {module == "view" && (
        <div className="view_voters_data_popup_main_div">
          <div className=" view_voters_popup_sub_div_bottom">
            <span className="view_voters_voter_id_display">
              {addnewvotersData.voter_id}
            </span>

            {/* <p>Print</p>       */}
            <span
              className="pencil_icon_view_voters_"
              onClick={handleclickEdit}
            >
              <img src={Pencilicon} className="pencil_icon_mobile_" />
              {/* <i class="fa fa-pencil"></i> */}
            </span>

            <span
              //  onClick={handleclickDelete}
              onClick={() => {
                setSelectedvoter(addnewvotersData.voter_id);
              }}
            >
              <img src={Delteicon} className="delete_icon_mobile_" />
            </span>

            <div className="view_voters_close_icon" onClick={cancleaddvoters}>
              {/* X */}
              <img src={Closecrossicon} className="cross_img_" />
            </div>
          </div>
          <div className="popup_newvotrs_underlines_1"></div>

          <div className="popup_view_voters_main_content_div">
            <div className=" view_voters_first_name">
              <span className="view_voters_first_name_name"> First name</span>

              <span className="d-flex popup_view_voters_text" id="first_name_text">
                {addnewvotersData.first_name}
              </span>
            </div>
            <div className="view_voters_last_name">
              <span className="view_voters_first_name_name"> Last name</span>

              <span className="d-flex popup_view_voters_text" id="first_name_text">
                {addnewvotersData.last_name}
              </span>
            </div>
          </div>

          <div className="popup_view_voters_main_content_div">
            <div className=" view_voters_first_name">
              <span className="view_voters_first_name_name"> Designation</span>

              <span className="d-flex popup_view_voters_text" >
                {addnewvotersData.designation}
              </span>
            </div>

            <div className="view_voters_gender">
              <span className="view_voters_first_name_name"> Gender</span>

              <span className=" d-flex popup_view_voters_text">
                {gendername}
              </span>
            </div>
          </div>

          <div className="popup_view_voters_main_content_div">
            <div className=" view_voters_first_name">
              <span className="view_voters_first_name_name">Caste</span>

              <span className="d-flex popup_view_voters_text">
                {/* Gouds */}
                {castename}
                </span>
            </div>
            <div className="view_voters_mobile">
              <span className="view_voters_first_name_name"> Mobile no.</span>

              <span className="d-flex popup_view_voters_text">
                +91{addnewvotersData.phone_no || ""}
              </span>
            </div>
          </div>

          <div className="popup_view_voters_main_content_div">
            <div className=" view_voters_first_name">
              <span className="view_voters_first_name_name"> Email</span>
              <span className="d-flex popup_view_voters_text">
                {addnewvotersData.email_id}
              </span>
            </div>
          </div>

          {/* // */}
        </div>
      )}
      {/* End if module == view popup from bottom */}

      {/* start  add new voter popup*/}

      {module !== "view" && (
        <div className="add_voters_popup_scroll_div d-flex justify-content-center ">
          <div className="add_voters_popup_sub_div d-flex flex-column">
            <div className="add_voters_title_">
              <div
                className="add_voters_popup_cancle_btn_"
                onClick={cancleaddvoters}
              >
                <img src={Closecrossicon} className="close_cross_icon_voters" />
              </div>
              <span className="title_voters_add">{text}</span>
              <div>
                {disabledropdowns ? null : (
                  <button
                    id="save_btn_voters"
                    className="add_voters_popup_save_btn d-flex align-items-center justify-content-center"
                    onClick={savedvoter}
                    disabled={disabledropdowns}
                  >
                    Save
                  </button>
                )}

                {(disabledropdowns || module === "Edit") && (
                  <button
                    id="save_btn_inEdit_voters_display"
                    className="add_voters_popup_save_btn
                     d-flex align-items-center justify-content-center"
                    onClick={savedvoter}
                    disabled={disabledropdowns}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>

            <div className="popup_newvotrs_underline_div"></div>
            <div className="popup_new_voter_informtion_div">
              <p>Information</p>
            </div>
            <div className="d-flex main_flex_component">
              <div className="information_details_main_div_newvoter d-flex flex-column align-items-start">
                {module == "view" ? null : (
                  <div className="add_picture_main">
                    <div>
                      <img src={Addpicture} alt="notvisible" />
                    </div>
                    <div className="add_picture_sub">Add Picture</div>
                  </div>
                )}

                <div className="voter_info_new_voter_main">
                  Voter ID
                  {/* Number */}
                  <FaAsterisk className="asterisk_newvoter" />
                  <div className="new_voter_data_input">
                    <input
                      type={"text"}
                      alt="not-visible"
                      placeholder="Enter Voter ID"
                      value={addnewvotersData.voter_id}
                      onChange={(e) =>
                        setAddnewvotersData({
                          ...addnewvotersData,
                          voter_id: e.target.value,
                        })
                      }
                      onBlur={Edit ? null : handleBlur}
                      disabled={voteriddispble}
                    />
                    <p class="voteriderror">{voteridError}</p>
                  </div>
                </div>
                <div className="voter_info_new_voter_main">
                  First Name
                  <FaAsterisk className="asterisk_newvoter" />
                  <div className="new_voter_data_input">
                    <input
                      type="text"
                      alt="not-visible"
                      value={addnewvotersData.first_name}
                      disabled={disabledropdowns}
                      placeholder={
                        module === "view" ? "NA" : "Enter your First Name"
                      }
                      autoCapitalize="none"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const filteredValue = inputValue.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        ); // Remove non-alphabetic characters
                        const capitalizedValue = filteredValue
                          .toLowerCase()
                          .replace(/(^|\s)\S/g, (match) => match.toUpperCase());

                        setAddnewvotersData({
                          ...addnewvotersData,
                          first_name: capitalizedValue,
                        });
                      }}
                    />

                    <p className="firstnameerror">{firstnameError}</p>
                  </div>
                </div>
                {module == "view" ? null : (
                  <div className="voter_info_new_voter_main">
                    Middle Name
                    <div className="new_voter_data_input">
                      <input
                        type="text"
                        alt="not-visible"
                        value={addnewvotersData.middle_name}
                        placeholder={
                          module === "view" ? "NA" : "Enter your Middle name"
                        }
                        disabled={disabledropdowns}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const filteredValue = inputValue.replace(
                            /[^a-zA-Z\s]/g,
                            ""
                          ); // Remove non-alphabetic characters
                          const capitalizedValue = filteredValue
                            .toLowerCase()
                            .replace(/(^|\s)\S/g, (match) =>
                              match.toUpperCase()
                            );

                          setAddnewvotersData({
                            ...addnewvotersData,
                            middle_name: capitalizedValue,
                          });
                        }}
                      />

                      {/* <p className="middlename_error">{middleNameError}</p> */}
                    </div>
                  </div>
                )}

                <div className="voter_info_new_voter_main">
                  Last Name
                  <FaAsterisk className="asterisk_newvoter" />
                  <div className="new_voter_data_input">
                    <input
                      type="text"
                      alt="not-visible"
                      placeholder={
                        module === "view" ? "NA" : "Enter your Last Name"
                      }
                      value={addnewvotersData.last_name}
                      disabled={disabledropdowns}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const filteredValue = inputValue.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        ); // Remove non-alphabetic characters
                        const capitalizedValue = filteredValue
                          .toLowerCase()
                          .replace(/(^|\s)\S/g, (match) => match.toUpperCase());

                        setAddnewvotersData({
                          ...addnewvotersData,
                          last_name: capitalizedValue,
                        });
                      }}
                    />

                    <p className="lastnameerror">{lastnameError}</p>
                  </div>
                </div>
                <div className="voter_info_new_voter_main">
                  Gender
                  <FaAsterisk className="asterisk_newvoter" />
                  <Dropdown class="new_voter_dropdown">
                    <Dropdown.Toggle
                      className="new_voter_popup_drop_div"
                      id="dropdown-basic"
                      disabled={disabledropdowns}
                    >
                      {gendername ? gendername : "--None--"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Gender.length > 0 &&
                        Gender.map((gender) => {
                          return (
                            <Dropdown.Item
                              className="dropdown_text_newVoters"
                              href="#/action-1"
                              onClick={() => {
                                setSelectedGender(gender.shortform);
                                setGendername(gender.gender);
                              }}
                            >
                              {gender.gender}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <p class="validationErrorsdropdown" id="valiationError_dropdown">{genderError}</p>
                </div>

                {module == "view" ? null : (
                  <div
                    className="voter_info_new_voter_main"
                    id="div_for_error_msg"
                  >
                    Date of birth
                    <FaAsterisk className="asterisk_newvoter" />
                    <div className="new_voter_data_input">
                      <input
                        type={"date"}
                        alt="not-visible"
                        value={selectedDateofbirth}
                        disabled={disabledropdowns}
                        onChange={handleDateChange}
                        placeholder={isReset ? "dd-mm-yy" : ""}
                      />
                    </div>
                    <p class="validationErrorsdropdown">{dobError}</p>
                  </div>
                )}

                <div className="voter_info_new_voter_main">
                  Age
                  <FaAsterisk className="asterisk_newvoter" />
                  <div className="new_voter_data_input">
                    <input
                      className="age_appreance"
                      type={"number"}
                      alt="not-visible"
                      placeholder={module === "view" ? "NA" : "Enter your Age"}
                      value={addnewvotersData.age || ""}
                      disabled={disabledropdowns}
                      // onChange={(e) =>

                      // }
                      min={18}
                      max={150}
                    />
                  </div>
                  <p class="validationErrorsdropdown">{ageerror}</p>
                </div>

                <div className="voter_info_new_voter_main">
                  Mobile No.
                  <FaAsterisk className="asterisk_newvoter" />
                  <div className="new_voter_data_input">
                    <input
                      type={"number"}
                      alt="not-visible"
                      placeholder={
                        module === "view" ? "NA" : "Enter your Mobile Number"
                      }
                      value={addnewvotersData.phone_no || ""}
                      disabled={disabledropdowns}
                      onChange={(e) =>
                        setAddnewvotersData({
                          ...addnewvotersData,
                          phone_no: parseInt(e.target.value),
                        })
                      }
                      min={0}
                      onBlur={handlemobileBlur}
                    />

                    {!isValid && (
                      <p class="validationErrorsdropdown">
                        {mobileError
                          ? mobileError
                          : "Phone number must be 10 digits."}
                      </p>
                    )}
                  </div>
                </div>
                <div className="voter_info_new_voter_main">
                  Email
                  <div className="new_voter_data_input">
                    <input
                      type={"text"}
                      alt="not-visible"
                      placeholder={
                        module === "view" ? "NA" : "Enter your Email ID"
                      }
                      value={addnewvotersData.email_id}
                      disabled={disabledropdowns}
                      onChange={(e) =>
                        setAddnewvotersData({
                          ...addnewvotersData,
                          email_id: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                {module == "view" ? null : (
                  <div className="voter_info_new_voter_main">
                    Company
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter your Company"
                        }
                        value={addnewvotersData.company_name}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            company_name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                )}

                {module == "view" ? null : (
                  <div className="voter_info_new_voter_main">
                    Title
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={module === "view" ? "NA" : "Enter Title"}
                        value={addnewvotersData.title}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            title: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                )}

                {/* 
                {module == "view" ? null : (
                  <div className="voter_info_new_voter_main">
                    Title
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={module === "view" ? "NA" : "Enter Title"}
                        value={addnewvotersData.title}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            title: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                )} */}
                {module == "view" ? null : (
                  <div
                    className="voter_info_new_voter_main"
                    onKeyDown={handleConstituencyKeydown}
                  >
                    Constituency
                    <FaAsterisk className="asterisk_newvoter" />
                    <Dropdown
                      class="new_voter_dropdown"
                      // onClick={handleMandaldropdownClicked}
                    >
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {/* <span>{constituencyname ? constituencyname : "--None--"}</span> */}
                        {constituencyname ? constituencyname : "--None--"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {filteredConstituency.length > 0 &&
                          filteredConstituency.map((constituencies) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-3"
                                onClick={() => {
                                  setConstituencyvaluenewvoters(
                                    constituencies.constituency_id
                                  );
                                  setConstituencyName(
                                    constituencies.mla_constituency_name
                                  );
                                  clickedConstituencies();
                                }}
                              >
                                {constituencies.mla_constituency_name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <p class="validationErrorsdropdown" id="valiationError_dropdown">{constituencyerror}</p>
                  </div>
                )}
                {module == "view" ? null : (
                  <div
                    className="voter_info_new_voter_main"
                    onKeyDown={handleMandalKeydown}
                    id="div_for_error_msg"
                  >
                    Mandal/Town
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {mandalname ? mandalname : "--None--"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {filteredMandalItems.length > 0 &&
                          filteredMandalItems?.map((mandals) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-3"
                                onClick={() => {
                                  setSelectedmandaldropdown(mandals.mandal_id);
                                  setMandalName(mandals.mandal_name);
                                  clickedMandals();
                                }}
                              >
                                {mandals.mandal_name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <p class="validationErrorsdropdown">{mandalError}</p>
                  </div>
                )}

                {module == "view" ? null : (
                  <div
                    className="voter_info_new_voter_main"
                    onKeyDown={handleVillageKeydown}
                    id="div_for_error_msg"
                  >
                    Ward/Village
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {villagename ? villagename : "--None--"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {filteredVillageItems.length > 0 &&
                          filteredVillageItems?.map((villages) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-3"
                                onClick={() => {
                                  setSelectedvillagedropdown(
                                    villages.ward_village_id
                                  );
                                  setVillageName(villages.ward_village_name);
                                  setvillageClicked();
                                }}
                              >
                                {villages.ward_village_name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <p class="validationErrorsdropdown">{villagesError}</p>
                  </div>
                )}
                {module == "view" ? null : (
                  <div
                    className="voter_info_new_voter_main"
                    id="div_for_error_msg"
                  >
                    Voter Type
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {votertypename ? votertypename : "--None--"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {votertype.length > 0 &&
                          votertype.map((votertype) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-1"
                                onClick={() => {
                                  setselectedVotertype(votertype.voter_type_id);
                                  setvotertypeName(votertype.voter_type_name);
                                }}
                              >
                                {votertype.voter_type_name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

                {module == "view" ? null : (
                  <div className="voter_info_new_voter_main">
                    Voter Favour To
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {votefavourname ? votefavourname : "--None--"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {voterfavour.length > 0 &&
                          voterfavour.map((value) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-1"
                                onClick={() => {
                                  setSelectedVotfavour(value.party_id);
                                  setvotefavourName(value.party_name);
                                }}
                              >
                                {value.party_name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
              {module == "view" ? null : (
                <div className="information_details_main_div_newvoter">
                  <div className="voter_info_new_voter_main">
                    Caste Type
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {castename ? castename : "--None--"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <input
                          type="text"
                          className="input_search"
                          placeholder="search"
                          onChange={handleChange}
                        />
                        {filterData(searchTerm).map((item) => {
                          return (
                            <Dropdown.Item
                              className="dropdown_text_newVoters"
                              href="#/action-1"
                              key={item.caste_id}
                              onClick={() => {
                                setSelectedCaste(item.caste_id);
                                setCasteName(item.caste_name);
                              }}
                            >
                              {item.caste_name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Caste
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {subcastename ? subcastename : "--None--"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <input
                          type="text"
                          value={Subcastesearch}
                          className="input_search"
                          placeholder="search"
                          onChange={handleChangeSubcastesearch}
                        />
                        {filterDatasubcastesearch(Subcastesearch).map(
                          (subCaste) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-2"
                                onClick={() => {
                                  setSelectedSubcaste(subCaste.sub_caste_id);
                                  setsubcasteName(subCaste.sub_caste_name);
                                  setSubcaste_caste(subCaste.caste_id);
                                }}
                              >
                                {subCaste.sub_caste_name}
                              </Dropdown.Item>
                            );
                          }
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Religion
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {religionname ? religionname : "--None--"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {religion.length > 0 &&
                          religion.map((Religion) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-1"
                                onClick={() => {
                                  setSelectedReligion(Religion.religion_id);
                                  setReligionName(Religion.religion_name);
                                }}
                              >
                                {Religion.religion_name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Designation
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        disabled={disabledropdowns}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Designation"
                        }
                        value={addnewvotersData.designation}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            designation:
                              e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Relation type
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {relationtypename ? relationtypename : "--None--"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {relationtypeData.length > 0 &&
                          relationtypeData.map((value) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-3"
                                onClick={() => {
                                  setRelationtype(value.relation);
                                  setRelationtypeName(value.relation_type);
                                }}
                              >
                                {value.relation_type}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Relation Name
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        disabled={disabledropdowns}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Relation Name"
                        }
                        value={addnewvotersData.relation_name}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            relation_name:
                              e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div class="new_voter_add_info">Address Information</div>
                  <div className="voter_info_new_voter_main">
                    House Number
                    <FaAsterisk className="asterisk_newvoter" />
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        disabled={disabledropdowns}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter House Number"
                        }
                        value={addnewvotersData.house_no_name}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            house_no_name: e.target.value,
                          })
                        }
                      />
                      <p
                        class="validationErrorsdropdown"
                        id="housenumber_newvoter"
                      >
                        {housenumberError}
                      </p>
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Street
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={module === "view" ? "NA" : "Enter Street"}
                        value={addnewvotersData.street}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            street: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Revenue division
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Revenue division"
                        }
                        value={addnewvotersData.revenue_division}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            revenue_division: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="voter_info_new_voter_main">
           Selection number
           <div className="new_voter_data_input">
             <input
               type={"text"}
               alt="not-visible"
               placeholder="Enter Selection number"
             />
           </div>
         </div> */}
                  <div className="voter_info_new_voter_main">
                    Section name
                    <FaAsterisk className="asterisk_newvoter" />
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Section name"
                        }
                        value={addnewvotersData.section_name}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            section_name:
                              e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1),
                          })
                        }
                      />
                    </div>
                    <p class="validationErrorsdropdown">{sectionnameError}</p>
                  </div>

                  <div className="voter_info_new_voter_main">
                    Polling Booth No
                    <FaAsterisk className="asterisk_newvoter" />
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {selectedpollings.polling_booth_no
                          ? selectedpollings.polling_booth_no
                          : "--None--"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {pollingStations.length > 0 &&
                          pollingStations?.map((value) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                onClick={() =>
                                  handleselectedpollingstation(value)
                                }
                              >
                                <p>{value.polling_booth_no}</p>
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <p class="validationErrorsdropdown" id="valiationError_dropdown">{pollingbootherror}</p>
                  </div>

                  <div
                    className="voter_info_new_voter_main"
                    id="div_for_error_msg"
                  >
                    Name of the Polling Station
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {selectedpollings.polling_booth_name
                          ? selectedpollings.polling_booth_name
                          : "--None--"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {pollingStations.length > 0 &&
                          pollingStations?.map((value) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                onClick={() =>
                                  handleselectedpollingstation(value)
                                }
                              >
                                <p>{value.polling_booth_name}</p>
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Address of the Polling Station
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {selectedpollings.polling_booth_address
                          ? selectedpollings.polling_booth_address
                          : "--None--"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {pollingStations.length > 0 &&
                          pollingStations?.map((value) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                onClick={() =>
                                  handleselectedpollingstation(value)
                                }
                              >
                                <p className="word_">
                                  {value.polling_booth_address}
                                </p>
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="voter_info_new_voter_main">
                    City
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={module === "view" ? "NA" : "Enter City"}
                        value={addnewvotersData.city}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            city:
                              e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    State/Province
                    <Dropdown class="new_voter_dropdown">
                      <Dropdown.Toggle
                        className="new_voter_popup_drop_div"
                        id="dropdown-basic"
                        disabled={disabledropdowns}
                      >
                        {statename ? statename : "--State/Province--"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {state.length > 0 &&
                          state.map((value) => {
                            return (
                              <Dropdown.Item
                                className="dropdown_text_newVoters"
                                href="#/action-1"
                                onClick={() => {
                                  setSelectedState(value.state_id);
                                  setStateName(value.state_name);
                                }}
                              >
                                {value.state_name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}

              {module == "view" ? null : (
                <div className="information_details_main_div_newvoter">
                  <div className="voter_info_new_voter_main">
                    Zip/Postal Code
                    <div className="new_voter_data_input">
                      <input
                        type={"number"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Zip/Postal Code"
                        }
                        value={addnewvotersData.postalcode}
                        disabled={disabledropdowns}
                        // onChange={(e) =>
                        //   setAddnewvotersData({
                        //     ...addnewvotersData,
                        //     postalcode: parseInt(e.target.value),
                        //   })
                        // }
                        onChange={(e) => {
                          const enteredValue = e.target.value.trim();
                          if (
                            enteredValue === "" ||
                            (!isNaN(enteredValue) &&
                              parseInt(enteredValue) >= 0)
                          ) {
                            setAddnewvotersData({
                              ...addnewvotersData,
                              postalcode:
                                enteredValue === ""
                                  ? ""
                                  : parseInt(enteredValue),
                            });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "-" || e.key === "e" || e.key === "E") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Status
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={module === "view" ? "NA" : "Enter Status"}
                        value={addnewvotersData.xx}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            xx: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Head of House
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Head of House"
                        }
                        value={addnewvotersData.HH}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            HH: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Location
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter your Location"
                        }
                        value={addnewvotersData.LOC}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            LOC: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Important Person VID
                    <div className="new_voter_data_input">
                      <input
                        type={"number"}
                        alt="not-visible"
                        placeholder={
                          module === "view"
                            ? "NA"
                            : "Enter Important Person VID"
                        }
                        value={addnewvotersData.ipvid}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            ipvid: parseInt(e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Important Person Name
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={
                          module === "view"
                            ? "NA"
                            : "Enter Important Person Name"
                        }
                        value={addnewvotersData.ipname}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            ipname: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Important Person PH#
                    <div className="new_voter_data_input">
                      <input
                        type={"number"}
                        alt="not-visible"
                        placeholder={
                          module === "view"
                            ? "NA"
                            : "Enter Important Person PH#"
                        }
                        value={addnewvotersData.ipph}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            ipph: parseInt(e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Reference Name
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Reference Name"
                        }
                        value={addnewvotersData.refname}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            refname: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Reference VID
                    <div className="new_voter_data_input">
                      <input
                        type={"number"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Reference VID"
                        }
                        value={addnewvotersData.refvid}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            refvid: parseInt(e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Reference Phone No
                    <div className="new_voter_data_input">
                      <input
                        type={"number"}
                        alt="not-visible"
                        placeholder={
                          module === "view" ? "NA" : "Enter Reference Phone No"
                        }
                        value={addnewvotersData.refph}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            refph: parseInt(e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Notes
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={module === "view" ? "NA" : "Enter Notes"}
                        value={addnewvotersData.notes}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            notes: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main">
                    Count
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder={module === "view" ? "NA" : "Enter Count"}
                        value={addnewvotersData.count}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            count: parseInt(e.target.value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main  voter_info_new_voter_main_occupation">
                    Occupation
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder="Enter your Occupation"
                        value={addnewvotersData.occupation}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            occupation: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="voter_info_new_voter_main voter_info_new_voter_main_education">
                    Education
                    <div className="new_voter_data_input">
                      <input
                        type={"text"}
                        alt="not-visible"
                        placeholder="Enter your Education"
                        value={addnewvotersData.education}
                        disabled={disabledropdowns}
                        onChange={(e) =>
                          setAddnewvotersData({
                            ...addnewvotersData,
                            education: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>


                </div>
              )}

              {/* {module == "" ? null : (
                <div className="">
                {disabledropdowns ? null : (
                  <button
                    disabled={disabledropdowns}
                    style={{ display: Saveandnew ? "none" : "block" }}
                    onClick={handleClickedSaveandNew}
                    className="add_voters_popup_savenew_btn"
                  >
                    Save & New
                  </button>
                )}
              </div>
                )}  */}

              <div className="d-flex buttons_holder_div_addvoters d-flex align-items-center justify-content-center buttons_holder_div_addvoters_view_voters">
                {disabledropdowns ? null : (
                  <button
                    disabled={disabledropdowns}
                    style={{ display: Saveandnew ? "none" : "block" }}
                    onClick={handleClickedSaveandNew}
                    className="add_voters_popup_savenew_btn"
                  >
                    Save & New
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* End  add new voter popup*/}

      {voteraddedPopup ? (
        <div className="voterAddedpopup_newvoters_page d-flex align-items-center justify-content-center">
          voter added successfully
        </div>
      ) : null}

      {selectedvoter ? (
        <div>
          <div className="delete_popup_votersdata d-flex align-items-center justify-content-center"></div>
          <div className="delete_popup_voters_subDiv">
            <div className="delete_popup_voters_innerDiv">
              <div className="delete_popup_voters_data_delete_text_div">
                <span>Do you want to delete this User details?</span>
                <button
                  className="delete_voters_dismiss"
                  onClick={handlecancleDeletevoter}
                >
                  Dismiss
                </button>
                <button
                  className="delete_voters_continue"
                  onClick={handledeleteVoter}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default AddVoterspopup;
