import React, { useState } from 'react'
import './Topnav.scss'
import toggle from '../../Assets/togglebtn.png'
import SignOutPopup from '../Popup/Popup';
import profile from '../../Assets/profilelogo.png'
import lock from '../../Assets/locknav.png'
import calender from '../../Assets/calendar.png'
import notifaction from '../../Assets/notification.png'
import { NavLink } from 'react-router-dom';
import HumanLogo from '../../Assets/HumanLogo.png';
import Vector from '../../Assets/Vector.png';
import Popup from 'reactjs-popup';
import { Link, useNavigate } from 'react-router-dom';
// import logout  from '../../Service/service';
import { loginUser, logout } from '../../Service/service';

import { Modal, Button } from 'react-bootstrap';

function Topnav({ showSideNav, hideSideNav, closenav,toggleSideNav }) {

  
  const navigate = useNavigate();
  const logOut = () => {
    logout().then(res => {
      localStorage.removeItem("data");
      navigate("/login");
    }
    );
  }

 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  return (
    <div className='topnav_main_div'>
      {/* <img className='nav_top_togglebtn' src={toggle} alt='not_visible' onClick={() => { closenav ? hideSideNav() : showSideNav() }}/> */}
      <div className='right_align_icons'>
      <span style={{textAlign:'center',padding:'15px',color:'#046a38',fontWeight:'800'}} className="Admin_dashboard_text">iToConnect </span>
{/*   
        <NavLink className="notificationlink">
          <img className='notifaction_logo' src={notifaction} alt='not-visible' />
        </NavLink>
        <NavLink className="calendarlink" to={'/add-calender'}>
          <img className='calender_logo' src={calender} alt='not-visible' />
        </NavLink>
         */}
        {/* <SignOutPopup /> */}
        {/* <Button className='signout_logo_lock' onClick={handleShow}>
        </Button> */}
        <span  onClick={handleShow} style={{position:'absolute',right:'-20px',fontSize:'30px',top:'25%'}}>
        <i class="fa fa-sign-out" aria-hidden="true"></i>
        </span>
        <Modal show={show} onHide={handleClose} className="mobile_view_pop" >
          <Modal.Body className="mobile_view_pop">Are you sure, you want to sign out?</Modal.Body>
          <Modal.Footer className='modal_footer_signout'>
            <Button className="signout_no" onClick={handleClose} >
              No
            </Button>
            <Button className="signout_yes" onClick={logOut} >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* <img className='signout_logo_lock' src={lock} alt='not-visible' /> */}
      </div>

    </div>
  )
}

export default Topnav