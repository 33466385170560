import "./Signup.scss";
import Emslogo from "../../src/Assets/itoclogo.png";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import {
  getConstituencyDataForMLAorMP,
  getDistrictData,
  getStateAndRoleData,
  signUp,
} from "../Service/service";
import Select from "react-select";
function Signup({ history }) {
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
    setValue,
  } = useForm();

  const [selectedState, setSelectedState] = useState("");
  const [selectedUsertype, setSelectedUsertype] = useState("");
  const [selectedDist, setSelectedDist] = useState("");
  const [selectedConstituency, setSelectedConstituency] = useState("");
  const [email, setEmail] = useState("");

  const [dropdownData, setdropdownData] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtData, setdistrictData] = useState([]);
  const [constituencyId, setconstituencyId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [constituencyData, getconstituencyData] = useState([]);
  const [apiResponce, setapiResponce] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [roleOptions, setRoleOptions] = useState([]);
  const [isEmailAlreadyRegistered, setIsEmailAlreadyRegistered] =
    useState(false);
  const [phoneErrMessage, setPhoneErrMessage] = useState("");
  const [isPhoneAlreadyRegistered, setIsPhoneAlreadyRegistered] =
    useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    password: "user",
    phone_no: "",
    role: "",
    dist: "",
    state: "",
    constituency: "",
    country: "1",
  });

  localStorage.setItem("local_email_id", apiResponce.email);

  const otpdata = localStorage.getItem("local_email_id");

  let first_name = useRef();
  let last_name = useRef();
  let email_id = useRef();
  let state = useRef();
  let dist = useRef();
  let phone_no = useRef();
  let constituency = useRef();
  let role = useRef();

  var enterData = {};
  /**************on blur listeners ************** */
  const firstNameListener = () => {
    trigger("first_name");
    var firstname = document.getElementById("first_name");
    if (!!firstname.value) {
      firstname.value =
        firstname.value[0]?.toUpperCase() + firstname.value.slice(1);
    }
  };
  const lastNameListener = () => {
    trigger("last_name");
    var lastname = document.getElementById("last_name");
    if (!!lastname.value) {
      lastname.value =
        lastname.value[0]?.toUpperCase() + lastname.value.slice(1);
    }
  };
  const emailListener = (e) => {
    setIsEmailAlreadyRegistered(false);
    trigger("email_id");
    var emailId = document.getElementById("email_id");

    emailId.value = emailId.value.toLowerCase();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const getUserData = async () => {
    getStateAndRoleData().then((json) => {
      const roleForFilterdOptions = json.user_type.filter((item) => {
        if (item.user_type === "MLA" || item.user_type === "MP") {
          return item;
        }
      });
      const roleForOptions = roleForFilterdOptions.map((item) => {
        return { value: item.user_type_id, label: item.user_type };
      });
      const stateOptions = json.states.map((item) => {
        return { value: item.state_id, label: item.state_name };
      });
      setdropdownData(stateOptions);
      setRoleOptions(roleForOptions);
    });
  };

  const onClickErrorMessage = (id, elementId) => {
    const getState = id;
    const stateErrorEl = document.getElementById(elementId);
    if (getState !== "") {
      if (stateErrorEl) {
        stateErrorEl.style.display = "none";
      }
    } else {
      stateErrorEl.style.display = "block";
    }
  };
  const handleRole = (event, item) => {
    const getRole = event.value;
    setRoleId(getRole);
    setValue("user_type_id", event.value);
    onClickErrorMessage(event, "roleError");
  };
  const handleState = (event, item) => {
    const getState = event.value;
    setStateId(getState);
    setValue("state", event.value);
    onClickErrorMessage(event, "stateError");
  };

  const handledistrict = (event, item) => {
    const getdistrictId = event.value;
    setDistrictId(getdistrictId);
    setValue("dist", event.value);
    onClickErrorMessage(event, "districtError");
  };
  const handleconstituency = (event, item) => {
    const getconstituencyId = event.value;
    setconstituencyId(getconstituencyId);
    setValue("constituency", event.value);
    onClickErrorMessage(event, "constituencyError");
  };

  const getdistrictData = async () => {
    if (!!stateId) {
      getDistrictData(stateId).then((dist) => {
        const distOptions = dist.map((item) => {
          return { value: item.dist_id, label: item.dist_name };
        });
        setdistrictData(distOptions);
      });
    }
  };

  const getConstituencyData = async () => {
    getConstituencyDataForMLAorMP(roleId, districtId).then((cons) => {
      const distOptions = cons.map((item) => {
        const constituency_name =
          roleId === 1 ? item.mla_constituency_name : item.mp_constituency_name;
        return { value: item.constituency_id, label: constituency_name };
      });
      getconstituencyData(distOptions);
    });
  };

  const stateSelectListener = (e, item) => {
    // handleState(item.state_id);
    setSelectedState(e.target.innerText);
  };
  const userTypeSelectListener = (e, value) => {
    // handleRole(value.role_id);
    setSelectedUsertype(e.target.innerText);
  };
  const districtSelectListener = (e, value) => {
    // handledistrict(value.dist_id);
    setSelectedDist(e.target.innerText);
  };
  const constituencySelectListener = (e, value) => {
    // handleconstituency(value.constituency_id);
    setSelectedConstituency(e.target.innerText);
  };

  // Api call code

  // const onSubmit = (data) => {
  //   enterData = getValues();
  //   enterData.password = "user";
  //   enterData.country = "1";
  //   setFormData(enterData);

  //   // setTimeout(window.location.href="otp",5000)
  //   signUp(enterData)
  //     .then((apiData) => {
  //       // debugger;
  //       setapiResponce(apiData);
  //       if (apiData.message === "Account already exist, if forgot password please click on Forgot password" || apiData.message === "Phone validation completed, set your password from the link send to your Email.") {
  //         setErrMessage(apiData.message);
  //         setIsEmailAlreadyRegistered(true);
  //         setIsPhoneAlreadyRegistered(false);
  //         // setTimeout((window.location.href = "/otp"),5000);
  //       } else if (apiData.message === "Phone number exists, please use some other number" || apiData.message === "Phone number exists with other user, please use other number or already given number" || apiData.message === "Phone number used is linked with an existing account. Use a different phone number to create a new account.") {
  //         // Phone number exists, please use some other number
  //         setPhoneErrMessage(apiData.message);
  //         setIsPhoneAlreadyRegistered(true);
  //         setIsEmailAlreadyRegistered(false);
  //       }
  //       else {
  //         window.location.href = `/otp/?${enterData.email_id}`;
  //       }
  //     })
  // };

  const onSubmit = (data) => {
    enterData = getValues();
    // enterData.password = "user";
    enterData.country = "1";

    // Convert the email to lowercase before submitting
    enterData.email_id = enterData.email_id.toLowerCase();

    setFormData(enterData);

    signUp(enterData).then((apiData) => {
      setapiResponce(apiData);
      if (
        apiData.message ===
          "Account already exist, if forgot password please click on Forgot password" ||
        apiData.message ===
          "Phone validation completed, set your password from the link send to your Email."
      ) {
        setErrMessage(apiData.message);
        setIsEmailAlreadyRegistered(true);
        setIsPhoneAlreadyRegistered(false);
      } else if (
        apiData.message ===
          "Phone number exists, please use some other number" ||
        apiData.message ===
          "Phone number exists with other user, please use other number or already given number" ||
        apiData.message ===
          "Phone number used is linked with an existing account. Use a different phone number to create a new account."
      ) {
        setPhoneErrMessage(apiData.message);
        setIsPhoneAlreadyRegistered(true);
        setIsEmailAlreadyRegistered(false);
      } else {
        window.location.href = `/otp/?${enterData.email_id}`;
      }
    });
  };

  useEffect(() => {}, [phoneErrMessage]);

  useEffect(() => {
    getdistrictData();
  }, [stateId]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (roleId && !!districtId !== false) {
      getConstituencyData();
    }
  }, [roleId, districtId]);

  useEffect(() => {
    document.getElementById("first_name").value = "";
    document.getElementById("last_name").value = "";
    document.getElementById("email_id").value = "";
    document.getElementById("phone_no").value = "";
    // document.getElementById("role").value = "";
    // document.getElementById("state").value = "";
    // document.getElementById("dist").value = "";
    // document.getElementById("constituency").value = "";
  }, [history]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#F3F7F9",
      backgroundImage: "none",
      height: "2.65625vw",
      border: "none",
      minHeight: "0px",
      fontSize: "0.9375vw",
      span: {
        display: "none",
      },
      svg: {
        color:'#AEAEAE',
        verticalAlign:'middle',
        marginRight:'1.5vw',
        
      },
      // Customize the arrow icon
      // "&:after": {
      //   content: '""',
      //   display: "inline-block",
      //   width: "0",
      //   height: "0",
      //   backgroundColor:'red',
      //   verticalAlign: "middle",
      //   borderTop: "6px solid #000",
      //   borderRight: "4px solid transparent",
      //   borderBottom: "0",
      //   borderLeft: "4px solid transparent",
      //   position: "absolute",
      //   right: "0.9375vw",
      // },
      
    }),
    menu: (provided) => ({
      ...provided,
      width: '35.011vw', 
    //   display: "inline-block",
    // overflow:" hidden",
    // whiteSpace: "nowrap",
      
      // Set the desired width here
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontWeight: "500",
      fontSize: "0.9375vw",
      color: "#8b93a5",
    }),
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      borderBottom: "1px solid #D9D8D8",
      color: "#263659",
      fontSize: "0.9375vw",
      "&:last-child": {
        borderBottom: "none",
      },
    }),

    "option:last-child": () => ({}),
  };

  return (
    <div className="signupcontainer" id="signup"
    // style={{background: 'linear-gradient(153deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 80.21%)',height:'100vh'}}
    // style={{background:'linear-gradient(123deg, #ffffff 50%, rgb(255, 103, 31) 150%)',marginTop:'2rem'}}
    >
      {/* <div className="signup_emslogo">
        <img src={Emslogo} alt="Waiting..." />
      </div> */}
      {/* <div className="signup_emslogo">
      <span style={{color:'green',fontSize:'30px',fontWeight:'800',textAlign:'center'}}>ItoConnect</span>
      </div> */}
      <div className="signup__main">
        <form className="signup__sub" onSubmit={handleSubmit(onSubmit)}>
          <div className="heading">
            {/* <span className="hrline1" /> */}
            <h3>Sign Up</h3>
            {/* <span className="hrline2" /> */}
          </div>
          <p>First Name</p>
          <div className="signup__sub_inputBlock">
            <input
              placeholder="First Name"
              type={"text"}
              id="first_name"
              tabIndex="1"
              name="first_name"
              ref={first_name}
              {...register("first_name", {
                required: "First Name required",
                pattern: {
                  value: /^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/,
                  message: "Allows alphabets only",
                },
              })}
              onBlur={firstNameListener}
            />
          </div>
          {errors.first_name && (
            <p className="errormessage"> {errors.first_name.message}</p>
          )}

          <p>Last Name</p>
          <div className="signup__sub_inputBlock">
            <input
              placeholder="Last Name"
              type="text"
              tabIndex="2"
              id="last_name"
              name="last_name"
              ref={last_name}
              {...register("last_name", {
                required: "Last Name required",
                pattern: {
                  value: /^[a-zA-Z]+(([a-zA-Z ])?[a-zA-Z]*)*$/,
                  message: "Allows alphabets only",
                },
              })}
              onBlur={lastNameListener}
            />
          </div>
          {errors.last_name && (
            <p className="errormessage"> {errors.last_name.message}</p>
          )}

          <p>Email ID</p>
          <div className="signup__sub_inputBlock">
            <input
              placeholder="Email"
              type="text"
              tabIndex="3"
              id="email_id"
              name="email_id"
              // value={email} // Use the state value as the input field value
              {...register("email_id", {
                required: "Email required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid mail Id",
                },
              })}
              // onChange={handleEmailChange} // Call the event handler on input change
              onBlur={emailListener}
            />
          </div>
          {errors.email_id && (
            <p className="errormessage"> {errors.email_id.message}</p>
          )}
          {isEmailAlreadyRegistered && (
            <p className="errormessage"> {errMessage}</p>
          )}
          <p id="emailError"></p>
          <div className="grid-container">
            <div className="grid-item ">
              <p>Phone Number</p>
              <div className="signup_phonenumberBlock itoconnect-phoneblock">
                <span className="signup_phonenumberBlockCountrycode">+91</span>
                <input
                  className="signup_phonenumberBlock_input"
                  type="text"
                  tabIndex="4"
                  id="phone_no"
                  placeholder="Phone Number"
                  name="phone_no"
                  ref={phone_no}
                  // onChange={(e)=>handleChange(e)}
                  {...register("phone_no", {
                    required: "Phone Number required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Allows 10 digits only",
                    },
                  })}
                  onBlur={() => {
                    trigger("phone_no");
                  }}
                />
              </div>
              {errors.phone_no && (
                <p className="errormessage" id="errormessage">
                  {" "}
                  {errors.phone_no.message}
                </p>
              )}
              {isPhoneAlreadyRegistered && (
                <p className="errormessage"> {phoneErrMessage}</p>
              )}
            </div>
            <div className="grid-item">
              <p className="extra__text">User Type</p>
              <div className="signup_usertypeBlock">
                <Select
                  tabIndex="5"
                  isSearchable={true}
                  escapeClearsValue={true}
                  className="react_select_role"
                  styles={customStyles}
                  options={roleOptions}
                  classNamePrefix="select_role"
                  {...register("user_type_id", {
                    required: true,
                  })}
                  onChange={(e) => {
                    handleRole(e);
                  }}
                  placeholder="User Type"
                />
              </div>
              {errors.role && (
                <p className="usertypeerrormessage" id="roleError">
                  Role required
                </p>
              )}
            </div>
            <div className="grid-item sigup_select_state">
              <p>Select State</p>
              <div className="signup_stateBlock">
                <Select
                  tabIndex="6"
                  className="signup_stateBlock_dropdown"
                  styles={customStyles}
                  options={dropdownData}
                  classNamePrefix="select_role"
                  {...register("state", {
                    required: true,
                  })}
                  onChange={(e) => {
                    handleState(e);
                  }}
                  placeholder="State"
                />
              </div>
              {errors.state && (
                <p className="errormessage" id="stateError">
                  State required
                </p>
              )}
              {/* <p id="stateError"></p> */}
            </div>

            <div className="grid-item sigup_select_ditrict">
              <p className="extra__text">Select District</p>
              <div className="signup_DistrictBlock">
                <Select
                  tabIndex="7"
                  styles={customStyles}
                  options={districtData}
                  classNamePrefix="select_role"
                  {...register("dist", {
                    required: true,
                  })}
                  onChange={(e) => {
                    handledistrict(e);
                  }}
                  placeholder="District"
                />
              </div>
              {errors.dist && (
                <p className="errormessage" id="districtError">
                  District required
                </p>
              )}
              {/* <p id="districtError"></p> */}
            </div>

            <div className="grid-item">
              <p>Select Constituency</p>
              <div className="signup_ConstituencyBlock">
                <Select
                  tabIndex="8"
                  styles={customStyles}
                  options={constituencyData}
                  classNamePrefix="select_role"
                  {...register("constituency", {
                    required: true,
                  })}
                  onChange={(e) => {
                    handleconstituency(e);
                  }}
                  placeholder="Constituency"
                />
              </div>
              {errors.constituency && (
                <p className="errormessage" id="constituencyError">
                  Constituency required
                </p>
              )}
              <p id="constituencyError"></p>
            </div>

            <div className="grid-item"></div>
          </div>
          <div className="signupbutton__main">

            <button
              className="signup__button"
              type="submit"
              id="signup"
              tabIndex="9"
            >
              Sign Up
            </button>
            
            <div className="signin__text">
              <span className="sing_text_title">
          Already have an account?{" "}
          <br/>
          <span className="sign_text_loginlink">
            <Link to="/login">
              <b>Log In </b>
            </Link>
          </span>
          </span>
            </div>
            
          </div>

          {/* <div className="signupbutton__main">
            <span></span>
            <p className="bottom__text" style={{ marginLeft: "0" }}>
              By clicking “Sign Up” , you agree to EMS
               <br />
              <span style={{ color: "red" }}>
                Terms and Conditions
              </span> and <span style={{ color: "red" }}>Privacy Policy</span>.
            </p>
          </div> */}

      



        </form>
      </div>
      {/* <div className="signin__text">
        <p>
          Already have an account?{" "}
          <span>
            <Link to="/login">
              <b>Log In </b>
            </Link>
          </span>
        </p>
      </div> */}
      {/* <div className="copyright">© 2023 EMS ----- All Right Reserved</div> */}
    </div>
  );
}

export default Signup;
