
export const user = {
    super_admin: 1,
    mp: 2,
    mla: 3,
    pa:4,
    admin_staff:5,
    volunteer:6
  };
  
// export const surveyIds = {
//     healthQuestionnaire: 1,
//     checkin: 2,
//   };
  
// export const collectorIds = {
//     questionnaire: 1,
//     checkin: 2,
//   };
  
// export const choiceIds = {
//     healthQuestionnaire: {
//       maleGender: 1,
//     },
//     checkin: {},
//   };
  