import axiosInstance from "./AxiosInstance";

const url  = process.env.REACT_APP_SERVER_CONFIG;

export const getBirthdayData = async (filterValue) => {
    const values = `?filters=${filterValue}`
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const birthdayResponse = await axiosInstance.get(`/dashboard/birthday/${filterValue?values:''}`);
    return birthdayResponse.data;

    // if(filterValue){
    //     formattedUrl = `${url}/dashboard/birthday/?filters=${filterValue}`
    // }
    // const tokenData = JSON.parse(localStorage.getItem("data"));
    // const birthdayResponse = await axiosInstance.get(formattedUrl);
    // return birthdayResponse.data;
    // const birthdayResponse = await fetch(formattedUrl, {
    //     method: "GET",
    //     headers: {
    //         Authorization: `Bearer ${tokenData.token}`,
    //     },
    // });
    // if (birthdayResponse.status !== 404) {
    //     return birthdayResponse.json();
        
    // }
};

export const getBirthdayDataWithPagination = async (url) => {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const birthdayResponse = await axiosInstance.get(url);
    return birthdayResponse.data;
    // const birthdayResponse = await fetch(url, {
    //     method: "GET",
    //     headers: {
    //         Authorization: `Bearer ${tokenData.token}`,
    //     },
    // });
    // if (birthdayResponse.status !== 404) {
    //     return birthdayResponse.json();
    // }
};

export const getBirthdayFilters = async () => {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const birthdayResponse = await axiosInstance.get('/dashboard/votertype/');
    return birthdayResponse.data;
    // const birthdayResponse = await fetch(`${url}/dashboard/votertype/`, {
    //     method: "GET",
    //     headers: {
    //         Authorization: `Bearer ${tokenData.token}`,
    //     },
    // });
    // if (birthdayResponse.status !== 404) {
    //     // return birthdayResponse.json();
    //     return birthdayResponse.data;

    // }
};