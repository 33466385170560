import { urlData } from "../config/apiconfig";
import axiosInstance from "./AxiosInstance";


export async function getissues(dropdownvalue) {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const resp = await axiosInstance.get(`${urlData.wradvillagegetissues}?${dropdownvalue==''?'':dropdownvalue}`)
    return resp.data;
}

export async function importantpeopleApi() {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const resp = await axiosInstance.get(`${urlData.impPeopleDashboardAPI}`)
    return resp.data;
}

export async function wardvillagedelete(issueid) {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const resp = await axiosInstance.delete(`${urlData.wardvillagedelete}${issueid}`)
    return resp.data;
}
export async function wardvillageissuesdropdown() {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const resp = await axiosInstance.get('/dashboard/dropdowndb/')
    return resp.data;
}

export async function wardvillageissuesMandal(selectedValues) {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const constituencies = selectedValues ? `?constituency_id=${selectedValues}` : ''
    const dashboard = await axiosInstance.get(`/dashboard/dropdowndb/${constituencies ? constituencies : ''}`)
    return dashboard.data;
}

export async function wardvillageissuesVillage(selectedmandalvalue) {
    const mandals = selectedmandalvalue ? `?mandal_id=${selectedmandalvalue}` : '';
    // const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(`/dashboard/dropdowndb/${mandals}`);
    return response.data;
}

export async function wardvillageissuesPollingStation(selectedvillagevalue) {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const villages = selectedvillagevalue ? `?ward_village_id=${selectedvillagevalue}` : ''
    const response = await axiosInstance.get(`/dashboard/dropdowndb/${villages}`);
    return response.data;
}

export async function AddissuesApicall(datapayload) {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.post(`${urlData.wradvillagegetissues}`,datapayload);
    return response.data;
}

export async function AddissuesApicallPatch(datapayload) {
    const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.patch(`${urlData.wradvillagegetissues}`,datapayload);
    return response.data;
}

