import { json } from "sjcl";
import { urlData } from "../config/apiconfig";
import Voterjson from '../../src/Assets/response.json'
import axiosInstance from "./AxiosInstance";


const url = process.env.REACT_APP_SERVER_CONFIG;

export async function votersData(currentPage, constituenciesvotersdata, mandalsvotersdata, villagesvotersdata, pollingstationsvotersdata) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const resp = await axiosInstance.get(`${urlData.votersdataDashboard}${urlData.votersdataPage}${currentPage}&${constituenciesvotersdata?constituenciesvotersdata:''}${mandalsvotersdata?mandalsvotersdata:''}${villagesvotersdata?villagesvotersdata:''}${pollingstationsvotersdata?pollingstationsvotersdata:''}`)
  return resp.data;
  // const resp = await fetch(`${url}${urlData.votersdataDashboard}${urlData.votersdataPage}${currentPage}&${constituenciesvotersdata?constituenciesvotersdata:''}${mandalsvotersdata?mandalsvotersdata:''}${villagesvotersdata?villagesvotersdata:''}${pollingstationsvotersdata?pollingstationsvotersdata:''}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  // const voters = resp.json();
  // return voters;
}

export async function votersDataConstituency() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const resp = await axiosInstance.get('/dashboard/dropdowndb/')
  return resp.data;
  
  
  // const dashboard = await fetch(`${url}/dashboard/dropdowndb/`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  // const data = dashboard.json();
  // return data;
}

export async function votersDataMandal(selectedConstituencies) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const constituencies = selectedConstituencies ? `?constituency_id=${selectedConstituencies}` : ''
  const dashboard = await axiosInstance.get(`/dashboard/dropdowndb/${constituencies ? constituencies : ''}`)
  return dashboard.data;
  // const dashboard = await fetch(`${url}/dashboard/dropdowndb/${constituencies ? constituencies : ''}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  // const data = dashboard.json();
  // return data;
}


export async function votersDataVillage(selectedmandals) {
  const mandals = selectedmandals ? `?mandal_id=${selectedmandals}` : '';
  // const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`/dashboard/dropdowndb/${mandals}`);
  return response.data;

  // const dashboard = await fetch(`${url}/dashboard/dropdowndb/${mandals}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  // const data = dashboard.json();
  // return data;
}

export async function votersDatapollingStation(selectedvillages) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const villages = selectedvillages ? `?ward_village_id=${selectedvillages}` : ''
 
   const response = await axiosInstance.get(`/dashboard/dropdowndb/${villages}`);
  return response.data;

  // const dashboard = await fetch(`${url}/dashboard/dropdowndb/${villages}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  // const data = dashboard.json();
  // return data;
}

// export async function csvconverter(csvbody) {
//   const tokenData = JSON.parse(localStorage.getItem("data"));

//   const resp = await fetch(`${url}/dashboard/generatecsv/`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${tokenData.token}`,
//     },
//     body: JSON.stringify(csvbody),
//   });
//   return resp.blob()
// }

export async function csvconverter(csvbody) {
  try {
    const resp = await axiosInstance.post(`/dashboard/generatecsv/`, csvbody, {
      responseType: 'blob', 
    });
    return resp.data;
  } catch (error) {
    console.error('Error generating csv:', error);
    throw error;
  }
}

// export async function pdfconverter(pdfbody) {
//   // const tokenData = JSON.parse(localStorage.getItem("data"));
//   console.log('pdf console', pdfbody)
//   const resp = await axiosInstance.post('/dashboard/generatepdf/',pdfbody);
//   return resp.blob();
  // const resp = await fetch(`${url}/dashboard/generatepdf/`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  //   body: JSON.stringify(pdfbody),
  // });
  // console.log(JSON.stringify(pdfbody));
  // return resp.blob()
// }



export async function pdfconverter(pdfBody) {
  try {
    const resp = await axiosInstance.post('/dashboard/generatepdf/', pdfBody, {
      responseType: 'blob', 
    });
    return resp.data;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
}


export async function deleteVoter(selectedvoter) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`/dashboard/deletevoter/?voter_id=${selectedvoter}`);
  return response.data;
  

  // const resp = await fetch(`${url}/dashboard/deletevoter/?voter_id=${selectedvoter}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  const voters = response.json();
  return voters;
}

export async function votersDataColumnselection() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`${urlData.votersdataColumnselection}`);
    return response.data;
  // const response = await fetch(`${url}${urlData.votersdataColumnselection}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  // const data = response.json();
  // return data;

}

export async function updateCheckedvalues(selectedFields) {

  // const tokenData = JSON.parse(localStorage.getItem("data"));
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.post(`${urlData.columnsstoring}`,selectedFields);
  return response.data;


  // const response = await fetch(`${url}${urlData.columnsstoring}`, {
  //   method: 'POST',
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify(selectedFields)
  // });
  // const data = response.json();
  // return data;


}

export async function getupdateCheckedvalues() {
  const tokenData = JSON.parse(localStorage.getItem("data"));
    const response = await axiosInstance.get(`${urlData.columnsstoring}`);
  return response.data;

  // const response = await fetch(`${url}${urlData.columnsstoring}`, {
  //   method: 'GET',
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //     "Content-Type": "application/json",
  //   },
  // });
  // const data = response.json();
  // return data;

}

export async function searchvoter(search) {
  const tokenData = JSON.parse(localStorage.getItem("data"));
  const response = await axiosInstance.get(`${urlData.checkvoters}${search}`);
  return response.data;
 

  // const response = await fetch(`${url}${urlData.checkvoters}${search}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  
  const data = response.json();
  return data;
}

export async function searchvoterId(SearchtermvoterID) {
  const tokenData = JSON.parse(localStorage.getItem("data"));

  const response = await axiosInstance.get(`${urlData.searchvoters}${SearchtermvoterID}`);
  return response.data;

  // const response = await fetch(`${url}${urlData.checkvoters}${SearchtermvoterID}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  const data = response.json();
  return data;
}

export async function getvoterPrintList() {
  const tokenData = JSON.parse(localStorage.getItem("data"));

  const response = await axiosInstance.get(`https://test-api.itoconnect.online/sms/voterslip/`);
  // const response = await fetch(Voterjson); // Update the path accordingly

  return response.data;

  // const response = await fetch(`${url}${urlData.checkvoters}${SearchtermvoterID}`, {
  //   headers: {
  //     Authorization: `Bearer ${tokenData.token}`,
  //   },
  // });
  const data = response.json();
  return data;
}