import style from "./Otp.module.scss";
// import emslogo from "../Assets/emslogo.png";
import emslogo from "../../src/Assets/itoclogo.png";

import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import { resendOtp, verifyOtp } from "../Service/service";
import { Link, withRouter } from "react-router-dom";
import LoaderWrapper from '../components/LoaderWrapper';
// import { signuppost } from "./service/service";



const Otp = () => {
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
  } = useForm();

  const otpmail = window.location.search.substr(
    1,
    window.location.search.length
  );
 
  //let otp = useRef();
  var enterData;
  const [otpData, setotpData] = useState({
    email_id: "",
    otp: "",
  });
  const [isOTPAccepted, setIsOTPAccepted] = useState(false);
  const [isShowingAlert, setShowingAlert] = useState(false);
  const [classVal, setClassVal] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [isApiData, setApiData] = useState(true);
  const [isButtonInnerText, setButtonInnerText] = useState("Submit");
  const [isLoading,setIsLoading] = useState(false);
  

  const onSubmit = (data) => {
    enterData = getValues();
    enterData.email_id=otpmail
   
    
    setotpData({ email_id: otpmail, otp: enterData.otp });

    if(isApiData){
      setIsLoading(true);
      verifyOtp(enterData)
        .then((apiData) => {
          setApiData(!apiData.status)
          if (apiData.status) {
           
            setIsOTPAccepted(true);
            setClassVal("otpbody_box_sucessfullotp");
            setErrMessage("A link has been sent to your email address to create a password for logging in.");
            setButtonInnerText("Go To Login page");
            
            // setShowingAlert(true)
            // window.location.href = "/login";
          } else {
            setClassVal("otpbody_box_wrongotp");
            setErrMessage("Invalid OTP. Please check your code and try again.");
          }
          // setapiResponce(apiData);
        })
        .finally(()=>{
          setIsLoading(false);
        });
    }else{
      window.location.href = "/login";
      
    }
  };
  
  // resendOtp(otpmail);
  const sendTheOtpAgain = () => {
    resendOtp(otpmail).then((apiData)=>{
      if(apiData.status){
        setClassVal("otpbody_box_sucessfullotp");
        setErrMessage("A new OTP has been sent to your mobile number.");
      }
    });
  };
  useEffect(() => {
    const resendOtpBtn = document.querySelector(".resend");
    resendOtpBtn.addEventListener("click", sendTheOtpAgain);
    return () => {
      resendOtpBtn.removeEventListener("click", sendTheOtpAgain);
    };
  }, []);

  return (
    <div>
      {isLoading?
      <LoaderWrapper/>:null}
      <div className={`${style.otpmainBlock}`}>
        <div className={`${style.otplogo_block}`}>
          <img src={emslogo} />
        </div>
        <div className={`${style.otpbodyBlock}`}>
          <form
            className={`${style.signup__sub}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={`${style.otpbodyBlock_verifyotp}`}>
              {/* <p>VERIFY YOUR OTP</p> */}
            </div>
            <div className={`${style.otpbody_box}`}>
              <div className={`${style.otpbody_box_otpVerification}`}>
                {/* <hr  className={`${style.otpbody_box_otpVerificationhrline}`} /> */}
                <span
                  className={`${style.otpbody_box_otpVerificationhrline}`}
                ></span>
                <h3 className={`${style.otp_heading}`}>OTP Verification</h3>
                {/* <hr className={`${style.otpbody_box_otpVerificationhrline}`}/> */}
                <span
                  className={`${style.otpbody_box_otpVerificationhrline}`}
                ></span>
              </div>
              <input
                className={`${style.otpbody_box_enterotp}`}
                type="text"
                placeholder="Enter OTP"
                id="otp"
                name="otp"
                // ref={otp}
                {...register("otp", {
                  required: "Enter OTP",
                  pattern: {
                    value: /^[0-9]{6}$/,
                    // message: "Allows 6 digits only",
                  },
                })}
                onKeyUp={() => {
                  trigger("otp");
                }}
              />
              {errors.otp && (
                <p
                  id="nameAlert"
                  className={`${style.name_alert}`}
                  style={{
                    width: "100%",
                    color: "red",
                    fontSize: "0.7291666667vw",
                    marginTop: "1px",
                    marginLeft: "23.4vw",
                    marginBottom: "0.1vw"
                  }}
                >
                  {" "}
                  {errors.otp.message}
                  {/* A new OTP has been sent to your mobile number. */}
                </p>
              )}
              <p className={`${style[classVal]}`}>
                  {errMessage}
                </p>
              {/* {isOTPAccepted === false ? (
                <p className={`${style.otpbody_box_wrongotp}`}>
                  Invalid OTP. Please check your code and try again.
                </p>
              ) : (
                <p className={`${style.otpbody_box_sucessfullotp}`}>
                  A link has been sent to your email address to create a password for logging in.
                </p>
              )} */}
              <button className={`${style.otpbody_box_submit}`} id="getsubmit">
                {isButtonInnerText}
              </button>

              <div className={`${style.otpbody_box_resendotp}`}>
                <p className="resend" style={{ cursor: "pointer" }}>
                  <u color="red">Resend OTP</u>{" "}
                </p>
              </div>
            </div>
            <div className={`${style.otpbodyBlock_login}`}>
              
               <Link to="/login">
               <p style={{ cursor: "pointer" }}>Log in to Application</p>
            </Link>
            </div>
          </form>
        </div>
        <div className={`${style.otp_Emptyblock}`}></div>
        <div className={`${style.otp_footerBlock}`}>
          <p>2023 iToconnect --- - All Right Reserved</p>
        </div>
      </div>
    </div>
  );
};
export default Otp;
