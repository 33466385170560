import React, { useEffect, useState } from "react";
import "./Login.css";
import logo from "../../src/Assets/itoclogo.png";
import footertext from "../Assets/loginfootertext.png";
import Eye from "../Assets/Eye.png";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import EyeOpen from "../Assets/EyeOpen.png";
import L from 'leaflet'; // Import Leaflet library

import { loginUser } from "../Service/service";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import { decode } from "base-64";
import axios from 'axios';

function Loginpage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState(decode(getCookie("emailid")));
  const [password, setPassword] = useState(decode(getCookie("password")));
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [errorresp, setErrorresp] = useState();
  const customIcon = new L.Icon({
    iconUrl: 'https://cdn.pixabay.com/animation/2023/04/06/16/10/16-10-43-442_512.gif', // Replace with the path to your custom icon image
    iconSize: [75, 75], // Set the icon size [width, height]
    iconAnchor: [16, 32], // Set the anchor point [horizontal, vertical] - the point on the icon that should coincide with the marker's geographical location
    popupAnchor: [0, -32], // Set the popup anchor [horizontal, vertical] - the point on the icon that should be aligned with the popup's tip
  });
  const nav = useNavigate();
  const [locationTracker, setLocationTracker] =  useState();
  const [isLocationPermissionGranted, setLocationPermissionGranted] = useState(false);

  useEffect(() => {
    console.log('Fetching location...');
    const watchId =  navigator.geolocation.getCurrentPosition(
      async (position) => {
        console.log('Location fetched successfully:', position);
        // const { latitude, longitude } = position.coords;
        console.log('position.coords', position.coords);
        console.log('latitude', position.coords.latitude);
        console.log('longitude', position.coords.longitude);
        // const locationData = {
        //   user_id: 19,
        //   latitude: position.coords.latitude,
        //   longitude: position.coords.longitude,
        // };
        setLocationPermissionGranted(true);
        setLocationTracker({ lat: position.coords.latitude, lng: position.coords.longitude });

        // Make a POST request to the API endpoint
        // const response = await axios.post(
        //   'https://aum2086ycf.execute-api.us-east-1.amazonaws.com/dev/updatelocation',
        //   locationData
        // );

      },
      (error) => {
        console.error('Error getting location:', error.message);
        alert('Location access is required to use this application.');
        setLocationPermissionGranted(false);

      }
    );
    return () => navigator.geolocation.clearWatch(watchId);

  }, []);
  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const signupPage = () => {
    nav("/signup");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email) {
      setEmailError("Email required.");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email is invalid.");
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password required.");
    } else {
      setPasswordError("");
    }

    if (email && password) {
      loginUser(email, password, rememberme).then((data) => {
        const errMessageElement =
          document.getElementsByClassName("errMessage")[0];
        errMessageElement.style.display = "inline";
        errMessageElement.style.color = "red";
        errMessageElement.innerText = data.message ? data.message : "";
      });
    }
  };

  const [rememberme, setRememberme] = useState(false);
  const handleRememberme = () => {
    setRememberme(!rememberme);
  };

  // useEffect(() => {
  //   // setEmail(getCookie("email"));
  //   // setPassword(getCookie("password"));
  // });
  console.log('Location:', locationTracker);

  if (!isLocationPermissionGranted) {
    return (
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p>Location access is required to use this application. Please grant permission.</p>
      </div>
    );
  }
  if (isLocationPermissionGranted) {
  return (
    <>
            <div className="App">
    {locationTracker && (
      <MapContainer center={locationTracker} zoom={15} style={{ height: '150px', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={locationTracker} icon={customIcon}>
          <Popup>Your Location</Popup>
        </Marker>
      </MapContainer>
    )}
  </div>
      <div className="ems_login_page_main_div" 
      style={{background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #93C7F7 99.99%)'}}
      // style={{background:'linear-gradient(123deg, #ffffff 50%, rgb(255, 103, 31) 150%)',marginTop:'2rem'}}
      >
        <div className="ems_login_logo_div d-flex justify-content-center align-items-center" style={{marginTop:'1rem'}}>
          <img className="login_emslogo" src={logo} alt="not visible" />
        </div>
        <div className="d-flex justify-content-center align-items-center itoconnect_logoname">
        <span 
        // style={{color:'green',fontSize:'30px',fontWeight:'800'}}
        >iToconnect</span>
        </div>

        <div className="login_page_body_main_div d-flex justify-content-center align-items-center">
          <div className="login_page_body_sub_div d-flex flex-column justify-content-start">
            <div className="d-flex horizontal_login_line">
              <div className="horizontal_line_login"></div>
              <span className="ems_login_text">Login</span>
              <div className="horizontal_line_two_login"></div>
            </div>
            <br></br>
            <form onSubmit={handleSubmit}>
              <div>
                {/* <label className="loginpage_email_label">Email</label> */}
                <div className="login_email_input_div d-flex justify-content-center">
                  <input
                    type="email"
                    style={{border:'1px solid #0047B2'}}

                    placeholder="Enter Email"
                    className="login_page_input_fields"
                    value={email}
                    onChange={(event) =>
                      setEmail(event.target.value.toLowerCase())
                    }
                    required
                  />
                </div>
                {emailError && <div className="error">{emailError}</div>}
              </div>
              <br></br>
              <div className="pwd">
                {/* <label className="loginpage_email_label">Password</label> */}
                <div className="login_email_input_div d-flex justify-content-center">
                  <input
                    //  type="password"
                    style={{border:'1px solid #0047B2'}}

                    className="login_page_input_fields"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                    type={isRevealPwd ? "text" : "password"}
                  />
                  <img
                    className="eye"
                    alt=""
                    title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? EyeOpen : Eye}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                </div>

                {passwordError && <div className="error">{passwordError}</div>}
              </div>
              <span className="errMessage"></span>
              {/* <div className="d-flex align-items-center remember_me_main_div">
                <input
                  type="checkbox"
                  className="remember_me_checkbox"
                  onClick={handleRememberme}
                  checked={rememberme}
                  style={{border:'1px solid orange'}}
                />

                <span className="login_page_rememberme">Remember me</span>
              </div> */}
              <div className="loginpage_foregt_password_main_div">
              <a
                className="forgetpassword_anchortag_element"
                href="/forgotpassword"
              >
                <span className="loginpage_forgetpassworddiv">
                  <u>Forgot Password ?</u>
                </span>
              </a>
              {/* <div className="loginpage_forgetpassword_underline_div"></div> */}
            </div>
            <br></br>
              <button
                className="loginpage_login_button"
                type="submit"
                onClick={handleSubmit}
              >
                Login
              </button>
            </form>

            <div className="privacy_policy_div d-flex justify-content-between">
              <span className="Donthaveanaccount_loginpage">
                Don’t have an account?{" "}
              </span>
              
              {/* <a href="/signup" id="href" className="privacy_policy_text">
                Sign Up
              </a> */}
            </div>
            <div>
            <a href="/signup" id="href" className="privacy_policy_text">
                Sign Up
              </a>
            </div>
            
            {/* <br/> */}
          </div>
        </div>

        {/* <div className="bottom_white_space_div"></div>
        <div className="bottom_end_footer_login d-flex justify-content-center align-items-center">
          <img
            className="footer_end_text_login"
            src={footertext}
            alt="not-visible"
          />
          <span className="footer_end_text_terms">Terms & Conditions</span>
          <p className="privacys">Privacy Policy</p>
        </div> */}
      </div>
    </>
  );
 }
}

export default Loginpage;
