import { ImportConstants } from '../Pages/ImportData/ImportConstants';
import { urlData } from '../config/apiconfig';
import axios from 'axios';
import  axiosInstance  from './AxiosInstance';  

export default class ImportDataService {
    constructor() {
        if(this.tokenData){
            this.headerConfig = {
                headers: {
                  'Authorization': 'Bearer ' + this.tokenData.token
                }
              }
        }
    }
    
    tokenData = JSON.parse(localStorage.getItem("data"));
    headerConfig = {};
    serverURL = process.env.REACT_APP_SERVER_CONFIG;
    // serverURL = process.env.REACT_APP_TEST_SERVER_CONFIG;
    downloadSampleURL = this.serverURL + urlData.importData.downloadSampleFile;
    getConstituenciesURL = urlData.importData.getConstituencies;
    getPollingBoothURL = this.serverURL + urlData.importData.getPollingBooth;
    getPollingBoothByConstituencyURL = this.serverURL + urlData.importData.getPollingBoothByConstituency;
    getvillagesForPollingBoothURL = this.serverURL + urlData.importData.getvillagesForPollingBooth;
    getPresignedURL = this.serverURL + urlData.importData.uploadPDF;
    getParsePDFURL = this.serverURL + urlData.importData.parsePDF;
    getMappingFieldsURL = this.serverURL + urlData.importData.mappingFields;
    getSaveImportedDataURL = this.serverURL + urlData.importData.saveImportData;
    getSaveImportedDataExcelURL = this.serverURL + urlData.importData.saveImportData_Excel;
    getBatchDetailsURL = this.serverURL + urlData.importData.getBatchData;

    viewDataValidationConfig = {
        emptyCheck: ['voter_id', 'first_name', 'last_name', 'serial_no'],
        matchCheck: ['assembly_constituency', 'polling_booth']
    }
    
    _mappingData = [{"mappedField":"serial_no","jsonField":"sno"},{"mappedField":"","jsonField":"name"},{"mappedField":"","jsonField":"husband_name"},{"mappedField":"","jsonField":"house_num"},{"mappedField":"","jsonField":"age"},{"mappedField":"","jsonField":"gender"},{"mappedField":"","jsonField":"assembly"},{"mappedField":"","jsonField":"section_num"},{"mappedField":"","jsonField":"part_num"},{"mappedField":"last_name","jsonField":"last_name"},{"mappedField":"first_name","jsonField":"first_name"},{"mappedField":"voter_id","jsonField":"voter_id"},{"mappedField":"","jsonField":"father_name"},{"mappedField":"","jsonField":"other_name"},{"mappedField":"","jsonField":"mother_name"},{"mappedField":"","jsonField":"relation_type"},{"mappedField":"","jsonField":"parliamentary_constituency"},{"mappedField":"constituency","jsonField":"assembly"},{"mappedField":"","jsonField":"year_of_revision"},{"mappedField":"","jsonField":"qualify_date"},{"mappedField":"","jsonField":"type_of_revision"},{"mappedField":"","jsonField":"date_of_publication"},{"mappedField":"polling_booth","jsonField":"polling_station"},{"mappedField":"","jsonField":"town_or_village"},{"mappedField":"","jsonField":"police_station"},{"mappedField":"","jsonField":"mandal"},{"mappedField":"","jsonField":"revinue_division"},{"mappedField":"","jsonField":"district"},{"mappedField":"","jsonField":"pincode"},{"mappedField":"","jsonField":"male"},{"mappedField":"","jsonField":"starting_serial"},{"mappedField":"","jsonField":"ending_serial"},{"mappedField":"","jsonField":"female"},{"mappedField":"","jsonField":"third_gender"},{"mappedField":"","jsonField":"total"}];
    _exportJsonData = {
        "voterData": [
            {
                "sno": 2.0,
                "name": "Shobharani Addanki",
                "husband_name": "• Nagalingam",
                "house_num": "I-I",
                "age": 46,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Shobharani",
                "voter_id": "SG10525741",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            },
            {
                "sno": 3.0,
                "name": "Naga Lingam Addanki",
                "husband_name": '',
                "house_num": "I-I",
                "age": 51,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Lingam Naga",
                "voter_id": "SG10818304",
                "father_name": "Brahmaiah",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            },
            {
                "sno": 4.0,
                "name": "Addanki Rama Lakshmi",
                "husband_name": "Siva Naga Raju",
                "house_num": "1-1",
                "age": 37,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Lakshmi",
                "first_name": "Rama Addanki",
                "voter_id": "SG10525733",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 5.0,
                "name": "Addanki Siva Naga Raju",
                "husband_name": '',
                "house_num": "1-1",
                "age": 40,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Raju",
                "first_name": "Naga Siva Addanki",
                "voter_id": "SG12130425",
                "father_name": "Naga Lingam",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 6.0,
                "name": "Brahmayyaaddanki",
                "husband_name": '',
                "house_num": "1-1",
                "age": 32,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Brahmayyaaddanki",
                "first_name": "",
                "voter_id": "SG12130458",
                "father_name": "Naga Lingam",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 7.0,
                "name": "Veeraiah Addanki",
                "husband_name": '',
                "house_num": "1-1",
                "age": 24,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Veeraiah",
                "voter_id": "SG11030998",
                "father_name": "Naga Lingam",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 8.0,
                "name": "Sowjanya Addanki",
                "husband_name": "Brahmaiah Addanki",
                "house_num": "1-1",
                "age": 25,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Sowjanya",
                "voter_id": "SG10883323",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            },
            {
                "sno": 9.0,
                "name": "Kumar Addanki",
                "husband_name": '',
                "house_num": '',
                "age": 23,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Kumar",
                "voter_id": "SG11031012",
                "father_name": "Nagalingam Addanki House 1-1",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 10.0,
                "name": "Mastanamma Chopya",
                "husband_name": "Venkateswarlu",
                "house_num": "1-1/2",
                "age": 53,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Chopya",
                "first_name": "Mastanamma",
                "voter_id": "SG10817850",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 11.0,
                "name": "Jyothi Choppa",
                "husband_name": '',
                "house_num": "1-1/2",
                "age": 29,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Choppa",
                "first_name": "Jyothi",
                "voter_id": "SG11469741",
                "father_name": "Venkateswarlu Choppa",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            },
            {
                "sno": 12.0,
                "name": "Srinivasa Rao Choppa",
                "husband_name": '',
                "house_num": "1-1/2",
                "age": 35,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Choppa",
                "first_name": "Rao Srinivasa",
                "voter_id": "FLR3108719",
                "father_name": "Venkateswarlu",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 13.0,
                "name": "Hrudaya Kumari Choppa",
                "husband_name": "Srinivasa Rao",
                "house_num": "1-1/2",
                "age": 29,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Choppa",
                "first_name": "Kumari Hrudaya",
                "voter_id": "SG11469758",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 14.0,
                "name": "Eeshvaramma Addanki",
                "husband_name": "Veerabhahmam",
                "house_num": "1-2",
                "age": 51,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Eeshvaramma",
                "voter_id": "SG11030972",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 15.0,
                "name": "Siva Koteshwara R Addanki",
                "husband_name": '',
                "house_num": "1-3",
                "age": 36,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "R Koteshwara Siva",
                "voter_id": "SG11030980",
                "father_name": "Kondaiah",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 16.0,
                "name": "Nagendrammaaddanki",
                "husband_name": "Bhikshalu",
                "house_num": "1-3",
                "age": 71,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Nagendrammaaddanki",
                "first_name": "",
                "voter_id": "SG11469766",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 17.0,
                "name": "Mahesh Addanki",
                "husband_name": '',
                "house_num": "1-4",
                "age": 25,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Mahesh",
                "voter_id": "SG11469774",
                "father_name": "Kataiah",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            },
            {
                "sno": 18.0,
                "name": "Tirupati Rao Addanki",
                "husband_name": '',
                "house_num": '',
                "age": 27,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Rao Tirupati",
                "voter_id": "SG11030956",
                "father_name": "Kataiah House Numter: 1-4",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 19.0,
                "name": "Suneet Addanki",
                "husband_name": '',
                "house_num": "1-4",
                "age": 41,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Addanki",
                "first_name": "Suneet",
                "voter_id": "FLR1260074",
                "father_name": '',
                "other_name": "KOTAIAH",
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 20.0,
                "name": "Sambaiah Karsala",
                "husband_name": '',
                "house_num": "1-4",
                "age": 66,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Karsala",
                "first_name": "Sambaiah",
                "voter_id": "SG10525758",
                "father_name": "Enkateshwarlu",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 21.0,
                "name": "Mastanaiah Karasala",
                "husband_name": '',
                "house_num": "1-6",
                "age": 31,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Karasala",
                "first_name": "Mastanaiah",
                "voter_id": "SG11429141",
                "father_name": "Sambaiah",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            },
            {
                "sno": 22.0,
                "name": "Adamu Bhattu",
                "husband_name": '',
                "house_num": "1-7",
                "age": 37,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Bhattu",
                "first_name": "Adamu",
                "voter_id": "SG10585331",
                "father_name": "Arjuna",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 23.0,
                "name": "Bhattu Jyothi",
                "husband_name": "Adam",
                "house_num": "1-7",
                "age": 33,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Jyothi",
                "first_name": "Bhattu",
                "voter_id": "SG10585349",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 24.0,
                "name": "Manikyamma",
                "husband_name": "Arjuna House : 1-7",
                "house_num": '',
                "age": 73,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Manikyamma",
                "first_name": "",
                "voter_id": "SG10983080",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            },
            {
                "sno": 25.0,
                "name": "Ramesh Babu Rajolu",
                "husband_name": '',
                "house_num": "1-08",
                "age": 47,
                "gender": "Male",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Rajolu",
                "first_name": "Babu Ramesh",
                "voter_id": "SG11469782",
                "father_name": "Prabhakara Rao",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 26.0,
                "name": "Bhanu Prasanna Rajolu",
                "husband_name": '',
                "house_num": "1-08",
                "age": 42,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Rajolu",
                "first_name": "Prasanna Bhanu",
                "voter_id": "SG11469790",
                "father_name": "Ramesh Babu",
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            },
            {
                "sno": 27.0,
                "name": "Prameela Padarthi",
                "husband_name": "Rama Koteswararao Padarthi",
                "house_num": "1-10",
                "age": 27,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Padarthi",
                "first_name": "Prameela",
                "voter_id": "FLR1263672",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Father"
            },
            {
                "sno": 28.0,
                "name": "Koteshvaramma Padarthy",
                "husband_name": "Shiivaiah",
                "house_num": "1-10",
                "age": 46,
                "gender": "Female",
                "assembly": 98,
                "section_num": " I-Satram Bazar",
                "part_num": 3,
                "last_name": "Padarthy",
                "first_name": "Koteshvaramma",
                "voter_id": "FLR1262930",
                "father_name": '',
                "other_name": '',
                "mother_name": '',
                "relation_type": "Husband"
            }
        ],
        "polling_booth_data": {
            "parliamentary_constituency": " 14- Narsarao et",
            "year_of_revision": "2022",
            "qualify_date": "01-01-2022",
            "type_of_revision": "Special Summary Revision 2022",
            "date_of_publication": "Roll Identification",
            "polling_station": "3-KANTEPUDI",
            "town_or_village": "Kantepudi",
            "police_station": "Sattanapalli",
            "mandal": "Sattanapalli",
            "revinue_division": "Guntur",
            "district": "Guntur",
            "pincode": "522438",
            "male": "346",
            "starting_serial": "1",
            "ending_serial": "702",
            "female": "356",
            "assembly": 98,
            "third_gender": 0,
            "total": "702"
        }
    }

    importDataSelected = this.newImportUploadData();
    jobID = 0;
    fileUploaded = '';
    exportedJsonData = {polling_booth_data: {}, voterData: []};
    // exportedJsonData = this._exportJsonData;

    validateImportData(page) {
        let isValid = true;
        let errorMessages = [];
        if ( page = 'pdfUpload') {
            if ( !this.importDataSelected.constituency) {
                isValid = false;
                errorMessages.push('Constituency need to be selected');
            }
            if(this.importDataSelected.importTypeSelection == ImportConstants.IMPORT_TYPE_POLLING_STATION &&
                !this.importDataSelected.polingStation) {
                isValid = false;
                errorMessages.push('Polling Booth need to be selected');
            }
            if(this.importDataSelected.hasMultipleVillages) {
                if(!this.importDataSelected.village1.from ||
                    !this.importDataSelected.village1.to ||
                    !this.importDataSelected.village1.village ) {
                        isValid = false;
                        errorMessages.push('Village1 details are missing');
                    }
                if(!this.importDataSelected.village2.from ||
                    !this.importDataSelected.village2.to ||
                    !this.importDataSelected.village2.village ) {
                        isValid = false;
                        errorMessages.push('Village2 details are missing');
                    }
            }
        }

        return {isValid: isValid, errorMessages: errorMessages};
    }

    newImportUploadData() {
        return {
            importTypeSelection: '',
            constituency: '',
            polingStation: '',
            hasMultipleVillages: false,
            village1 : {
                from: '',
                to: '',
                village: ''
            },
            village2 : {
                from: '',
                to: '',
                village: ''
            },
            mappingData : []
            
        }
    }

    resetImportData() {
        this.importDataSelected = this.newImportUploadData();
        this.exportedJsonData = {polling_booth_data: {}, voterData: []};
    }
    getVoterDataByImportType() {
        if ( this.importDataSelected.importTypeSelection === ImportConstants.IMPORT_TYPE_CONSTITUENCY) {
            let mappingFields = this.getMappingCheckFields(this.viewDataValidationConfig.matchCheck)
            const grouped_jsonDataWithHeaderAsKeys= this.exportedJsonData.voterData.reduce((groups, row) => {
                const key = row[mappingFields[1]];
                if (!groups[key]) {
                  groups[key] = [];
                }
                groups[key].push(row);
                return groups;
              }, {});
              return grouped_jsonDataWithHeaderAsKeys;
        } else {
            return this.exportedJsonData;
        }
        
    }

    getMappingCheckFields = (fields) => {
        let _fields = [];
        fields.forEach( x => {
            const matchingFields = this.importDataSelected.mappingData.filter( y => {
            return x === y.mappedField
            });
            if (matchingFields.length) {
            _fields.push(matchingFields[0].jsonField);
            }
        })
        return _fields;
    } 
    downloadSampleFiles() {
        // return axios.get(this.downloadSampleURL, this.headerConfig);
        return axiosInstance.get(this.downloadSampleURL, this.headerConfig);

    }

    getConstituencies() {
        return axiosInstance.get(this.getConstituenciesURL);
    }

    getPollingBooth() {
        return axios.get(this.getPollingBoothURL, this.headerConfig);
        // return axios.get(`${this.serverURL}${urlData.favourMandalDashboardAPI}1,28`, this.headerConfig);
    }

    getPollingBoothByConstituency(constituencyID) {
        return axios.get(this.getPollingBoothByConstituencyURL+constituencyID, this.headerConfig);
    }

    getvillagesForPollingBooth(pollingBooth) {
        return axios.get(this.getvillagesForPollingBoothURL+pollingBooth, this.headerConfig);
    }

    getPDFBLOB(url) {
        return axios.get(url);
    }

    getPDFPreSignedURL(fileName) {
        return axios.get(this.getPresignedURL+fileName, this.headerConfig);
    }

    async uploadPresignedFile(url, file) {
        const config = { headers: { 'Content-Type': 'multipart/form-data' }};
        return await axios.post(url, file, config)
    }

    parsePDF(data) {
        return axios.post(this.getParsePDFURL, data,  this.headerConfig);
    }

    getMappingFields() {
        return axios.get(this.getMappingFieldsURL, this.headerConfig);
    }

    saveImportedData(data) {
        if(ImportConstants.excelFileType.some(x => this.fileUploaded.includes(x))) {
            return axios.post(this.getSaveImportedDataExcelURL, data,  this.headerConfig);
        } else {
            return axios.post(this.getSaveImportedDataURL, data,  this.headerConfig);
        }
    }

    getBatchDetails() {
        return axios.get(this.getBatchDetailsURL, this.headerConfig);
    }

}