import React, { useContext, useState } from "react";
import "./Sidenav.css";
import itoconnect from "../../Assets/itoclogo.png";
import toggle from "../../Assets/crossclose.png";
import respnav from "../../Assets/resplogonav.png";
import { NavLink } from "react-router-dom";
import { RiArrowDropRightLine } from "@react-icons/all-files/ri/RiArrowDropRightLine";
import { Modal, Button } from "react-bootstrap";
import { RoleDataContext } from "../../AppContextComponent";
import { useEffect } from "react";
import { ROLES_PAGE_NAME_CONSTANTS } from "../../Utils/utils";

function Sidenav({ toggleopend, showSideNav }) {
  const { roleDataService } = useContext(RoleDataContext);
  const [rolesInfo, setRolesInfo] = useState({});

  useEffect(() => {
    if (roleDataService.allRoles.length) {
      let _roleInfo = {};
      for (const [key, value] of Object.entries(ROLES_PAGE_NAME_CONSTANTS)) {
        let role = roleDataService.allRoles.filter(
          (x) => x.page_name === value
        );
        _roleInfo[value] = role.length ? role[0].read_role : false;
      }
      setRolesInfo(_roleInfo);
    }
  }, []);

  const sideNavMobileCollapse = () => {
    const viewPortWidth = window.innerWidth;
    const sideNavEl = document.getElementsByClassName("Sidenav_main_div")[0];
    if (viewPortWidth < "600") {
      sideNavEl.addEventListener("click", toggleopend);
    } else if (viewPortWidth > "600") {
      sideNavEl.removeEventListener("click", toggleopend);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    showSideNav(true);
  };
  const isLinkDisabled = () => {
    return window.innerWidth <= 1024;
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="Sidenav_main_div" onClick={sideNavMobileCollapse}>
      <div className="ems_nav_logo_main_div">
        <NavLink to={"/"}>
          <img className="itoconnectLogo" src={itoconnect} alt="not-visible" />
          <img className="resp_nav_logo" src={itoconnect} alt="not-visible" />
        </NavLink>
        <img
          className="respnav_closebtn_cross"
          src={toggle}
          alt="not_visible"
          onClick={toggleopend}
        />
      </div>
      <div className="navbar_scroll_div">
        {" "}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.Dashboard] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/"}
            >
              <span className="text_inside_navigation_btn_voterdata">
                Dashboard
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>
        ) : (
          ""
        )}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.VoterData] ? (
          <div
            className="navigation_buttons_main_div d-flex align-items-center "
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/votersdata"}
            >
              <span className="text_inside_navigation_btn_dashboard">
                Voters Data
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>
        ) : (
          ""
        )}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.Calendar] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/add-calender"}
            >
              <span className="text_inside_navigation_btn_voterdata">
                Calendar
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>
        ) : (
          ""
        )}
        {/* {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.UserManagement] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/usermanagement"}
            >
              <span className="text_inside_navigation_btn_usermanagement">
                User Managment
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>

        ) : (
          ""
        )}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.UserManagement] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/wardvillageissues"}
            >
              <span className="text_inside_navigation_btn_usermanagement">
                Ward/Village
                issues
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>

        ) : (
          ""
        )} */}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.ECampaigns] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center ecampaign_button"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/e-campaign"}
            >
              <span
                className="text_inside_navigation_btn_ecamping"
                id="link_active"
              >
                E - Campaigns
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>
        ) : (
          ""
        )}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.ECampaigns] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center ecampaign_button"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/wardvillageissues"}
            >
              <span
                className="text_inside_navigation_btn_ecamping"
                id="link_active"
              >
                Ward Village Issues
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>
        ) : (
          ""
        )}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.ECampaigns] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center ecampaign_button"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/usermanagement"}
            >
              <span
                className="text_inside_navigation_btn_ecamping"
                id="link_active"
              >
                User_Management
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>
        ) : (
          ""
        )}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.Birthdays] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/birthdays"}
            >
              <span className="text_inside_navigation_btn_voterdata">
                Birthdays
                <RiArrowDropRightLine size={20} className="sidenav_arrow" />
              </span>
            </NavLink>
          </div>
        ) : (
          ""
        )}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.ImportData] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            {isLinkDisabled() ? (
              <span className="disabled-link" onClick={handleShow}>
                <span className="text_inside_navigation_btn_voterdata">
                  Import Data
                  <RiArrowDropRightLine
                    size={20}
                    className="sidenav_arrow importdata_sidnav_arrow"
                  />
                </span>
              </span>
            ) : (
              <NavLink
                className="d-flex align-items-center"
                style={{
                  height: "100%",
                  width: "100%",
                  textDecoration: "none",
                }}
                to={"/importData"}
              >
                <span className="text_inside_navigation_btn_voterdata">
                  Import Data
                  <RiArrowDropRightLine
                    size={20}
                    className="sidenav_arrow importdata_sidnav_arrow"
                  />
                </span>
              </NavLink>
            )}
          </div>
        ) : (
          ""
        )}
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.Reports] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
          >
            <NavLink
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
              to={"/reports"}
            >
              <span className="text_inside_navigation_btn_voterdata">
                Reports
              </span>
              <RiArrowDropRightLine size={20} className="sidenav_arrow" />
            </NavLink>
          </div>
        ) : (
          ""
        )}
        <Modal show={show} onHide={handleClose} className="modal_popup">
          <Modal.Body>Importdata is not support in Mobile or Tab</Modal.Body>
          <Modal.Footer>
            <Button className="signout_no" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.SettingsRole] ||
        rolesInfo[ROLES_PAGE_NAME_CONSTANTS.SettingsVoterType] ||
        rolesInfo[ROLES_PAGE_NAME_CONSTANTS.SettingsConfiguration] ? (
          <div
            className="navigation_buttons_main_div_two d-flex align-items-center"
            id="link_active"
            onClick={toggleDropdown}
          >
            <div
              className="d-flex align-items-center"
              style={{ height: "100%", width: "100%", textDecoration: "none" }}
            >
              <span className="text_inside_navigation_btn_voterdata">
                Settings
                <RiArrowDropRightLine
                  size={20}
                  className="sidenav_arrow"
                  style={{
                    transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
        {isOpen && (
          <ul className="settings_sub_divs_main" id="link_active">
            {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.SettingsRole] ? (
              <li className="settings_sub_divs">
                <NavLink
                  id="link_active"
                  className="d-flex align-items-center settings_sub_divs_atag"
                  style={{
                    height: "100%",
                    width: "100%",
                    textDecoration: "none",
                  }}
                  to={"/roles"}
                >
                  <span className="settings_sub_divs_text">
                    Roles
                    <RiArrowDropRightLine size={20} className="sidenav_arrow" />
                  </span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.SettingsVoterType] ? (
              <li className="settings_sub_divs settings_sub_divs_atag">
                <span className="settings_sub_divs_text">
                  Voters type
                  <RiArrowDropRightLine size={20} className="sidenav_arrow" />
                </span>
              </li>
            ) : (
              ""
            )}
            {rolesInfo[ROLES_PAGE_NAME_CONSTANTS.SettingsConfiguration] ? (
              <li className="settings_sub_divs settings_sub_divs_atag">
                <span className="settings_sub_divs_text">
                  Configuration
                  <RiArrowDropRightLine size={20} className="sidenav_arrow" />
                </span>
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Sidenav;
